import { ActionReducer, ActionReducerMap, MetaReducer } from '@ngrx/store';

import { environment } from '../../environments/environment';
import * as fromMultiCircuit from './multi-circuit/multicircuit.reducers';
import * as fromContext from './context/context.reducers';
export * from './multi-circuit/multi-circuit.selectors';
export * from './context/context.selectors';
import { storeLogger } from 'ngrx-store-logger';

export interface State {
  [fromMultiCircuit.multicircuitKey]: fromMultiCircuit.State;
  [fromContext.contextKey]: fromContext.State;
}

export const reducers: ActionReducerMap<State> = {
  [fromMultiCircuit.multicircuitKey]: fromMultiCircuit.multicircuitReducer,
  [fromContext.contextKey]: fromContext.contextReducer,
};

export function logger(reducer: ActionReducer<any>): any {
  // default, no options
  return storeLogger()(reducer);
}

export const metaReducers: MetaReducer<State>[] = !environment.production ? [] : [];
