<div class="container">
  <h4 class="mat-title">Apps</h4>

  <div *ngIf="apps$ | async as apps">
    <mat-card *ngFor="let app of apps">
      <mat-card-header>
        <mat-card-title>{{app.name}}</mat-card-title>
        <mat-card-subtitle>{{app.owner.username}}</mat-card-subtitle>
      </mat-card-header>
      <mat-card-actions>
        <button mat-button [routerLink]="[app.id]">
          AudioBridges
          <mat-icon>chevron_right</mat-icon>
        </button>
      </mat-card-actions>
    </mat-card>
  </div>
</div>
