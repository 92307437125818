<div class="bcsn" *ngIf="bcns.length > 1 && !confNum">
  <span class="mat-caption">filter by conference number</span> <br />
  <div class="btns">
    <button
      mat-raised-button
      [color]="activeBcn === bcn ? 'primary' : 'default'"
      *ngFor="let bcn of bcns"
      (click)="activeBcn = activeBcn === bcn ? null : bcn"
    >
      {{ bcn }}
    </button>
  </div>
</div>

<div *ngIf="transcriptions$ | async as transcriptions" class="transcriptions">
  <ng-container *ngFor="let person of transcriptions">
    <mat-card
      *ngIf="
        (!activeBcn || person.bridgechannelnum === activeBcn) &&
        (!confNum || person.bridgechannelnum === confNum)
      "
    >
      <mat-card-header>
        <div mat-card-avatar>
          <identicon-svg [attr.username]="person.username || person.channelId"></identicon-svg>
        </div>
        <mat-card-title [title]="person.username || person.channelId">
          {{ person.username || person.channelId }}
        </mat-card-title>
        <mat-card-subtitle>
          <strong>{{ person.bridgechannelnum }}: </strong>
          {{ person.startTimestamp | date: 'medium' }}
        </mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
        <p>
          <ng-container *ngFor="let textEvent of person.transcriptions">
            {{ textEvent.text }}
          </ng-container>
        </p>
      </mat-card-content>
    </mat-card>
  </ng-container>
</div>
