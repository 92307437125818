<h4 class="mat-title" #broadcastGroup>
  <mat-icon>settings_input_antenna</mat-icon>
  Broadcast Groups
</h4>
<card-list>
  <div
    class="card-item"
    [class.editable]="group.editable"
    *ngFor="let group of broadcastGroups_"
    (mouseenter)="onMouseHoverGroup(group, true)"
    (mouseleave)="onMouseHoverGroup(group)"
  >
    <user-avatar>
      {{ getInitials(group.name) }}
    </user-avatar>
    <ng-container *ngIf="!group.editable">
      <div class="meta">
        <h3>{{ group.formGroup.get('name').value }}</h3>
        <span class="status">
          <!-- <span
            class="connected"
            *ngIf="activeFarparties[group.ownerBridge?.bridge?.confNum]">
            connected
          </span> -->
        </span>
      </div>
      <mat-chip-list class="group-bridges-chips">
        <mat-chip
          [ngClass]="{ 'group-chip--connectable': bridge.openChannel }"
          *ngFor="let bridge of group.formGroup.get('bridges').value"
        >
          <mat-icon>settings_ethernet</mat-icon>
          {{ bridge.farparty?.name }}
        </mat-chip>
      </mat-chip-list>
      <div class="controls--right">
        <handset-selector
          [connected]="group.onHandset"
          (selected)="onHandsetClick(group, $event, !!group.onHandset)"
          [handsets]="handsets"
        >
        </handset-selector>
        <ui-button class="icon" color="default" tooltip="Edit" (click)="editGroup(group)">
          <mat-icon>settings</mat-icon>
        </ui-button>
        <ui-button class="icon" color="warn" tooltip="Remove" (click)="removeGroup(group)">
          <mat-icon>clear</mat-icon>
        </ui-button>
      </div>
    </ng-container>
    <ng-container *ngIf="group.editable">
      <form [formGroup]="group.formGroup">
        <mat-form-field class="group-name">
          <mat-label>Group Name</mat-label>
          <input matInput placeholder="Group Name" formControlName="name" />
        </mat-form-field>
        <mat-form-field
          class="group-bridges"
          *ngIf="group.filteredBridges | async as filteredBridges"
        >
          <mat-chip-list #chipList>
            <mat-chip
              *ngFor="let bridge of group.selectedBridges"
              [removable]="true"
              (removed)="detachTwosome(group, bridge)"
            >
              {{ bridge.farparty.name }}
              <mat-icon matChipRemove>cancel</mat-icon>
            </mat-chip>
            <input
              placeholder="Broadcast Bridges"
              #bridgeInput
              [formControl]="group?.formGroup.get('bridges')"
              [matAutocomplete]="autocomplete"
              [matChipInputFor]="chipList"
            />
          </mat-chip-list>
          <mat-autocomplete
            (optionSelected)="attachTwosome($event, group)"
            #autocomplete="matAutocomplete"
          >
            <mat-option *ngFor="let bridge of filteredBridges" [value]="bridge">
              <div
                [ngClass]="{ 'bridge-option--connectable': bridge.openChannel }"
                class="bridge-option"
              >
                {{ bridge.farparty?.name }}
                <span *ngIf="bridge.openChannel" class="mat-caption">Open Channel</span>
              </div>
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </form>
      <div class="controls--right">
        <ui-button
          class="icon"
          color="default"
          [disabled]="!group.formGroup.get('name').value"
          (click)="saveGroup(group, group.formGroup.get('name').value)"
        >
          <mat-icon>check</mat-icon>
        </ui-button>
        <ui-button class="icon" color="warn" tooltip="Remove" (click)="removeGroup(group)">
          <mat-icon>clear</mat-icon>
        </ui-button>
      </div>
    </ng-container>
  </div>
  <div
    class="card-item add-new"
    (click)="createBroadcastGroup()"
    [class.add-new--disabled]="loading$ | async"
  >
    <user-avatar>
      <mat-icon>add</mat-icon>
    </user-avatar>
    <div class="meta">
      <h3>Create</h3>
    </div>
  </div>
</card-list>
