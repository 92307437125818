import { Component, Input } from '@angular/core';
import { Intercom } from '../../store/intercom/intercom.model';
import { Handset } from '../../store/handset/handset.model';
import { Store } from '@ngrx/store';
import { State } from '../../store/intercom/intercom.reducer';
import { dropHandset, putOnHandset } from '../../store/handset/handset.actions';
import {
  openConnectionDialog,
  removeConnection,
  toggleIntercomDevice,
} from '../../store/intercom/intercom.actions';

@Component({
  selector: 'intercom-bridge',
  templateUrl: './intercom-bridge.component.html',
  styleUrls: ['./intercom-bridge.component.scss'],
})
export class IntercomBridgeComponent {
  @Input() intercom: Intercom;
  @Input() handsets: Handset[];

  constructor(private store: Store<State>) {}

  removeConnection(intercomId: string, contactId: string): void {
    this.store.dispatch(removeConnection({ intercomId, contactId }));
  }

  openIntercomRequestDialog(intercomId: string): void {
    this.store.dispatch(openConnectionDialog({ intercomId }));
  }

  onHandsetClick(intercom: Intercom, { id }: Handset, disconnect: boolean): void {
    const dispatchable = { handsetId: id, bridgeId: intercom.ownerBridge.id };
    if (disconnect) {
      this.store.dispatch(dropHandset(dispatchable));
    } else {
      this.store.dispatch(putOnHandset(dispatchable));
    }
  }

  toggleSpeaker(intercom, line) {
    this.store.dispatch(
      toggleIntercomDevice({ intercom, line, device: 'speaker', isMuted: line.mutedSpeaker })
    );
  }

  toggleMicrophone(intercom, line) {
    this.store.dispatch(
      toggleIntercomDevice({ intercom, line, device: 'mic', isMuted: line.mutedMic })
    );
  }
}
