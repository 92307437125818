import { createAction, props } from '@ngrx/store';

import { Contact } from './contact.model';
import { Twosome } from '../twosome/twosome.model';

// loadContacts
export const loadContacts = createAction('[Contact/API] Load Contacts');

export const loadContactsSuccess = createAction(
  '[Contact/API] Load Contacts Success',
  props<{ contacts: Contact[] }>()
);

export const loadContactsError = createAction(
  '[Contact/API] Load Contacts Error',
  props<{ error: unknown }>()
);

// requestTwosome
export const requestTwosome = createAction(
  '[Contact/API] Request Twosome',
  props<{ twosome: Twosome }>()
);

export const requestTwosomeSuccess = createAction(
  '[Contact/API] Request Twosome Success',
  props<{ contacts: Contact[]; twosome: Twosome }>()
);

export const requestTwosomeError = createAction(
  '[Contact/API] Request Twosome Error',
  props<{ error: unknown }>()
);

// requestMemberBridge
export const requestMemberBridge = createAction(
  '[Contact/API] Request Member Bridge',
  props<{ memberId: string; handsetId?: string; webrtc?: boolean }>()
);

export const requestMemberBridgeSuccess = createAction(
  '[Contact/API] Request Member Bridge Success'
  // props<{ contacts: Contact[]; twosome: Twosome }>()
);

export const requestMemberBridgeError = createAction(
  '[Contact/API] Request Member Bridge Error',
  props<{ error: unknown }>()
);

// dropMemberBridge
export const dropMemberBridge = createAction(
  '[Contact/API] Drop Member Bridge',
  props<{ callId: string }>()
);

export const dropMemberBridgeSuccess = createAction(
  '[Contact/API] Drop Member Bridge Success'
  // props<{ contacts: Contact[]; twosome: Twosome }>()
);

export const dropMemberBridgeError = createAction(
  '[Contact/API] Drop Member Bridge Error',
  props<{ error: unknown }>()
);
