import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { ResourceAbstract } from '@resources/resources.abstract';
import { Handset } from 'src/app/home/bridges/store/handset/handset.model';
import { Community } from 'src/app/home/bridges/store/community/community.model';

@Injectable({
  providedIn: 'root',
})
export class CommunityLineService extends ResourceAbstract {
  constructor(protected httpClient: HttpClient) {
    super(httpClient);
  }

  getCommunityLines(): Observable<Community> {
    return this.get<Community[]>('ethervox/community/line', {}, true).pipe(
      map(this.getEtherVoxCommunity.bind(this))
    );
  }

  getHandsets(): Observable<Handset[]> {
    return this.get<Handset[]>('ethervox/community/handset', {}, true);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getHansetInfo(handsetId: string): Observable<any> {
    return this.get(`ethervox/community/handset/info/${handsetId}`, {}, true);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getCommunityCalls(): Observable<any> {
    return this.get(`ethervox/community/call`, {});
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dropHandset(handsetId: string, lineId = null): Observable<any> {
    const body = lineId ? { participants: [lineId] } : null;
    return this.post(`ethervox/community/handset/drop/${handsetId}`, body);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  putMCOnHandset(callId: string, lineId: string): Observable<any> {
    const body = { lineId };
    return this.post(`ethervox/call/${callId}/add`, body, false, {});
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  putOnHandset(handsetId: string, lineId: string): Observable<any> {
    const body = { participants: [lineId] };
    return this.post(`ethervox/community/handset/bridge/${handsetId}`, body, false, {}, true);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  requestLine(community: string): Observable<any> {
    const body = { community };
    return this.post(`ethervox/capacity/community/line`, body);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  requestMemberBridge(member: string, handset?: string): Observable<any> {
    const body = { member, handset };
    return this.post(`ethervox/community/call`, body);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dropMemberBridge(callId: string): Observable<any> {
    return this.post(`ethervox/community/call/${callId}/drop`);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  resendLine(requestid: string, community: string): Observable<any> {
    const body = { community };
    return this.post(`ethervox/community/line/call/${requestid}/resend`, body);
  }

  getLocalSites(): Observable<any[]> {
    return this.get<any[]>('audiobridge/local/multicircuit');
  }

  getRemoteSites(): Observable<any[]> {
    return this.get<any[]>('audiobridge/multicircuit');
  }

  private getEtherVoxCommunity(communities: Community[]): Community {
    const etherVoxCommunity =
      communities.find((cl) => cl.community?.name === 'EtherVox') ||
      ({
        lines: [],
      } as Community);
    etherVoxCommunity.lines = etherVoxCommunity.lines.sort((a, b) => (a.id > b.id ? -1 : 1));
    return etherVoxCommunity;
  }
}
