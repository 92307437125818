import { Routes } from '@angular/router';
import { LoginPageComponent } from './+login-page/login-page.component';
import { DirectDialPageComponent } from './+direct-dial-page/direct-dial-page.component';
import { DirectWebrtcPageComponent } from './+direct-webrtc-page/direct-webrtc-page.component';

export const hallRoutes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    component: LoginPageComponent,
  },
  // {
  //   path: 'direct-dial',
  //   component: DirectDialPageComponent,
  // },
  // {
  //   path: 'direct-webrtc',
  //   component: DirectWebrtcPageComponent,
  // },
];
