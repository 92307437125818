<!-- <ng-container *ngIf="intercom"> -->
<h4 class="mat-title">
  <mat-icon>device_hub</mat-icon>
  Intercom
</h4>
<card-list>
  <div *ngIf="intercom?.dynamicline?.length" class="card-item put-on-handset">
    <user-avatar>
      {{ intercom?.owner?.displayName?.charAt(0)?.toUpperCase() }}
    </user-avatar>
    <div class="meta">
      <h3>
        <direction-indicator [direction]="'outbound'"></direction-indicator>
        {{ intercom?.owner?.displayName }} Outbound Intercom
      </h3>
    </div>
    <div class="controls--right">
      <handset-selector
        [connected]="intercom?.onHandset"
        (selected)="onHandsetClick(intercom, $event, !!intercom?.onHandset)"
        [handsets]="handsets"
      >
      </handset-selector>
    </div>
  </div>
  <div class="card-item" *ngFor="let line of intercom?.dynamicline">
    <user-avatar>
      {{ line.description?.charAt(0)?.toUpperCase() }}
    </user-avatar>
    <div class="meta">
      <h3>
        <direction-indicator [direction]="line.direction"></direction-indicator>
        &nbsp;{{ line.description }}
      </h3>
    </div>
    <div class="controls--right">
      <ui-button
        *ngIf="line.direction === 'outbound'"
        class="icon headset-btn"
        color="default"
        tooltip="Manage Handset"
      >
        <mat-icon>headset</mat-icon>
        <mat-icon>arrow_drop_down</mat-icon>
      </ui-button>
      <ng-container *ngIf="line.direction === 'inbound'">
        <ui-button
          class="icon"
          color="default"
          [color]="line.mutedSpeaker ? '' : 'primary'"
          (click)="toggleSpeaker(intercom, line)"
          tooltip="Manage Speaker"
        >
          <mat-icon>speaker</mat-icon>
        </ui-button>
        <ui-button
          class="icon"
          color="default"
          [color]="line.mutedMic ? '' : 'primary'"
          (click)="toggleMicrophone(intercom, line)"
          tooltip="Manage Mic"
        >
          <mat-icon>mic</mat-icon>
        </ui-button>
        <ui-button
          class="icon"
          color="warn"
          (click)="removeConnection(intercom?.id, line.id)"
          tooltip="Remove"
        >
          <mat-icon>clear</mat-icon>
        </ui-button>
      </ng-container>
    </div>
  </div>
  <div class="card-item add-new" (click)="openIntercomRequestDialog(intercom?.id)">
    <user-avatar>
      <mat-icon>add</mat-icon>
    </user-avatar>
    <div class="meta">
      <h3>Request Connection</h3>
    </div>
  </div>
</card-list>
<!-- </ng-container> -->
