import { Routes } from '@angular/router';
import { LoginComponent } from './components/login/login.component';

export const hallRoutes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    component: LoginComponent,
  },
];
