import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { mergeMap, map, catchError, switchMap, filter, tap, repeat } from 'rxjs/operators';
import { of } from 'rxjs';
import { Store } from '@ngrx/store';

import { ContactsService } from '@resources/contacts/contacts.service';
import * as fromContacts from './contact.actions';
import { State, selectAllContacts } from '..';
import { ContactRequestDialogComponent } from '@shared/dialogs/contact-request-dialog/contact-request-dialog.component';
import { dialogSize } from '@const/ui.const';
import { ContactRequestDialogData } from '@shared/dialogs/contact-request-dialog/contact-request-dialog';
import { Contact } from './contact.model';
import { SocketCarrierService } from '@services/socket-carrier/socket-carrier.service';
import { CommunityLineService } from '@resources/community-line/community-line.service';
import { loadCommunity } from '../community/community.actions';

@Injectable()
export class ContactEffects {
  loadContacts$ = createEffect(() =>
    this.actions$
      .pipe(ofType(fromContacts.loadContacts))
      .pipe(mergeMap(() => this.contactService.getContacts()))
      .pipe(map((contacts) => fromContacts.loadContactsSuccess({ contacts })))
      .pipe(catchError((error) => of(fromContacts.loadContactsError({ error }))))
      .pipe(repeat())
  );

  requestMemberBridge$ = createEffect(() =>
    this.actions$
      .pipe(ofType(fromContacts.requestMemberBridge))
      .pipe(
        switchMap(({ memberId, handsetId, webrtc }) =>
          this.communityLineService.requestMemberBridge(memberId, handsetId)
        )
      )
      .pipe(switchMap(() => [loadCommunity(), fromContacts.requestMemberBridgeSuccess()]))
      .pipe(catchError((error) => of(fromContacts.requestMemberBridgeError({ error }))))
      .pipe(repeat())
  );

  dropMemberBridge$ = createEffect(() =>
    this.actions$
      .pipe(ofType(fromContacts.dropMemberBridge))
      .pipe(mergeMap(({ callId }) => this.communityLineService.dropMemberBridge(callId)))
      .pipe(switchMap(() => [loadCommunity(), fromContacts.dropMemberBridgeSuccess()]))
      .pipe(catchError((error) => of(fromContacts.dropMemberBridgeError({ error }))))
      .pipe(repeat())
  );

  requestTwosome$ = createEffect(() =>
    this.actions$.pipe(ofType(fromContacts.requestTwosome)).pipe(
      mergeMap(({ twosome }) =>
        this.store$
          .select(selectAllContacts)
          .pipe(
            map((contacts) =>
              this.dialog.open<unknown, unknown, Contact[]>(ContactRequestDialogComponent, {
                width: dialogSize.MEDIUM,
                data: <unknown>{ contacts },
              })
            )
          )
          .pipe(switchMap((d) => d.afterClosed()))
          .pipe(filter((contacts) => contacts && contacts.length > 0))
          .pipe(
            tap(([{ member = {} }]) =>
              this.socketCarrier.requestBridge('5e1cd8f0b452ca743b86482e', twosome.id, member.id)
            )
          )
          .pipe(map((contacts) => fromContacts.requestTwosomeSuccess({ twosome, contacts })))
          .pipe(catchError((error) => of(fromContacts.requestTwosomeError({ error }))))
          .pipe(repeat())
      )
    )
  );

  constructor(
    private actions$: Actions,
    private store$: Store<State>,
    private dialog: MatDialog,
    private socketCarrier: SocketCarrierService,
    private contactService: ContactsService,
    private communityLineService: CommunityLineService
  ) {}
}
