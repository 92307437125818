import { Meeting } from './../models/site.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ServiceAbstract } from '@ethervox/ui';
import { Store } from '@ngrx/store';
import { State } from '../../store/app.reducer';
import { catchError, map, tap } from 'rxjs/operators';

@Injectable()
export class McsService extends ServiceAbstract {
  constructor(protected httpClient: HttpClient, private store: Store<State>) {
    super(httpClient);
  }

  getMCBridges(): Observable<any[]> {
    return this.get<any[]>('ethervox/call');
  }

  dropMCBridge(id: string): Observable<any[]> {
    return this.post<any[]>(`ethervox/call/${id}/drop`);
  }

  dropMCHandset(callId: string, handsetId: string): Observable<any[]> {
    return this.post<any[]>(`ethervox/call/${callId}/drop/${handsetId}`);
  }

  getHandsets(): Observable<any[]> {
    return this.get<any[]>('ethervox/community/handset');
  }

  getBridgeStatuses(sdnxNodeId: string): Observable<any[]> {
    return this.get<any[]>(`audiobridge/status/check/bridge?sdnxnode=${sdnxNodeId}&force=true`, {}, true);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getHandsetInfo(handsetId: string): Observable<any> {
    return this.get(`ethervox/community/handset/info/${handsetId}`, {}, true);
  }

  getLocalSites(): Observable<Meeting[]> {
    return this.get<Meeting[]>('audiobridge/local/multicircuit');
  }

  getRemoteSites(): Observable<Meeting[]> {
    return this.get<Meeting[]>('audiobridge/multicircuit');
  }

  volumeToggle(siteId: string, device: string, action: string, local: boolean): Observable<any> {
    return this.post(`audiobridge/selfmanage/${siteId}/${device}/${action}`);
  }

  setVolume(lineId: string, txGain: number, rxGain: number): Observable<any> {
    return this.post(`audiobridge/selfmanage/${lineId}/gain`, { tx: txGain, rx: rxGain });
  }

  ringDown(lineId: string): Observable<any> {
    return this.post(`audiobridge/selfmanage/${lineId}/ringdown`);
  }

  getMulticircuit(lineId: string): Observable<Meeting> {
    return this.get(`audiobridge/local/multicircuit/${lineId}/?exclude=circuit`).pipe(
      catchError((p) => of(<any | Meeting>{}))
    );
  }

  getFlexAccess(bridgeId: string): Observable<any> {
    return this.get<Meeting[]>(`audiobridge/flexaccess?bridge=${bridgeId}`);
  }

  createMcBridge(sites: Meeting[]): Observable<any> {
    return this.post(`ethervox/call/group`, {
      local: sites.map((site) => ({
        number: site.confNum,
        sdnxnode: site.sdnxnode,
      })),
      type: 'local-mcs',
    });
  }

  updateFlexAccess(
    destinationBridge: string,
    inboundNumber: string,
    pin: string,
    callerIDs
  ): Observable<any> {
    const model = {
      destinationBridge,
      inboundNumber,
      pin,
      callerIDs,
    };
    return this.post<any>(`audiobridge/flexaccess`, model);
  }
}
