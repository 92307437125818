import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SharedModule } from '@shared/shared.module';

import { homeRoutes } from './home.routes';
import { HomePageComponent } from './home-page.component';
import { BridgesModule } from './bridges/bridges.module';
import { HandsetsModule } from './handsets/handsets.module';
import { ContactsModule } from './contacts/contacts.module';
import { MCSModule } from './mcs/mcs.module';
import { HootModule } from './hoot/hoot.module';
import { AdminModule } from './admin/admin.module';
import { HandsetsBarComponent } from './bridges/+bridges-page/handsets-bar/handsets-bar.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    RouterModule.forChild(homeRoutes),
    BridgesModule,
    HandsetsModule,
    MCSModule,
    ContactsModule,
    HootModule,
    AdminModule
  ],
  declarations: [HomePageComponent, HandsetsBarComponent],
})
export class HomeModule {}
