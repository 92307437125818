import { ActionReducerMap, createFeatureSelector, createSelector, MetaReducer } from '@ngrx/store';
import { environment } from '../../../../environments/environment';
import * as fromIntercom from './intercom/intercom.reducer';
import * as fromTwosome from './twosome/twosome.reducer';
import * as fromBroadcastGroup from './broadcast-group/broadcast-group.reducer';
import * as fromHandset from './handset/handset.reducer';
import * as fromCommunity from './community/community.reducer';
import * as fromContact from './contact/contact.reducer';

export const bridgesFeatureKey = 'bridges';

export interface State {
  [fromIntercom.intercomsFeatureKey]: fromIntercom.State;
  [fromTwosome.twosomesFeatureKey]: fromTwosome.State;
  [fromBroadcastGroup.broadcastGroupsFeatureKey]: fromBroadcastGroup.State;
  [fromHandset.handsetsFeatureKey]: fromHandset.State;
  [fromCommunity.communityFeatureKey]: fromCommunity.State;
  [fromContact.contactsFeatureKey]: fromContact.State;
}

export const reducers: ActionReducerMap<State> = {
  [fromIntercom.intercomsFeatureKey]: fromIntercom.reducer,
  [fromTwosome.twosomesFeatureKey]: fromTwosome.reducer,
  [fromBroadcastGroup.broadcastGroupsFeatureKey]: fromBroadcastGroup.reducer,
  [fromHandset.handsetsFeatureKey]: fromHandset.reducer,
  [fromCommunity.communityFeatureKey]: fromCommunity.reducer,
  [fromContact.contactsFeatureKey]: fromContact.reducer,
};

export const metaReducers: MetaReducer<State>[] = !environment.production ? [] : [];

export const selectBridgesState = createFeatureSelector<State>(bridgesFeatureKey);

/** Community specific selectors */
export const selectCommunityState = createSelector(
  selectBridgesState,
  (state) => state[fromCommunity.communityFeatureKey]
);

export const selectCommunityLoaded = createSelector(selectCommunityState, (state) => state.loaded);

/** Intercom specific selectors */
export const selectIntercomState = createSelector(
  selectBridgesState,
  (state) => state[fromIntercom.intercomsFeatureKey]
);

export const selectIntercom = createSelector(selectIntercomState, fromIntercom.selectAll);

export const selectTranscriptions = createSelector(
  selectCommunityState,
  (state) => state.transcriptionArray
);
export const selectTranscriptionsAggregatesByBCN = createSelector(
  selectCommunityState,
  (state) => state.transcriptionsAggregatesByBCN
);
export const selectTranscriptionsAggregatesByChannelId = createSelector(
  selectCommunityState,
  (state) => state.transcriptionsAggregatesByChannelId
);

export const selectIntercomLoaded = createSelector(selectIntercomState, (state) => state.loaded);

/** Twosome specific selectors */
export const selectTwosomeState = createSelector(
  selectBridgesState,
  (state) => state[fromTwosome.twosomesFeatureKey]
);

export const selectAllTwosomes = createSelector(selectTwosomeState, fromTwosome.selectAll);

/** Broadcast Group specific selectors */
export const selectBroadcastGroupState = createSelector(
  selectBridgesState,
  (state) => state[fromBroadcastGroup.broadcastGroupsFeatureKey]
);

export const selectAllBroadcastGroups = createSelector(
  selectBroadcastGroupState,
  fromBroadcastGroup.selectAll
);

export const selectBroadcastGroupsLoading = createSelector(
  selectBroadcastGroupState,
  (state) => state.loading
);

export const selectBroadcastGroupsLoaded = createSelector(
  selectBroadcastGroupState,
  (state) => state.loaded
);

/** Handset specific selectors */
export const selectHandsetState = createSelector(
  selectBridgesState,
  (state) => state[fromHandset.handsetsFeatureKey]
);

export const selectAllHandsets = createSelector(selectHandsetState, fromHandset.selectAll);

/** Contact specific selectors */
export const selectContactState = createSelector(
  selectBridgesState,
  (state) => state[fromContact.contactsFeatureKey]
);

export const selectAllContacts = createSelector(selectContactState, fromContact.selectAll);

export const selectContactsLoaded = createSelector(selectContactState, (state) => state.loaded);
