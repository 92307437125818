<div class="container">
  <h4 class="mat-title">
    <button mat-icon-button [routerLink]="['../']">
      <mat-icon>chevron_left</mat-icon>
    </button>
    AudioBridges
  </h4>

  <div *ngIf="nodes$ | async as nodes">
    <mat-card *ngFor="let node of nodes">
      <mat-card-header *ngIf="!node.node">
        <mat-card-title>{{node.id}}</mat-card-title>
        <mat-card-subtitle>{{node.app}}</mat-card-subtitle>
      </mat-card-header>
      <ng-container *ngIf="node.node as iNode">
        <mat-card-header >
          <mat-card-title>{{iNode.node?.name}}</mat-card-title>
          <mat-card-subtitle>{{iNode.node?.host}}</mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
          <!-- <ul class="node-props">
            <li class="mat-caption">
              API enabled: {{iNode.apiEnabled ? 'yes' : 'no'}}
            </li>
            <li class="mat-caption">
              Dynamic Calls: {{iNode.dynamicCallsNode ? 'yes' : 'no'}}
            </li>
            <li class="mat-caption">
              Test Capabilites: {{iNode.testCapabilitiesNode ? 'yes' : 'no'}}
            </li>
          </ul> -->
          <mat-list role="list">
            <mat-list-item role="listitem">
              API enabled: {{iNode.apiEnabled ? 'yes' : 'no'}}
            </mat-list-item>
            <mat-list-item role="listitem">
              Dynamic Calls: {{iNode.dynamicCallsNode ? 'yes' : 'no'}}
            </mat-list-item>
            <mat-list-item role="listitem">
              Test Capabilites: {{iNode.testCapabilitiesNode ? 'yes' : 'no'}}
            </mat-list-item>
            <mat-list-item role="listitem">
              Available Codecs: <ng-container *ngFor="let codec of iNode.globalconfig.available_codecs; let last = last">
                {{codec}}<ng-container *ngIf="!last">, </ng-container>
              </ng-container>
            </mat-list-item>
          </mat-list>
        </mat-card-content>
      </ng-container>
    </mat-card>
  </div>
</div>
