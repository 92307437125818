import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, catchError, switchMap, tap, take, filter, withLatestFrom } from 'rxjs/operators';
import { flatten } from 'lodash';
import { Store } from '@ngrx/store';
import { McsService } from './../../common/services/mcs.service';
import { forkJoin, of } from 'rxjs';
import * as fromMulticircuit from './multicircuit-actions';
import * as contextActions from '../../store/context/context.actions';
import * as reducer from '../../store/app.reducer';
import { Meeting } from './../../common/models/site.model';
import { selectSelectedMeeting } from '../../store/app.reducer';
import { CommunityLineService } from '../../../../../dashboard-ui/src/app/common/resources/community-line/community-line.service';
import { Web } from 'sip.js';
import {
  confJoin,
  confLeave,
} from '../../../../../dashboard-ui/src/app/store/actions/socket.actions';
import { selectAllHandsets } from '../../../../../dashboard-ui/src/app/home/bridges/store/index';

import {
  callWebRTCError,
  callWebRTCSuccess,
  dropMcHandset,
  dropWebRTCError,
  dropWebRTCSuccess,
} from './multicircuit-actions';
import { dropMemberBridge } from '../../../../../dashboard-ui/src/app/home/bridges/store/contact/contact.actions';
import { putMCOnHandsetSuccess } from '../../../../../dashboard-ui/src/app/home/bridges/store/handset/handset.actions';

// Helper function to get an HTML audio element
function getAudioElement(id: string): HTMLAudioElement {
  const el = document.getElementById(id);
  if (!(el instanceof HTMLAudioElement)) {
    throw new Error(`Element "${id}" not found or not an audio element.`);
  }
  return el;
}

@Injectable()
export class MulticircuitEffects {
  silenceCount: number;
  websocket: WebSocket;
  audioCtx = new AudioContext();
  audioStream;
  audioInput;
  audioEncoderPCM16;
  micAudioConverter;
  transcript = document.getElementById('transcript');
  topSegment = document.getElementById('welcome-message');

  createNewMessage(text = '', classNameList = []): void {
    // console.log('MulticircuitEffects', text);
    const newTopSegment = document.createElement('p');
    const segmentText = document.createTextNode(text);
    newTopSegment.appendChild(segmentText);
    if (classNameList.length !== 0) {
      newTopSegment.classList.add(...classNameList);
    }
    this.transcript.insertBefore(newTopSegment, this.topSegment);
    this.topSegment = newTopSegment;
  }

  stopRecording(): void { }

  startRecording(): void {
    this.silenceCount = 0;
    try {
      /*
        Set up a WebSocket connection to that will stream microphone data to a Mod9 Engine.
      */
      this.websocket = new WebSocket('wss://mod9.io');

      this.createNewMessage(
        `WebSocket is connecting to wss://mod9.io.
        Partial transcript: ; Formatted transcript: `,
        ['log-info']
      );

      this.websocket.onerror = (e) => {
        this.createNewMessage(
          `Failed to connect to . wss://mod9.io` +
          'Please make sure that URI is correct or try again later.',
          ['log-info', 'log-info-error']
        );
      };

      this.websocket.onclose = (e) => {
        this.stopRecording();
        this.createNewMessage('WebSocket connection is now closed.', ['log-info']);

        /* Revert the changes made to DOM Elements */
        // formattedSetting.disabled = false;
        // partialSetting.disabled = false;
        // uriInput.disabled = false;
        // settingsContainer.classList.remove("disabled");
        // micBtn.classList.remove("recording");
        // console.log("WebSocket connection is closed.");
      };

      this.websocket.onopen = () => {
        console.log('Websocket connection is open');

        /* Apply changes to the DOM Elements */
        // formattedSetting.disabled = true;
        // partialSetting.disabled = true;
        // uriInput.disabled = true;
        // settingsContainer.classList.add("disabled");
        // transcript.classList.add("recording");
        // micBtn.classList.add("recording");

        // isMicOn = true;
        this.createNewMessage('WebSocket connection is open. Say something ...', ['log-info']);
        // if (partialSetting.checked) {
        //   this.createNewMessage("", ["transcript-message"]);
        // }

        // Send request options to Mod9 ASR Engine.
        const optionsJSON = new Blob(
          [
            JSON.stringify({
              format: 'raw',
              encoding: 'pcm_s16le',
              rate: this.audioCtx.sampleRate,
              resample: true,
              partial: true, // partialSetting.checked,
              'transcript-formatted': false, //formattedSetting.checked,
              'transcript-formatted-partial': false, // formattedSetting.checked && partialSetting.checked,
            }),
          ],
          { type: 'text/plain' }
        );
        this.websocket.send(optionsJSON);
      };

      this.websocket.onmessage = async (e) => {
        /*
          On each WebSocket response, use the User settings to determine which relevant
          information to show on the webpage.
        */
        const replyJSON = await e.data.text();
        const asrResults = JSON.parse(replyJSON);
        // const text = formattedSetting.checked
        //   ? asrResults.transcript_formatted : asrResults.transcript;
        const text = asrResults.transcript;
        // Check the results to see if there are any errors.
        if (asrResults.status === 'failed') {
          this.createNewMessage(`Error. ${asrResults.error}`, ['log-info', 'log-info-error']);
        } else if (text) {
          // if (this.silenceCount && partialSetting.checked) {
          //   this.createNewMessage('', ['transcript-message']);
          // }
          this.silenceCount = 0;
          // this.createNewMessage(text, ['transcript-message']);
          // if (partialSetting.checked) {
          this.topSegment.innerText = text;
          if (asrResults.final) {
            this.createNewMessage('', ['transcript-message']);
          }
          // } else {
          // this.createNewMessage(text, ['transcript-message']);
          // }
        } else if (text === '') {
          this.silenceCount += 1;
          if (this.silenceCount === 1) {
            this.createNewMessage('[silence]', ['transcript-message']);
          } else if (this.silenceCount > 1) {
            this.topSegment.innerHTML = `[silence] <span class="silence-count">${this.silenceCount}</span>`;
          }
        }
      };
    } catch (err) {
      console.log('Connection could not be made');
      this.createNewMessage(
        'Could not create a WebSocket connection.' +
        ' Please make sure that the URI is correct and try again.',
        ['log-info', 'log-info-error']
      );
    }

    this.startStreamingAudio();
  }

  startStreamingAudio(): void {
    /*
      Create the audio processing interface. Use a Data URL to embed a small JS file containing a
      custom AudioWorkletProcessor. The custom processor will take the user's microphone data as
      32-bit float PCM (gain-normalized) nominally in [-1,+1] audio data and convert it to signed 16-bit
      integer linear PCM audio data.
      Details about AudioBuffer can be found at https://www.w3.org/TR/webaudio/#AudioBuffer
    */
    const workletProcessorDataURL = `data:application/javascript;charset=utf8,${encodeURIComponent(`
      class AudioConvertingProcessor extends AudioWorkletProcessor {
        constructor() {
          super();
        }

        process(inputList, outputList, parameters) {
          //console.log('processor', inputList, outputList, parameters)
          const inputChannel = inputList[0][0];
          if (inputChannel) {
            const PCM16iSamples = [];
            for ( let i = 0; i < inputChannel.length; i++) {
              let val = Math.round((2**16-1) * (1.0+inputChannel[i])/2) - 2**15;
              /* Clip audio outside the bounds of int16. */
              if (val < -(2**15)) {
                val = -(2**15);
              }
              if (val > (2**15 - 1)) {
                val = 2**15 - 1;
              }
              PCM16iSamples.push(val);
            }
          /*
            Use the MessagePort to communicate the converted data between the processor
            and the AudioWorkletNode.
          */
          this.port.postMessage(new Int16Array(PCM16iSamples));
          }

          return true;
        }
      }

      registerProcessor("audio-converting-processor", AudioConvertingProcessor);
    `)}`;

    this.audioCtx.audioWorklet
      .addModule(workletProcessorDataURL)
      .then(async () => {
        console.log('SIMPLE USER', this.simpleUser);
        this.audioStream = await navigator.mediaDevices.getUserMedia({ audio: true });
        this.audioEncoderPCM16 = new AudioWorkletNode(this.audioCtx, 'audio-converting-processor');
        this.audioInput = this.audioCtx.createMediaStreamSource(this.audioStream);
        this.audioInput.connect(this.audioEncoderPCM16);
        this.audioEncoderPCM16.port.onmessage = (e) => {
          /*
            Send converted audio from the AudioWorkletProcessor to the ASR Engine once the
            WebSocket connection is open.
          */
          if (this.websocket.readyState === WebSocket.OPEN) {
            this.websocket.send(e.data);
          }
        };
        this.audioEncoderPCM16.onprocessorerror = (error) => {
          this.stopRecording();
          this.createNewMessage(
            'The microphone audio data was not in the expected [-1,+1] range as floating point. ' +
            'Please refer to xkcd.com/2200',
            ['log-info', 'log-info-error']
          );
        };
      })
      .catch((e) => console.error(e, 'Did not create AudioWorkletNode.'));
  }

  simpleUser: Web.SimpleUser;

  callWebRTC$ = createEffect(() =>
    this.actions$
      .pipe(ofType(fromMulticircuit.callWebRTC))
      .pipe(
        tap(({ confNum, sipuri }) =>
          sipuri
            ? this.simpleUser.call(sipuri)
            : this.simpleUser.call(`sip:555${confNum}@webrtc.ethervox.io:8089`)
        )
      )
      .pipe(map(() => callWebRTCSuccess()))
      .pipe(catchError((error) => of(callWebRTCError({ error }))))
  );

  transcript$ = createEffect(
    () =>
      this.actions$
        .pipe(ofType(fromMulticircuit.transcript))
        .pipe(tap(() => this.startRecording())),
    { dispatch: false }
  );

  dropWebRTC$ = createEffect(() =>
    this.actions$
      .pipe(ofType(fromMulticircuit.dropWebRTC))
      .pipe(tap(() => this.simpleUser.hangup()))
      .pipe(map(() => dropWebRTCSuccess()))
      .pipe(catchError((error) => of(dropWebRTCError({ error }))))
  );

  dropWebRTCSuccess$ = createEffect(
    () =>
      this.actions$
        .pipe(ofType(fromMulticircuit.dropWebRTCSuccess))
        .pipe(switchMap(() => this.store.select(selectAllHandsets)))
        .pipe(take(1))
        .pipe(
          map((handsets) =>
            handsets.filter((h) => !!h.webRTC).find((h) => h.connected && h.connected.call)
          )
        )
        .pipe(filter((h) => !!h))
        .pipe(
          tap((handset) =>
            this.store.dispatch(dropMemberBridge({ callId: handset.connected.call.id }))
          )
        ),
    { dispatch: false }
  );

  initWebRTC$ = createEffect(
    () =>
      this.actions$
        .pipe(ofType(fromMulticircuit.initWebRTC))
        .pipe(switchMap(() => this.mcsService.getHandsets()))
        .pipe(map((handsets) => handsets.find((h) => !!h.webRTC)))
        .pipe(switchMap((handset) => this.mcsService.getHandsetInfo(handset.id)))
        .pipe(
          map(async (handset) => {
            const options: Web.SimpleUserOptions = {
              aor: `sip:${handset.sip.username}@webrtc.ethervox.io`,
              media: {
                constraints: { audio: true, video: false },
                remote: { audio: getAudioElement('remoteAudio') },
              },
              userAgentOptions: {
                autoStart: true,
                autoStop: true,
                authorizationPassword: handset.sip.password,
                authorizationUsername: handset.sip.username,
              },
            };
            const server = 'wss://webrtc.ethervox.io:8089/ws';
            this.simpleUser = new Web.SimpleUser(server, options);
            await this.simpleUser.connect();
            await this.simpleUser.register();
          })
        ),
    { dispatch: false }
  );

  isLocal: boolean;

  loadRemoteSites$ = createEffect(() =>
    this.actions$
      .pipe(ofType(fromMulticircuit.loadSites))
      .pipe(switchMap(() => this.mcsService.getRemoteSites()
        .pipe(
          map((payload: Meeting[]) => fromMulticircuit.loadSitesSuccess({ payload, isLocal: false }))
        )
        .pipe(catchError((error) => of(fromMulticircuit.loadSitesError({ error }))))
      ))

  );

  loadlocalSites$ = createEffect(() =>
    this.actions$
      .pipe(ofType(fromMulticircuit.loadlocalSites))
      .pipe(switchMap(() => this.mcsService.getLocalSites()
        .pipe(switchMap(sites => this.mcsService.getBridgeStatuses(sites[0].sdnxnode)

          .pipe(map(statuses => {
            const connectedMembers = statuses.map(s => {
              return (s.members || []).map(m => {
                return m.sipwline_id || m.sippwline_id
              })
            });
            const connectedMembersFlat = flatten(connectedMembers);
            const withStatuses = sites.map(site => ({
              ...site,
              sites: site.sites.map(s => ({
                ...s,
                connectionStatus: connectedMembersFlat.includes(s.sipLine),
                connected: connectedMembersFlat.includes(s.sipLine)
              }))
            }));
            return withStatuses;
          }))
          .pipe(map((sitesWithStatuses) => fromMulticircuit.loadLocalSitesSuccess({ payload: sitesWithStatuses })))
          .pipe(catchError((error) => of(fromMulticircuit.loadLocalSitesError({ error }))))
        ))
      ))
  );

  loadMulticircuit$ = createEffect(() =>
    this.actions$
      .pipe(ofType(fromMulticircuit.loadMulticircuitSite))
      .pipe(switchMap(({ siteId }) => this.mcsService.getMulticircuit(siteId)))
      .pipe(map((payload: Meeting) => fromMulticircuit.loadMulticircuitSiteSuccess({ payload })))
  );

  setSelectedMeeting$ = createEffect(
    () =>
      this.actions$
        .pipe(ofType(fromMulticircuit.setSelectedMeeting))
        .pipe(
          map((p) =>
            this.store.dispatch(contextActions.setSelectedSite({ payload: { ...p.payload } }))
          )
        ),
    { dispatch: false }
  );

  volumeToggle$ = createEffect(() =>
    this.actions$
      .pipe(ofType(fromMulticircuit.volumeToggle))
      .pipe(
        switchMap(({ siteId, device, action, local }) => {
          this.isLocal = local;
          return this.mcsService.volumeToggle(siteId, device, action, local);
          // return of({});
        })
      )
      .pipe(
        map((payload) =>
          fromMulticircuit.volumeToggleSuccess({
            payload,
            isLocal: this.isLocal,
          })
        )
      )
      .pipe(catchError((error) => of(fromMulticircuit.Error({ error }))))
  );

  volumeToggleSuccess$ = createEffect(() =>
    this.actions$
      .pipe(ofType(fromMulticircuit.volumeToggleSuccess))
      .pipe(switchMap(() => [fromMulticircuit.loadlocalSites(), fromMulticircuit.loadSites()]))
  );

  loadSitesToggle$ = createEffect(() =>
    this.actions$
      .pipe(ofType(fromMulticircuit.loadSitesToggle))
      .pipe(switchMap(() => this.mcsService.getRemoteSites()))
      .pipe(map((payload: Meeting[]) => fromMulticircuit.loadToggleSuccess({ payload })))
  );

  loadlocalSitesToggle$ = createEffect(() =>
    this.actions$
      .pipe(ofType(fromMulticircuit.loadlocalSitesToggle))
      .pipe(switchMap(() => this.mcsService.getLocalSites()))
      .pipe(map((payload) => fromMulticircuit.loadToggleSuccess({ payload })))
  );

  loadToggleSuccess$ = createEffect(() =>
    this.actions$
      .pipe(ofType(fromMulticircuit.loadToggleSuccess))
      .pipe(switchMap(() => this.store.select(selectSelectedMeeting)))
      .pipe(take(1))
      .pipe(map((p) => contextActions.setSelectedSite({ payload: { ...p } })))
  );

  setSelectedChannel$ = createEffect(() =>
    this.actions$
      .pipe(ofType(fromMulticircuit.setSelectedChannel))
      .pipe(switchMap(() => this.store.select(selectSelectedMeeting)))
      // .pipe(take(1))  TODO
      .pipe(map((p) => contextActions.setSelectedSite({ payload: { ...p } })))
  );

  setVolume$ = createEffect(() =>
    this.actions$
      .pipe(ofType(fromMulticircuit.setVolume))
      .pipe(
        switchMap(({ channelId, txGain, rxGain }) =>
          this.mcsService
            .setVolume(channelId, txGain, rxGain)
            .pipe(map(() => ({ channelId, rxGain, txGain })))
        )
      )
      .pipe(
        map((payload) =>
          fromMulticircuit.setVolumeSuccess({
            channelId: payload.channelId,
            txGain: payload.txGain,
            rxGain: payload.rxGain,
          })
        )
      )
      .pipe(catchError((error) => of(fromMulticircuit.Error({ error }))))
  );

  ringDown$ = createEffect(
    () =>
      this.actions$
        .pipe(ofType(fromMulticircuit.ringDown))
        .pipe(switchMap(({ lineId }) => this.mcsService.ringDown(lineId))),
    { dispatch: false }
  );

  createMcBridge$ = createEffect(() =>
    this.actions$.pipe(ofType(fromMulticircuit.createMcBridge)).pipe(
      switchMap(({ sites }) =>
        this.mcsService
          .createMcBridge(sites)
          .pipe(
            switchMap(() => [
              fromMulticircuit.createMcBridgeSuccess(),
              fromMulticircuit.getMcBridges(),
            ])
          )
          .pipe(catchError((error) => of(fromMulticircuit.Error({ error }))))
      )
    )
  );

  dropMcBridge$ = createEffect(() =>
    this.actions$.pipe(ofType(fromMulticircuit.dropMcBridge)).pipe(
      switchMap(({ id }) =>
        this.mcsService
          .dropMCBridge(id)
          .pipe(
            switchMap(() => [
              fromMulticircuit.dropMcBridgeSuccess(),
              fromMulticircuit.getMcBridges(),
            ])
          )
          .pipe(catchError((error) => of(fromMulticircuit.Error({ error }))))
      )
    )
  );

  dropMcHandset$ = createEffect(() =>
    this.actions$.pipe(ofType(fromMulticircuit.dropMcHandset)).pipe(
      switchMap(({ callId, handsetId }) =>
        this.mcsService
          .dropMCHandset(callId, handsetId)
          .pipe(
            switchMap(() => [
              fromMulticircuit.dropMcHandsetSuccess(),
              fromMulticircuit.getMcBridges(),
            ])
          )
          .pipe(catchError((error) => of(fromMulticircuit.Error({ error }))))
      )
    )
  );

  putMCOnHandsetSuccess$ = createEffect(() =>
    this.actions$
      .pipe(ofType(putMCOnHandsetSuccess))
      .pipe(map(() => fromMulticircuit.getMcBridges()))
  );

  confJoin$ = createEffect(() =>
    this.actions$.pipe(ofType(confJoin)).pipe(map(() => fromMulticircuit.getMcBridges()))
  );

  confLeave$ = createEffect(() =>
    this.actions$.pipe(ofType(confLeave)).pipe(map(() => fromMulticircuit.getMcBridges()))
  );

  loadMcsBridges$ = createEffect(() =>
    this.actions$.pipe(ofType(fromMulticircuit.getMcBridges)).pipe(
      switchMap(() => this.communityLineService.getHandsets()),
      switchMap((handsets) =>
        this.mcsService
          .getMCBridges()
          .pipe(
            map((bridges) =>
              bridges.map((b) => {
                const participantHandset = (b.participants || []).find(
                  (participant) =>
                    participant.site === 'thirdParty' &&
                    (participant.state === 'connected' || participant.state === 'ringing')
                );
                const onHandset =
                  participantHandset &&
                  handsets.find((handset) => participantHandset.destNum === handset.pwnum);
                return {
                  ...b,
                  onHandset,
                };
              })
            )
          )
          .pipe(map((bridges) => fromMulticircuit.getMcBridgesSuccess({ bridges })))
          .pipe(catchError((error) => of(fromMulticircuit.getMcBridgesError({ error }))))
      )
    )
  );

  constructor(
    private actions$: Actions,
    private communityLineService: CommunityLineService,
    private mcsService: McsService,
    private store: Store<reducer.State>
  ) {
    window.onbeforeunload = function () {
      this.simpleUser && this.simpleUser.disconnect();
    };
  }
}
