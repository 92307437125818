import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SharedModule } from '@shared/shared.module';

import { hallRoutes } from './hall.routes';
import { LoginPageComponent } from './+login-page/login-page.component';
import { DirectDialPageComponent } from './+direct-dial-page/direct-dial-page.component';
import { DirectWebrtcPageComponent } from './+direct-webrtc-page/direct-webrtc-page.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    RouterModule.forChild(hallRoutes)
  ],
  declarations: [
    LoginPageComponent,
    DirectDialPageComponent,
    DirectWebrtcPageComponent
  ]
})
export class HallModule { }
