<div class="handsets-container">
  <ng-container *ngFor="let handset of handsets; let i = index">
    <button mat-raised-button class="handset" [class.handset--connected]="handset.connected">
      <div class="handset__symbol" [matTooltip]="handset.bridge?.bridge?.confName">
        <mat-icon>headset_mic</mat-icon>
        <sup *ngIf="handset.connected?.broadcastGroup">
          <mat-icon>settings_input_antenna</mat-icon>
        </sup>
        <sup *ngIf="handset.connected?.twosome">
          <mat-icon>settings_ethernet</mat-icon>
        </sup>
        <sup *ngIf="handset.connected?.call">
          <mat-icon>person</mat-icon>
        </sup>
        <sub
          ><span class="mat-caption">{{ i + 1 }}</span></sub
        >
      </div>
      <mat-divider *ngIf="handset.connected" [vertical]="true"> </mat-divider>

      <ng-container *ngIf="handset.connected as connected">
        <!-- CONTACT ON HANDSET -->
        <div class="handset__members" *ngIf="connected.call as call">
          <user-avatar class="small" [matTooltip]="call.communityMember?.displayName">
            <span class="mat-caption">{{ getInitials(call.communityMember?.displayName) }}</span>
          </user-avatar>
        </div>

        <!-- BROADCAST GROUP ON HANDSET | Members -->
        <div class="handset__members" *ngIf="connected.broadcastGroup as broadcastGroup">
          <user-avatar class="small" [matTooltip]="broadcastGroup.name">
            <span class="mat-caption">{{ getInitials(broadcastGroup.name) }}</span>
          </user-avatar>
        </div>

        <!-- TWOSOME ON HANDSET | Twosome -->
        <div class="handset__members" *ngIf="connected.twosome as twosome">
          <user-avatar class="small" [matTooltip]="twosome.farparty?.name">
            <span class="mat-caption">{{ getInitials(twosome.farparty?.name) }}</span>
          </user-avatar>
        </div>

        <!-- UNKNOWN ON HANDSET -->
        <div
          class="handset__members"
          *ngIf="
            !connected.twosome &&
            !connected.broadcastGroup &&
            !connected.intercom &&
            !connected.call
          "
        >
          <user-avatar class="small" matTooltip="Unknown Farparty">
            <span class="mat-caption">?</span>
          </user-avatar>
        </div>
      </ng-container>
    </button>
  </ng-container>
</div>
