import { Routes } from '@angular/router';
import { AdminPageComponent } from './+admin-page/admin-page.component';
import { AppAbsPageComponent } from './+app-abs-page/app-abs-page.component';

export const adminRoutes: Routes = [
  {
    path: '',
    component: AdminPageComponent
  }
];
