import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  Meeting,
  createMcBridge,
  dropMcBridge,
  dropMcHandset,
  getMcBridges,
  selectMcBridges,
  selectMcBridgesLoaded,
} from '@ethervox/mcs';
import { Store } from '@ngrx/store';
import { State } from '../../store';
import { Observable } from 'rxjs';
import { filter, switchMap, tap } from 'rxjs/operators';
import { Handset } from '../../store/handset/handset.model';

@Component({
  selector: 'multicircuit-bridges',
  templateUrl: './multicircuit-bridges.component.html',
  styleUrls: ['./multicircuit-bridges.component.scss'],
})
export class MulticircuitBridgesComponent implements OnInit {
  @Output() putOnHandset = new EventEmitter();

  @Input() sites: Meeting[];
  @Input() handsets: Handset[];

  bridgeToCreate: Meeting[] = [];

  bridges$: Observable<any[]>;

  constructor(private store: Store<State>) {}

  ngOnInit() {
    this.bridges$ = this.store
      .select(selectMcBridgesLoaded)
      .pipe(tap((l) => !l && this.store.dispatch(getMcBridges())))
      .pipe(filter((l) => !!l))
      .pipe(switchMap(() => this.store.select(selectMcBridges)));
  }

  create() {
    this.store.dispatch(createMcBridge({ sites: this.bridgeToCreate }));
  }

  drop(id: string) {
    this.store.dispatch(dropMcBridge({ id }));
  }

  dropHandset(callId: string, bridge: any) {
    const handsetId = bridge.participants.find((p) => p.site === 'thirdParty')?.callId;
    this.store.dispatch(dropMcHandset({ callId, handsetId }));
  }
}
