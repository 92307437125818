import { createFeatureSelector, createReducer, on } from '@ngrx/store';
import * as fromAdmin from './admin.actions';

export const adminFeatureKey = 'admin';

export interface State {
  loaded: boolean;
  loading: boolean;
  apps: any[];
  nodes: any[];
}

export const initialState: State = {
  loaded: false,
  loading: false,
  apps: [],
  nodes: []
};

export const reducer = createReducer(
  initialState,
  on(fromAdmin.loadApps, (state) => ({
    ...state,
    loading: true,
  })),
  on(fromAdmin.loadAppsError, (state) => ({
    ...state,
    loading: false,
    loaded: false
  })),
  on(fromAdmin.loadAppsSuccess, (state, { apps }) => ({
    ...state,
    loading: false,
    loaded: true,
    apps
  })),
  on(fromAdmin.loadAppNodes, (state) => ({
    ...state,
    nodes: []
  })),
  on(fromAdmin.loadAppNodesSuccess, (state, { nodes }) => ({
    ...state,
    nodes
  }))
);

export const selectAdminState = createFeatureSelector<State>(adminFeatureKey);
