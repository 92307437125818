<card-list>
  <div
    *ngFor="let twosome of twosomes"
    [id]="'line__' + twosome.id"
    class="card-item {{ twosome.state }} {{ twosome.onHandset ? 'connected' : '' }}"
  >
    <user-avatar *ngIf="twosome.farparty">
      {{ getInitials(twosome.farparty.name) }}
    </user-avatar>
    <user-avatar *ngIf="!twosome.farparty" class="idle"></user-avatar>
    <div class="meta">
      <h3>
        <span *ngIf="twosome.farparty">
          <direction-indicator [direction]="twosome.direction"></direction-indicator>
          {{ twosome.farparty.name }}
        </span>
        <span *ngIf="!twosome.farparty" class="idle-line"> Idle </span>
      </h3>
      <span class="status">
        {{ twosome.state }}<ng-container *ngIf="twosome.onHandset">, connected</ng-container>
      </span>
    </div>
    <div class="controls--right">
      <!-- IN BROADCAST GROUP-->
      <div
        class="bg-status"
        *ngIf="twosome.broadcastGroup as broadcastGroup"
        [class.bg-status--connected]="!!broadcastGroup.onHandset"
      >
        <mat-icon> settings_input_antenna </mat-icon>
      </div>

      <ui-button
        class="icon"
        color="default"
        tooltip="Resend Notification"
        *ngIf="twosome.direction === 'outbound' && twosome.state === 'pending'"
        (click)="resend.next(twosome)"
      >
        <mat-icon>refresh</mat-icon>
      </ui-button>

      <ui-button
        class="icon"
        color="default"
        tooltip="Request Contact"
        *ngIf="twosome.direction === 'outbound' && !twosome.farparty"
        (click)="requestContact.emit(twosome)"
      >
        <mat-icon>people</mat-icon>
      </ui-button>

      <handset-selector
        *ngIf="twosome.state !== 'idle'"
        (selected)="putOnHandset.emit({ twosome: twosome, handset: $event })"
        (unselected)="dropHandset.emit({ twosome: twosome, handset: $event })"
        [connected]="twosome.onHandset"
        [handsets]="handsets"
      >
      </handset-selector>

      <ui-button
        class="icon"
        color="green"
        tooltip="Accept Bridge"
        *ngIf="twosome.direction === 'inbound' && twosome.state === 'pending'"
        (click)="accept.emit(twosome)"
      >
        <mat-icon>check</mat-icon>
      </ui-button>

      <ui-button
        class="icon"
        color="primary"
        color="default"
        tooltip="Ringdown Bridge"
        *ngIf="twosome.onHandset"
        (click)="ringdown.emit(twosome)"
      >
        <mat-icon>notifications</mat-icon>
      </ui-button>

      <ui-button
        *ngIf="twosome.state === 'established' || twosome.state === 'pending'"
        class="icon"
        color="default"
        tooltip="Settings"
        (click)="updateSettings.emit(twosome)"
      >
        <mat-icon>settings</mat-icon>
      </ui-button>

      <ui-button
        class="icon"
        color="warn"
        tooltip="Reject Bridge"
        *ngIf="twosome.state !== 'idle'"
        (click)="reject.emit(twosome)"
      >
        <mat-icon>clear</mat-icon>
      </ui-button>
    </div>
  </div>
</card-list>
