import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// import { NgProgressModule } from 'ngx-progressbar';

import { HeaderBarComponent } from './header-bar/header-bar.component';
import { UiModule } from '@ethervox/ui';

const modules = [
  // NgProgressModule,
  RouterModule,
  CommonModule,
  FormsModule,
  ReactiveFormsModule,
  UiModule,
  AuthStoreModule,
];

// COMPONENTS
// import { CardListComponent } from './card-list/card-list.component';
// import { UserAvatarComponent } from './user-avatar/user-avatar.component';
import { VersionBarComponent } from './version-bar/version-bar.component';
import { DirectionIndicatorComponent } from './direction-indicator/direction-indicator.component';
import { HandsetSelectorComponent } from './handset-selector/handset-selector.component';
import { TranscriptionSheetComponent } from './transcription-sheet/transcription-sheet.component';
const components = [
  HeaderBarComponent,
  // CardListComponent,
  // UserAvatarComponent,
  VersionBarComponent,
  DirectionIndicatorComponent,
  HandsetSelectorComponent,
];

// DIALOGS
import { ContactRequestDialogComponent } from './dialogs/contact-request-dialog/contact-request-dialog.component';
import { HandsetDetailsDialogComponent } from './dialogs/handset-details-dialog/handset-details-dialog.component';
import { ConfirmDialogComponent } from './dialogs/confirm-dialog/confirm-dialog.component';
import { ContactInvitationDialogComponent } from './dialogs/contact-invitation-dialog/contact-invitation-dialog.component';
import { ContactLookupResultDialogComponent } from './dialogs/contact-lookup-result-dialog/contact-lookup-result-dialog.component';
import { HootRequestDialogComponent } from './dialogs/hoot-request-dialog/hoot-request-dialog.component';
import { BridgeSettingsDialogComponent } from './dialogs/bridge-settings-dialog/bridge-settings-dialog.component';
import { IntercomRequestDialogComponent } from './dialogs/intercom-request-dialog/intercom-request-dialog.component';

const dialogs = [
  ContactRequestDialogComponent,
  HandsetDetailsDialogComponent,
  ConfirmDialogComponent,
  ContactInvitationDialogComponent,
  ContactLookupResultDialogComponent,
  HootRequestDialogComponent,
  BridgeSettingsDialogComponent,
  IntercomRequestDialogComponent,
  TranscriptionSheetComponent,
];

const directives = [];

// PIPES
import { FilterByPipe } from '@pipes/filter-by/filter-by.pipe';
import { SafeUrlPipe } from '@pipes/safe-url/safe-url.pipe';

import { AuthStoreModule } from '@ethervox/auth-store';

const pipes = [FilterByPipe, SafeUrlPipe];

@NgModule({
  imports: [...modules],
  entryComponents: [...dialogs],
  exports: [...modules, ...components, ...dialogs, ...directives, ...pipes],
  declarations: [...components, ...dialogs, ...directives, ...pipes],
})
export class SharedModule {}
