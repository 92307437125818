import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SessionService {
  public static get token(): string {
    return localStorage.getItem('token');
  }

  public static set token(token: string) {
    localStorage.setItem('token', token);
  }
}
