import { Component, OnInit, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { filter, map, switchMap, takeUntil, tap, withLatestFrom } from 'rxjs/operators';

import * as multicircuitActions from '../../../store/multi-circuit/multicircuit-actions';
import { State, selectLocalSites, selectRemoteSites } from '../../../store/app.reducer';
import { Meeting, SitesDetails } from './../../../common/models/site.model';
import { NestedTreeControl } from '@angular/cdk/tree';
import { SocketCarrierService } from '../../../common/services/socket-carrier.service';
import { SimpleUser } from 'sip.js/lib/platform/web';
import { callWebRTC, dropWebRTC } from '../../../store/multi-circuit/multicircuit-actions';
import {
  selectLocalSitesGrouped,
  selectLocalSitesLoaded,
  selectOnCall,
  selectRemoteSitesGrouped,
  selectRemoteSitesLoaded,
} from '../../../store/multi-circuit/multi-circuit.selectors';
import { McsService } from './../../../common/services/mcs.service';

@Component({
  selector: 'ethervox-expandable',
  templateUrl: './expandable.component.html',
  styleUrls: ['./expandable.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExpandableComponent implements OnInit, OnDestroy {
  localSites$: Observable<Meeting[]>;
  remoteSites$: Observable<Meeting[]>;

  localSitesGrouped$: Observable<Meeting[]>;
  remoteSitesGrouped$: Observable<Meeting[]>;

  localSitesTree = new NestedTreeControl<any>((node) => node.sites);
  remoteSitesTree = new NestedTreeControl<any>((node) => node.sites);

  playStatus: Array<string> = [];
  stopStatus: Array<string> = [];
  simpleUser: SimpleUser;
  onCall: boolean;

  onCall$: Observable<boolean>;
  statuses$ = new BehaviorSubject<any[]>([]);

  private destroyed$ = new Subject();

  constructor(
    private socketCarrierService: SocketCarrierService,
    private mcsService: McsService,
    private store: Store<State>) { }

  ngOnInit(): void {
    this.onCall$ = this.store.select(selectOnCall);

    this.localSites$ = this.store.select(selectLocalSitesLoaded)
      .pipe(tap(loaded => !loaded && this.store.dispatch(multicircuitActions.loadlocalSites())))
      .pipe(filter(loaded => !!loaded))
      .pipe(switchMap(() => this.store.select(selectLocalSites)));

    this.remoteSites$ = this.store.select(selectRemoteSitesLoaded)
      .pipe(tap(loaded => !loaded && this.store.dispatch(multicircuitActions.loadSites())))
      .pipe(filter(loaded => !!loaded))
      .pipe(switchMap(() => this.store.select(selectRemoteSites)))

    this.localSitesGrouped$ = this.store.select(selectLocalSitesLoaded)
      .pipe(tap(loaded => !loaded && this.store.dispatch(multicircuitActions.loadlocalSites())))
      .pipe(filter(loaded => !!loaded))
      .pipe(switchMap(() => this.store.select(selectLocalSitesGrouped)));

    this.remoteSitesGrouped$ = this.store.select(selectRemoteSitesLoaded)
      .pipe(tap(loaded => !loaded && this.store.dispatch(multicircuitActions.loadSites())))
      .pipe(filter(loaded => !!loaded))
      .pipe(switchMap(() => this.store.select(selectRemoteSitesGrouped)));

    this.socketCarrierService.dispatchActions();
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  callWebRTC(node): void {
    this.store.dispatch(callWebRTC({ confNum: node.confNum }));
  }

  dropWebRTC(): void {
    this.store.dispatch(dropWebRTC());
  }

  getSite(sites: Meeting[], channel: SitesDetails): Meeting {
    return sites.find((site) => site.sites.find((i) => i.id === channel.id));
  }

  hasChild = (_: number, node: Meeting) => !!node.sites && node.sites.length > 0;

  clickPlay(meeting: Meeting): void {
    if (!this.playStatus?.includes(meeting.id)) {
      this.playStatus.push(meeting.id);
    } else {
      this.playStatus = this.playStatus.filter((e) => e != meeting.id);
    }
  }

  clickPause(meeting: Meeting): void {
    if (!this.stopStatus?.includes(meeting.id)) {
      this.stopStatus.push(meeting.id);
    } else {
      this.stopStatus = this.stopStatus.filter((e) => e != meeting.id);
    }
  }

  clickPerson(site: SitesDetails, e: Event): void {
    e.stopPropagation();
  }

  clickPhone(site: SitesDetails, e: Event): void {
    e.stopPropagation();
  }

  toggleMic(site: SitesDetails, e: Event): void {
    e.stopPropagation();
  }

  toggleSpeaker(site: SitesDetails, e: Event): void {
    e.stopPropagation();
  }

  onNodeExpanded(node: any) {
    this.mcsService
      .getBridgeStatuses(node.sdnxnode)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(statuses => {
        this.statuses$.next(statuses);
      });
  }
}
