<h3 class="dialog-title">
  Handset Details
</h3>
<br>
<div>
  <span class="mat-caption">
    Username:
  </span>
  <span class="mat-body">
    {{data?.sip?.username}}
  </span>
</div>
<div>
  <span class="mat-caption">
    Password:
  </span>
  <span class="mat-body">
    {{data?.sip?.password}}
  </span>
</div>
<div>
  <span class="mat-caption">
    URL:
  </span>
  <span class="mat-body">
    {{data?.sip?.host}}:{{data?.sip?.port}}{{data?.sip?.path}}
  </span>
</div>
<div class="dialog-controls">
  <button
    color="default"
    (click)="cancel()"
    mat-button>
    Cancel
  </button>
</div>
