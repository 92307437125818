<ui-button
  class="icon headset-btn"
  tooltip="Manage Handset"
  [color]="connected ? 'accent' : 'default'"
  [matMenuTriggerFor]="menu"
>
  <mat-icon>headset</mat-icon>
  <sub *ngIf="connected">
    {{ getConnectedIndex(connected, handsets) }}
  </sub>
  <mat-icon>arrow_drop_down</mat-icon>
</ui-button>
<mat-menu #menu="matMenu" panelClass="double-line">
  <button
    mat-menu-item
    (click)="handset.id === connected?.id ? unselected.emit(handset) : selected.emit(handset)"
    [class.connected]="handset.id === connected?.id"
    *ngFor="let handset of handsets"
  >
    <span class="content">
      <span class="mat-caption">
        {{ handset.id === connected?.id ? 'Disconnect from' : 'Connect to' }}
      </span>
      <span class="mat-body-1">
        {{ handset.displayName || handset.bridge?.bridge?.confName }}
        <!-- <ng-template [ngIf]="connected">| {{connected}}</ng-template> -->
      </span>
    </span>
  </button>
</mat-menu>
