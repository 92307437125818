import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { SharedModule } from '@shared/shared.module';
import { adminRoutes } from './admin.routes';

import * as fromAdmin from './store';
import { AdminPageComponent } from './+admin-page/admin-page.component';
import { AdminEffects } from './store/admin.effects';
import { AppAbsPageComponent } from './+app-abs-page/app-abs-page.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    RouterModule.forChild(adminRoutes),
    StoreModule.forFeature(fromAdmin.adminFeatureKey, fromAdmin.reducers, {
      metaReducers: []
    }),
    EffectsModule.forFeature([
      AdminEffects,
    ]),
  ],
  declarations: [
    AdminPageComponent,
    AppAbsPageComponent
  ],
})
export class AdminModule { }
