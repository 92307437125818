import { Routes } from '@angular/router';

import { HandsetsPageComponent } from './+handsets-page/handsets-page.component';

export const handsetsRoutes: Routes = [
  {
    path: '',
    component: HandsetsPageComponent
  }
];
