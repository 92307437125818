import * as multicircuitActions from './multicircuit-actions';
import { Meeting, SitesDetails } from '../../common/models/site.model';
import { createReducer, on } from '@ngrx/store';
import { groupBy } from 'lodash';
import { state } from '@angular/animations';
export const multicircuitKey = 'list';

export interface State {
  loading: boolean;
  loaded: boolean;
  isLocal: boolean;
  subMulticircuit: Meeting;
  localSites: Meeting[];
  localSitesLoaded: boolean;
  localSitesGrouped: any[];
  remoteSites: Meeting[];
  remoteSitesLoaded: boolean;
  remoteSitesGrouped: any[];
  onCall: boolean;
  onCallWith: string;
  mcBridges: {
    bridges: any[];
    loading: boolean;
    loaded: boolean;
  };
}

const initialState = {
  loading: false,
  loaded: false,
  isLocal: true,
  subMulticircuit: null,
  localSites: [],
  localSitesLoaded: false,
  localSitesGrouped: [],
  remoteSites: [],
  remoteSitesLoaded: false,
  remoteSitesGrouped: [],
  onCall: false,
  onCallWith: '',
  mcBridges: {
    bridges: [],
    loading: false,
    loaded: false,
  },
};

export const multicircuitReducer = createReducer(
  initialState,
  on(multicircuitActions.getMcBridges, (state, action) => ({
    ...state,
    mcBridges: {
      ...state.mcBridges,
      loading: true,
    },
  })),
  on(multicircuitActions.getMcBridgesError, (state, action) => ({
    ...state,
    mcBridges: {
      ...state.mcBridges,
      loading: false,
      loaded: false,
    },
  })),
  on(multicircuitActions.getMcBridgesSuccess, (state, action) => ({
    ...state,
    mcBridges: {
      ...state.mcBridges,
      loading: false,
      loaded: true,
      bridges: action.bridges.map((b) => ({
        ...b,
        formattedTime: new Date(b.startTimestamp).toLocaleString(),
      })),
    },
  })),
  on(multicircuitActions.callWebRTC, (state, action) => ({
    ...state,
    onCallWith: action.sipuri || action.confNum,
  })),
  on(multicircuitActions.callWebRTCSuccess, (state) => ({
    ...state,
    onCall: true,
  })),
  on(multicircuitActions.dropWebRTCSuccess, (state) => ({
    ...state,
    onCall: false,
  })),
  on(multicircuitActions.loadSites, (state) => ({
    ...state,
    loading: true,
  })),
  on(multicircuitActions.loadlocalSites, (state) => ({
    ...state,
    loading: true,
  })),
  on(multicircuitActions.loadSitesSuccess, (state, action) => ({
    ...state,
    remoteSites: action.payload,
    remoteSitesLoaded: true,
    remoteSitesGrouped: action.payload.map((meeting) => ({
      ...meeting,
      sitesGrouped: groupBy(meeting.sites, (site) => site.owner?.displayName || 'Shared'),
    })),
  })),
  on(multicircuitActions.loadLocalSitesSuccess, (state, action) => ({
    ...state,
    localSites: action.payload,
    localSitesLoaded: true,
    localSitesGrouped: action.payload.map((meeting) => {
      const sitesGroupedBy = groupBy(meeting.sites, (site) => site.owner?.displayName || 'Shared');
      const sitesGrouped = Object.keys(sitesGroupedBy).reduce(
        (acc, name, index) => {
          name !== 'Shared' &&
            acc.push({
              name,
              isGrouped: true,
              chipLabel: 'Participant',
              id: `${name}-${meeting.confNum}-${index}`,
              connectionStatus: !!(Object.values(sitesGroupedBy)[index] as any[] || []).filter((s: SitesDetails) => !!s.connected).length,
              talkingStatus: false,
              channelIds: ((Object.values(sitesGroupedBy)[index] as any[]) || []).map((i) => i.id),
              sitesGrouped: Object.values(sitesGroupedBy)[index],
            });
          console.log();
          return acc;
        },
        [...sitesGroupedBy['Shared']]
      )
      return {
        ...meeting,
        sitesGrouped
      };
    }),
  })),
  on(multicircuitActions.loadSitesError, (state) => ({
    ...state,
    loading: false,
    loaded: true,
  })),

  //Volume Toogle
  on(multicircuitActions.volumeToggle, (state) => ({
    ...state,
    loading: true,
  })),
  on(multicircuitActions.volumeToggleSuccess, (state, action) => ({
    ...state,
    loading: false,
  })),

  on(multicircuitActions.Error, (state) => ({
    ...state,
    loading: false,
  })),

  //Connected Status
  on(multicircuitActions.connected, (state, action) => ({
    ...state,
    localSitesGrouped: state.localSitesGrouped.map((groupedSite) => ({
      ...groupedSite,
      sitesGrouped: groupedSite.sitesGrouped?.map((subgroupedSite) => ({
        ...subgroupedSite,
        connectionStatus:
          (subgroupedSite.channelIds || []).includes('' + action.channelId) ||
          subgroupedSite.connectionStatus,
        sitesGrouped: subgroupedSite.sitesGrouped?.map((channel) => ({
          ...channel,
          connectionStatus: '' + channel.id === '' + action.channelId || channel.connectionStatus,
        })),
      })),
    })),
    localSites: state.localSites.map((site: Meeting) => ({
      ...site,
      sites: site?.sites?.map((channel) => ({
        ...channel,
        connectionStatus: '' + channel.id === '' + action.channelId || channel.connectionStatus,
      })),
    })),
    remoteSites: state.remoteSites.map((site: Meeting) => ({
      ...site,
      sites: site?.sites?.map((channel) => ({
        ...channel,
        connectionStatus: '' + channel.id === '' + action.channelId || channel.connectionStatus,
      })),
    })),
  })),
  on(multicircuitActions.disconnected, (state, action) => ({
    ...state,
    localSitesGrouped: state.localSitesGrouped.map((groupedSite) => ({
      ...groupedSite,
      sitesGrouped: groupedSite.sitesGrouped?.map((subgroupedSite) => ({
        ...subgroupedSite,
        connectionStatus: (subgroupedSite.channelIds || []).includes('' + action.channelId)
          ? false
          : subgroupedSite.connectionStatus,
        sitesGrouped: subgroupedSite.sitesGrouped?.map((channel) => ({
          ...channel,
          connectionStatus:
            '' + channel.id === '' + action.channelId ? false : channel.connectionStatus,
        })),
      })),
    })),
    localSites: state.localSites.map((site: Meeting) => ({
      ...site,
      sites: site?.sites?.map((channel) => ({
        ...channel,
        connectionStatus:
          '' + channel.id === '' + action.channelId ? false : channel.connectionStatus,
      })),
    })),
    remoteSites: state.remoteSites.map((site: Meeting) => ({
      ...site,
      sites: site?.sites?.map((channel) => ({
        ...channel,
        connectionStatus:
          '' + channel.id === '' + action.channelId ? false : channel.connectionStatus,
      })),
    })),
  })),

  //Set Volume
  on(multicircuitActions.setVolumeSuccess, (state, action) => ({
    ...state,
    localSitesGrouped: state.localSitesGrouped.map((groupedSite) => ({
      ...groupedSite,
      sitesGrouped: groupedSite.sitesGrouped?.map((subgroupedSite) => ({
        ...subgroupedSite,
        sitesGrouped: subgroupedSite.sitesGrouped?.map((channel) => ({
          ...channel,
          rxGain: '' + channel.id === '' + action.channelId ? action.rxGain : channel.rxGain,
          txGain: '' + channel.id === '' + action.channelId ? action.txGain : channel.txGain,
        })),
      })),
    })),
    localSites: state.localSites.map((site: Meeting) => ({
      ...site,
      sites: site?.sites?.map((channel) => ({
        ...channel,
        rxGain: '' + channel.id === '' + action.channelId ? action.rxGain : channel.rxGain,
        txGain: '' + channel.id === '' + action.channelId ? action.txGain : channel.txGain,
      })),
    })),
    remoteSites: state.remoteSites.map((site: Meeting) => ({
      ...site,
      sites: site?.sites?.map((channel) => ({
        ...channel,
        rxGain: '' + channel.id === '' + action.channelId ? action.rxGain : channel.rxGain,
        txGain: '' + channel.id === '' + action.channelId ? action.txGain : channel.txGain,
      })),
    })),
  })),

  //Talking Status
  on(multicircuitActions.talkingStatus, (state, action) => {
    return {
      ...state,
      localSitesGrouped: state.localSitesGrouped.map((groupedSite) => ({
        ...groupedSite,
        sitesGrouped: groupedSite.sitesGrouped?.map((subgroupedSite) => ({
          ...subgroupedSite,
          talkingStatus: (subgroupedSite.channelIds || []).includes('' + action.channelId)
            ? action.status
            : subgroupedSite.talkingStatus,
          sitesGrouped: subgroupedSite.sitesGrouped?.map((channel) => ({
            ...channel,
            talkingStatus:
              '' + channel.id === '' + action.channelId ? action.status : channel.talkingStatus,
          })),
        })),
      })),
      localSites: state.localSites.map((site: Meeting) => ({
        ...site,
        sites: site?.sites?.map((channel) => ({
          ...channel,
          talkingStatus:
            '' + channel.id === '' + action.channelId ? action.status : channel.talkingStatus,
        })),
      })),
      remoteSites: state.remoteSites.map((site: Meeting) => ({
        ...site,
        sites: site?.sites?.map((channel) => ({
          ...channel,
          talkingStatus:
            '' + channel.id === '' + action.channelId ? action.status : channel.talkingStatus,
        })),
      })),
    };
  }),

  //ring Down
  on(multicircuitActions.ringDown, (state) => ({
    ...state,
  })),

  on(multicircuitActions.loadMulticircuitSiteSuccess, (state, action) => ({
    ...state,
    subMulticircuit: action.payload,
  }))
);

const getMicStatus = (payload, site) => {
  const mutedMic = payload
    .filter((o1) => o1.sites.find((p1) => p1.id == site.id))[0]
    .sites.find((p1) => '' + p1.id === '' + site.id).mutedMic;
  return mutedMic;
};

const getSpeakerStatus = (payload, site) => {
  const mutedSpeaker = payload
    .filter((o1) => o1.sites.find((p1) => p1.id == site.id))[0]
    .sites.find((p1) => '' + p1.id === '' + site.id).mutedSpeaker;
  return mutedSpeaker;
};
