import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SharedModule } from '@shared/shared.module';
import { mcsRoutes } from './mcs.routes';
import { McsPageComponent } from './+mcs-page/mcs-page.component';
/* eslint-disable */
import { McsAppModule, MulticircuitEffects } from '@ethervox/mcs';
import { EffectsModule } from '@ngrx/effects';

/* eslint-enable */

@NgModule({
  imports: [
    CommonModule,
    McsAppModule,
    SharedModule,
    RouterModule.forChild(mcsRoutes),
    EffectsModule.forFeature([MulticircuitEffects]),
  ],
  declarations: [McsPageComponent],
})
export class MCSModule {}
