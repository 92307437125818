import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { mergeMap, map, catchError, take, tap, concatMap, switchMap, repeat } from 'rxjs/operators';
import { of, combineLatest, forkJoin } from 'rxjs';

import * as fromCommunity from './community.actions';
import * as fromSocket from './../../../../store/actions/socket.actions';
import { CommunityLineService } from '@resources/community-line/community-line.service';
import { BridgeService } from '@resources/bridge/bridge.service';
import { Store } from '@ngrx/store';
import { State, selectAllBroadcastGroups } from '..';
import { BroadcastGroupService } from '@resources/broadcast-group/broadcast-group.service';

@Injectable()
export class CommunityEffects {
  socketEvent$ = createEffect(
    () =>
      this.actions$
        .pipe(
          ofType(
            fromSocket.confJoin,
            fromSocket.confEnd,
            fromSocket.connectionState,
            fromSocket.handsetStatus
          )
        )
        .pipe(
          tap(() => setTimeout(() => this.store$.dispatch(fromCommunity.loadCommunity()), 1000))
        ),
    { dispatch: false }
  );

  loadCommunity$ = createEffect(() =>
    this.actions$
      .pipe(ofType(fromCommunity.loadCommunity))
      .pipe(
        switchMap(() =>
          forkJoin([
            this.bridgeService.getDynamicBridge(),
            this.communityLineService.getHandsets(),
            this.communityLineService.getCommunityCalls(),
            this.communityLineService.getCommunityLines(),
            this.broadcastGroupService.getAll(),
            this.communityLineService.getLocalSites(),
            this.communityLineService.getRemoteSites(),
          ])
        )
      )
      .pipe(
        map(
          ([
            dynamicBridges,
            handsets,
            calls,
            community,
            broadcastGroups,
            localSites,
            remoteSites,
          ]) =>
            fromCommunity.loadCommunitySuccess({
              dynamicBridges,
              community,
              calls,
              handsets,
              broadcastGroups,
              localSites,
              remoteSites,
            })
        )
      )
      .pipe(catchError((error) => of(fromCommunity.loadCommunityError({ error }))))
      .pipe(repeat())
  );

  constructor(
    private actions$: Actions,
    private store$: Store<State>,
    private bridgeService: BridgeService,
    private broadcastGroupService: BroadcastGroupService,
    private communityLineService: CommunityLineService
  ) {}
}
