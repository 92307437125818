import { Component, OnInit, Input } from '@angular/core';
import { Observable } from 'rxjs';

import { McsService } from './../../../common/services/mcs.service';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'ethervox-expandable-subtable',
  templateUrl: './expandable-subtable.component.html',
  styleUrls: ['./expandable-subtable.component.scss'],
})
export class ExpandableSubtableComponent implements OnInit {
  @Input() id: string;
  subtable$: Observable<any>;
  loading: boolean;

  columns = ['icon', 'label', 'number', 'lineId'];

  constructor(private mcsService: McsService) {}

  ngOnInit(): void {
    this.loading = true;
    this.subtable$ = this.mcsService.getMulticircuit(this.id).pipe(
      tap((res) => {
        this.loading = false;
      })
    );
  }
}
