import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AUTH_FEATURE_KEY, State, AuthPartialState } from './auth.reducer';

export const getAuthState = createFeatureSelector<AuthPartialState, State>(AUTH_FEATURE_KEY);

export const getAuthenticated = createSelector(getAuthState, (state: State) => state.authenticated);

export const getAuthenticating = createSelector(
  getAuthState,
  (state: State) => state.authenticating
);

export const getToken = createSelector(getAuthState, (state: State) => state.token);

export const getAuthEntityPartialState = createSelector(
  getAuthState,
  (state: State) => state.entity
);

export const getAuthEntityLoaded = createSelector(
  getAuthEntityPartialState,
  (state) => state.loaded
);

export const getAuthEntityLoading = createSelector(
  getAuthEntityPartialState,
  (state) => state.loading
);
