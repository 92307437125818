<h4 class="mat-title">
  <mat-icon>headset_mic</mat-icon>
  Handsets
</h4>
<card-list *ngIf="handsets$ | async as handsets" class="animated fadeIn content-list">
  <div class="card-item" *ngFor="let handset of handsets">
    <user-avatar>{{ handset.displayName.charAt(0) }}</user-avatar>
    <div class="meta">
      <h3>{{ handset.displayName }}</h3>
      <span class="status">{{ handset.webRTC ? 'webRTC enabled' : 'webRTC disabled' }}</span>
    </div>
    <div class="controls--right">
      <ui-button class="icon" tooltip="Show Details" (click)="showHandsetDetails(handset)">
        <mat-icon>settings</mat-icon>
      </ui-button>
    </div>
  </div>
</card-list>
