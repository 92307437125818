import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { Handset } from './handset.model';

// getHandsets
export const getHandsets = createAction('[Handset/API] Get Handsets');

export const getHandsetsSuccess = createAction(
  '[Handset/API] Get Handsets Success',
  props<{ handsets: Handset[] }>()
);

export const getHandsetsError = createAction(
  '[Handset/API] Get Handsets Error',
  props<{ error: unknown }>()
);

// putMCOnHandset
export const putMCOnHandset = createAction(
  '[Handset/API] Put MC On Handset',
  props<{ lineId: string; callId: string }>()
);

export const putMCOnHandsetSuccess = createAction(
  '[Handset/API] Put MC On Handset Success',
  props<any>()
);

export const putMCOnHandsetError = createAction(
  '[Handset/API] Put MC On Handset Error',
  props<{ error: unknown }>()
);

// putOnHandset
export const putOnHandset = createAction(
  '[Handset/API] Put On Handset',
  props<{ handsetId: string; bridgeId: string; webrtc?: boolean }>()
);

export const putOnHandsetSuccess = createAction(
  '[Handset/API] Put On Handset Success',
  props<{ handsetId: string; bridgeId: string }>()
);

export const putOnHandsetError = createAction(
  '[Handset/API] Put On Handset Error',
  props<{ error: unknown }>()
);

// dropHandset
export const dropHandset = createAction(
  '[Handset/API] Drop Handset',
  props<{ handsetId: string; bridgeId: string; webrtc?: boolean }>()
);

export const dropHandsetSuccess = createAction(
  '[Handset/API] Drop Handset Success',
  props<{ handsetId: string; bridgeId: string }>()
);

export const dropHandsetError = createAction(
  '[Handset/API] Drop Handset Error',
  props<{ error: unknown }>()
);

export const loadHandsets = createAction(
  '[Handset/API] Load Handsets',
  props<{ handsets: Handset[] }>()
);

export const addHandset = createAction('[Handset/API] Add Handset', props<{ handset: Handset }>());

export const upsertHandset = createAction(
  '[Handset/API] Upsert Handset',
  props<{ handset: Handset }>()
);

export const addHandsets = createAction(
  '[Handset/API] Add Handsets',
  props<{ handsets: Handset[] }>()
);

export const upsertHandsets = createAction(
  '[Handset/API] Upsert Handsets',
  props<{ handsets: Handset[] }>()
);

export const updateHandset = createAction(
  '[Handset/API] Update Handset',
  props<{ handset: Update<Handset> }>()
);

export const updateHandsets = createAction(
  '[Handset/API] Update Handsets',
  props<{ handsets: Update<Handset>[] }>()
);

export const deleteHandset = createAction('[Handset/API] Delete Handset', props<{ id: string }>());

export const deleteHandsets = createAction(
  '[Handset/API] Delete Handsets',
  props<{ ids: string[] }>()
);

export const clearHandsets = createAction('[Handset/API] Clear Handsets');
