import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, switchMap, tap } from 'rxjs/operators';

import { State } from '../store';
import { selectAppsLoaded, selectApps } from '../store/admin.selectors';
import { loadApps } from '../store/admin.actions';

@Component({
  selector: 'admin-page',
  templateUrl: './admin-page.component.html',
  styleUrls: ['./admin-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdminPageComponent implements OnInit {

  apps$: Observable<any[]>;

  constructor(private store: Store<State>) { }

  ngOnInit() {
    this.apps$ = this.store.select(selectAppsLoaded)
      .pipe(tap((loaded) => !loaded && this.store.dispatch(loadApps())))
      .pipe(filter((loaded) => !!loaded))
      .pipe(switchMap(() => this.store.select(selectApps)))
  }

}
