import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Twosome } from '../../store/twosome/twosome.model';
import { Handset } from '../../store/handset/handset.model';
import { BridgeHelper } from '../../../../common/helpers/bridge.helper';

@Component({
  selector: 'twosome-bridges',
  templateUrl: './twosome-bridges.component.html',
  styleUrls: ['./twosome-bridges.component.scss']
})
export class TwosomeBridgesComponent {

  @Input() twosomes: Twosome[];
  @Input() handsets: Handset[];

  @Output() ringdown = new EventEmitter<Twosome>();
  @Output() reject = new EventEmitter<Twosome>();
  @Output() accept = new EventEmitter<Twosome>();
  @Output() resend = new EventEmitter<Twosome>();
  @Output() requestContact = new EventEmitter<Twosome>();
  @Output() updateSettings = new EventEmitter<Twosome>();
  @Output() putOnHandset = new EventEmitter<{ twosome: Twosome, handset: Handset }>();
  @Output() dropHandset = new EventEmitter<{ twosome: Twosome, handset: Handset }>();

  getInitials = BridgeHelper.getInitials;
}
