import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ResourceAbstract } from '@resources/resources.abstract';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AdminService extends ResourceAbstract {

  constructor(protected httpClient: HttpClient) {
    super(httpClient);
  }

  getApps(): Observable<any[]> {
    return this.get<any[]>('ethervox/app');
  }

  getAppNodes(id: string): Observable<any[]> {
    return this.get<any[]>(`ethervox/app/${id}/sdnx`);
  }
}
