import { createAction, props } from '@ngrx/store';
import { Meeting, SitesDetails } from './../../common/models/site.model';

export const initWebRTC = createAction('[multicircuit] Init WebRTC');
export const transcript = createAction('[multicircuit] Transcript');

export const callWebRTC = createAction(
  '[multicircuit] Call WebRTC',
  props<{ confNum?: any; sipuri?: any }>()
);
export const callWebRTCSuccess = createAction('[multicircuit] Call WebRTC Success');
export const callWebRTCError = createAction(
  '[multicircuit] Call WebRTC Error',
  props<{ error: unknown }>()
);

export const dropWebRTC = createAction('[multicircuit] Drop WebRTC');
export const dropWebRTCSuccess = createAction('[multicircuit] Drop WebRTC Success');
export const dropWebRTCError = createAction(
  '[multicircuit] Drop WebRTC Error',
  props<{ error: unknown }>()
);

// loadSites
export const loadSites = createAction('[multicircuit] Load Remote Sites');
export const loadSitesSuccess = createAction(
  '[multicircuit] Load Remote Sites Success',
  props<{ payload: Meeting[]; isLocal?: boolean }>()
);
export const loadSitesError = createAction(
  '[multicircuit] Load Remote Sites Error',
  props<{ error: unknown }>()
);

// loadLocalSites
export const loadlocalSites = createAction('[multicircuit] Load Local Sites');
export const loadLocalSitesSuccess = createAction(
  '[multicircuit] Load Local Sites Success',
  props<{ payload: Meeting[] }>()
);
export const loadLocalSitesError = createAction(
  '[multicircuit] Load Local Sites Error',
  props<{ error: unknown }>()
);

export const loadMulticircuitSite = createAction(
  '[multicircuit] Load Multicircuit',
  props<{ siteId: string }>()
);
export const loadMulticircuitSiteSuccess = createAction(
  '[multicircuit] Load Multicircuit Sites Success',
  props<{ payload: Meeting }>()
);

// set Selected Channel
export const setSelectedMeeting = createAction(
  '[multicircuit] Set Selected Meeting',
  props<{ payload: Meeting }>()
);
export const unsetsetSelectedMeeting = createAction('[multicircuit] Unset Selected Site');

//Volume Toggle
export const volumeToggle = createAction(
  '[multicircuit/context] Volume Toggle',
  props<{ siteId: string; device: string; action: string; local: boolean }>()
);
export const volumeToggleSuccess = createAction(
  '[multicircuit/context] Volume Toggle Success',
  props<{ payload; isLocal }>()
);

export const Error = createAction('[multicircuit]  Error', props<{ error: unknown }>());
export const loadSitesToggle = createAction('[multicircuit] Load Sites Toggle');
export const loadlocalSitesToggle = createAction('[multicircuit] Load local Sites Toggle');
export const loadToggleSuccess = createAction(
  '[multicircuit] Load Sites Toggle Success',
  props<{ payload: any }>()
);

// set Selected Channel
export const setSelectedChannel = createAction(
  '[multicircuit] Set Selected Channel',
  props<{ payload: SitesDetails }>()
);
export const connected = createAction('[Socket] connected', props<{ channelId: string }>());
export const disconnected = createAction('[Socket] disconnected', props<{ channelId: string }>());
export const unsetSelectedChannel = createAction('[multicircuit/context] Unset Selected Channel');

//SetVolume
export const setVolume = createAction(
  '[multicircuit] setVolume',
  props<{ channelId: string; txGain: number; rxGain: number }>()
);
export const setVolumeSuccess = createAction(
  '[multicircuit] setVolume Success',
  props<{ channelId: string; txGain: number; rxGain: number }>()
);
export const setVolumeError = createAction(
  '[multicircuit] setVolume Error',
  props<{ error: unknown }>()
);
export const talkingStatus = createAction(
  '[Socket] Channel Status',
  props<{ channelId: string; status: any; callerIDNum: number }>()
);

//ringDown
export const ringDown = createAction('[multicircuit] ring down', props<{ lineId: string }>());

//getMcBridges
export const getMcBridges = createAction('[multicircuit] Get MC Bridges');
export const getMcBridgesSuccess = createAction(
  '[multicircuit] Get MC Bridges Success',
  props<{ bridges: any[] }>()
);
export const getMcBridgesError = createAction(
  '[multicircuit] Get MC Bridges Error',
  props<{ error: unknown }>()
);

//createMcBridge
export const createMcBridge = createAction(
  '[multicircuit] Create Bridge',
  props<{ sites: Meeting[] }>()
);
export const createMcBridgeSuccess = createAction('[multicircuit] Create Bridge Success');
export const createMcBridgeError = createAction(
  '[multicircuit] Create Bridge Error',
  props<{ error: unknown }>()
);

//dropMcBridge
export const dropMcBridge = createAction('[multicircuit] Drop MC Bridge ', props<{ id: string }>());
export const dropMcBridgeSuccess = createAction('[multicircuit] Drop MC Bridge  Success');
export const dropMcBridgeError = createAction(
  '[multicircuit] Drop MC Bridge  Error',
  props<{ error: unknown }>()
);

//dropMcHandset
export const dropMcHandset = createAction(
  '[multicircuit] Drop MC Handset ',
  props<{ callId: string; handsetId: string }>()
);
export const dropMcHandsetSuccess = createAction('[multicircuit] Drop MC Handset  Success');
export const dropMcHandsetError = createAction(
  '[multicircuit] Drop MC Handset  Error',
  props<{ error: unknown }>()
);
