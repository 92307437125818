<form [formGroup]="formGroup" autocomplete="off">
  <mat-form-field appearance="fill">
    <mat-label>Search for contacts...</mat-label>
    <input formControlName="search" matInput>
    <mat-error *ngIf="formGroup.get('search').errors?.format">
      Invalid address
    </mat-error>
    <button type="button" mat-button *ngIf="search" matSuffix mat-icon-button (click)="search=''">
      <mat-icon>close</mat-icon>
    </button>
  </mat-form-field>
  <button type="submit" mat-flat-button color="primary" (click)="lookupContacts()">
    <mat-icon>search</mat-icon>
    <!-- Search -->
  </button>
</form>
