import { createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Contact } from './contact.model';
import * as ContactActions from './contact.actions';

export const contactsFeatureKey = 'contacts';
export const selectContactRequestId = ({ requestid }: Contact): string => requestid;
export const selectContactId = ({ id }: Contact): string => id;

export interface State extends EntityState<Contact> {
  loaded: boolean;
  loading: boolean;
}

export const adapter: EntityAdapter<Contact> = createEntityAdapter<Contact>({
  selectId: selectContactId,
});

export const initialState: State = adapter.getInitialState({
  loaded: false,
  loading: false,
});

export const reducer = createReducer(
  initialState,
  on(ContactActions.loadContacts, (state) => ({
    ...state,
    loading: true,
  })),
  on(ContactActions.loadContactsSuccess, (state, action) => ({
    ...adapter.setAll(action.contacts, state),
    loading: false,
    loaded: true,
  })),
  on(ContactActions.loadContactsError, (state) => ({
    ...state,
    loading: false,
    loaded: false,
  }))
);

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
