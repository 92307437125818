import { createSelector } from '@ngrx/store';
import { selectAdminState, adminFeatureKey } from './admin.reducer';

export const selectAdminSubstate = createSelector(
  selectAdminState,
  (state) => state[adminFeatureKey]
);

export const selectAppsLoaded = createSelector(
  selectAdminSubstate,
  (state) => state.loaded
);

export const selectAppsLoading = createSelector(
  selectAdminSubstate,
  (state) => state.loading
);

export const selectApps = createSelector(
  selectAdminSubstate,
  (state) => state.apps
);

export const selectAppNodes = createSelector(
  selectAdminSubstate,
  (state) => state.nodes
);
