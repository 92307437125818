import { McsService } from './../../../common/services/mcs.service';
import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  OnDestroy,
} from '@angular/core';
import { Meeting, SitesDetails } from './../../../common/models/site.model';
import { NestedTreeControl, CdkTree } from '@angular/cdk/tree';
import { SelectionModel } from '@angular/cdk/collections';
import { Store } from '@ngrx/store';
import { State } from '../../../store/app.reducer';
import * as multicircuitActions from '../../../store/multi-circuit/multicircuit-actions';

import { filter, switchMap, tap, map, take, share, takeUntil } from 'rxjs/operators';
import { Subject, Observable, BehaviorSubject, of, from } from 'rxjs';

@Component({
  selector: 'ethervox-expandable-tree',
  templateUrl: './expandable-tree.component.html',
  styleUrls: ['./expandable-tree.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExpandableTreeComponent implements OnInit, OnDestroy {
  _sites: Meeting[];

  @Input() set sites(sites: Meeting[]) {
    if (!sites?.length) {
      return;
    }
    this._sites = sites;
    this.sitesTree.expansionModel.clear();
    this.expansionModel.selected.forEach((node) => {
      this.sitesTree.expansionModel.select(node);
    });
  }

  @Input() chipLabel: string;
  @Input() subtable: boolean;
  @Input() onCall: boolean;

  @Output() expandNode = new EventEmitter();
  @Output() toggleMic = new EventEmitter();
  @Output() toggleSpeaker = new EventEmitter();
  @Output() webRTCcall = new EventEmitter();

  @ViewChild('tree', { static: false }) tree: CdkTree<unknown>;

  sitesTree = new NestedTreeControl<any>((node) => node.sitesGrouped, {
    trackBy: (n) => n.id,
  });
  expansionModel = new SelectionModel<string>(true);
  flexAccess$: Observable<any>;
  bridgeId$ = new BehaviorSubject('');
  flexAccessLoaded: boolean;
  flexAccesses = {};
  subtables = {};

  private destroyed$ = new Subject();

  constructor(private store: Store<State>, private mcsService: McsService) { }

  ngOnInit(): void {

    this.flexAccess$ = this.bridgeId$
      .pipe(filter((b) => !!b))
      .pipe(tap(() => (this.flexAccessLoaded = false)))
      .pipe(
        switchMap((bridgeId: string) =>
          this.flexAccesses[bridgeId]
            ? of(this.flexAccesses[bridgeId])
            : this.mcsService
              .getFlexAccess(bridgeId)
              .pipe(take(1))
              .pipe(tap(() => (this.flexAccessLoaded = true)))
              .pipe(map((res) => (res || []).pop()))
              .pipe(tap((res) => (this.flexAccesses[bridgeId] = res)))
        )
      )
      .pipe(share());
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  hasChild(_: number, node: Meeting): boolean {
    return !!node.sitesGrouped && node.sitesGrouped.length >= 0;
  }

  onToggleMic(site: SitesDetails, e: Event): void {
    e.stopPropagation();
    this.store.dispatch(
      multicircuitActions.volumeToggle({
        siteId: site.id,
        device: 'mic',
        action: site.mutedMic ? 'unmute' : 'mute',
        local: false,
      })
    );
  }

  onToggleSpeaker(site: SitesDetails, e: Event): void {
    e.stopPropagation();
    this.store.dispatch(
      multicircuitActions.volumeToggle({
        siteId: site.id,
        device: 'speaker',
        action: site.mutedSpeaker ? 'unmute' : 'mute',
        local: false,
      })
    );
  }

  clickPhone(site: SitesDetails, e: Event): void {
    e.stopPropagation();
    this.store.dispatch(
      multicircuitActions.ringDown({
        lineId: site.id,
      })
    );
  }

  onNodeExpanded(node: any) {
    if (node.sdnxnode) {
      this.expandNode.next(node);
    }
    if (node.isGrouped) return;
    this.bridgeId$.next(node.id);
  }

  refreshFlexAccess() {
    // let cachedBridgeId;
    this.bridgeId$.pipe(take(1)).subscribe((bridgeId) => {
      this.flexAccesses[bridgeId] = null;
      this.bridgeId$.next('');
      setTimeout(() => this.bridgeId$.next(bridgeId));
    });
  }

  openTranscriptionsSheet(node) {
    const openTranscriptionsSheetEvent = new CustomEvent('openTranscriptionsSheet', {
      detail: { confNum: node.confNum },
    });
    window.dispatchEvent(openTranscriptionsSheetEvent);
  }
}
