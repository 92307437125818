import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { IndexComponent } from './components/index/index.component';
import { homeRoutes } from './home.routes';
import { MulticircuitPageComponent } from './components/multicircuit/multicircuit.page.component';
import { MulticircuitContextPageComponent } from './components/multicircuit-context-page/multicircuit-context-page.component';
import { ChannelsComponent } from './components/channels/channels.component';
import { LoaderComponent } from '../common/helpers/loader/loader.component';
import { MulticircuitExpansionComponent } from './components/multicircuit-expansion/multicircuit-expansion.component';
import { ExpandableComponent } from './components/expandable/expandable.component';
import { ExpandableTreeComponent } from './components/expandable-tree/expandable-tree.component';
import { ExpandableSubtableComponent } from './components/expandable-subtable/expandable-subtable.component';

@NgModule({
  declarations: [
    IndexComponent,
    MulticircuitPageComponent,
    MulticircuitContextPageComponent,
    ChannelsComponent,
    MulticircuitExpansionComponent,
    LoaderComponent,
    ExpandableComponent,
    ExpandableTreeComponent,
    ExpandableSubtableComponent,
  ],
  exports: [
    IndexComponent,
    MulticircuitPageComponent,
    MulticircuitContextPageComponent,
    ChannelsComponent,
    MulticircuitExpansionComponent,
    LoaderComponent,
  ],
  imports: [SharedModule, RouterModule.forChild(homeRoutes)],
})
export class HomeModule {}
