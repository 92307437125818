/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
/* eslint-disable */
import { SessionService } from '../../../../../dashboard-ui/src/app/common/services/session/session.service';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { State } from '../../store/app.reducer';

import { SocketConnectorService } from '../../../../../../libs/ui-core/src/lib/services/socket-connector.service';
import {
  connected,
  disconnected,
  talkingStatus,
} from '../../store/multi-circuit/multicircuit-actions';

/* eslint-enable */
export interface CarrierListener<T = any> {
  $: Observable<T>;
}
let callStatus = false;

export interface CarrierObject {
  id: string;
}

@Injectable({
  providedIn: 'root',
})
export class SocketCarrierService {
  constructor(private store: Store<State>, private socketConnectorService: SocketConnectorService) {
    this.socketConnectorService.initialize(SessionService.token);
  }

  dispatchActions(): void {
    this.socketConnectorService.socket.on('event', (e) => {
      switch (e.event) {
        case 'conf-join':
          this.store.dispatch(connected({ channelId: e.channel_id }));
          break;

        case 'conf-end':
          this.store.dispatch(disconnected({ channelId: e.channel_id }));
          break;

        case 'conf-talking':
          this.store.dispatch(
            talkingStatus({
              channelId: e.channel_id,
              status: e.talking === 'on',
              callerIDNum: e.channel_id,
            })
          );
          this.store.dispatch(connected({ channelId: e.channel_id }));
          break;

        case 'conf-leave':
          callStatus = false;
          this.store.dispatch(
            talkingStatus({
              channelId: e.channel_id,
              status: callStatus,
              callerIDNum: e.channel_id,
            })
          );
          this.store.dispatch(disconnected({ channelId: e.channel_id }));
          break;
        default:
          break;
      }
    });
  }
}
