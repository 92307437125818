import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromMultiCircuit from './multicircuit.reducers';

const multicircuitFeatureKey = 'stateKey';

export const selectState = createFeatureSelector<fromMultiCircuit.State>(multicircuitFeatureKey);

export const selectMulticircuitState = createSelector(
  selectState,
  (state) => state[fromMultiCircuit.multicircuitKey]
);

export const selectOnCall = createSelector(selectMulticircuitState, (state) => {
  return state.onCall;
});

export const selectOnCallWith = createSelector(selectMulticircuitState, (state) => {
  return state.onCallWith;
});

export const selectSites = createSelector(selectMulticircuitState, (state) => {
  return state.sites;
});

export const selectLocalSites = createSelector(selectMulticircuitState, (state) => {
  return state.localSites;
});

export const selectLocalSitesGrouped = createSelector(selectMulticircuitState, (state) => {
  return state.localSitesGrouped;
});

export const selectLocalSitesLoaded = createSelector(selectMulticircuitState, (state) => {
  return state.localSitesLoaded;
});

export const selectRemoteSites = createSelector(selectMulticircuitState, (state) => {
  return state.remoteSites;
});

export const selectRemoteSitesGrouped = createSelector(selectMulticircuitState, (state) => {
  return state.remoteSitesGrouped;
});

export const selectRemoteSitesLoaded = createSelector(selectMulticircuitState, (state) => {
  return state.remoteSitesLoaded;
});

export const selectStatusMulticircuit = createSelector(selectMulticircuitState, (state) => {
  return state.loaded;
});
export const selectLoadingStatusMulticircuit = createSelector(selectMulticircuitState, (state) => {
  return state.loading;
});

export const selectIsLocal = createSelector(selectMulticircuitState, (state) => {
  return state.isLocal;
});

export const selectSelectedMeeting = createSelector(selectMulticircuitState, (state) => {
  return state.sites?.find((p1) => p1.selected == true);
});

export const selectSubMulticircuit = createSelector(selectMulticircuitState, (state) => {
  return state.subMulticircuit;
});

export const selectMcBridges = createSelector(selectMulticircuitState, (state) => {
  return state.mcBridges.bridges;
});

export const selectMcBridgesLoading = createSelector(selectMulticircuitState, (state) => {
  return state.mcBridges.loading;
});

export const selectMcBridgesLoaded = createSelector(selectMulticircuitState, (state) => {
  return state.mcBridges.loaded;
});
