<div class="container">
  <ng-container *ngFor="let stream of streams$ | async">
    <mat-card class="animated fadeIn">
      <user-avatar>
        {{stream.id.charAt(0).toUpperCase()}}
      </user-avatar>
      <span class="meta mat-body-2">
        <ng-container *ngFor="let user of stream.users; let last = last">
          {{user.displayName}}<ng-container *ngIf="!last">, </ng-container>
        </ng-container>
        <span class="id mat-caption">
          {{stream.id}}
        </span>
      </span>
    </mat-card>
  </ng-container>
</div>
