import { Routes } from '@angular/router';
import { IndexComponent } from './components/index/index.component';
import { MulticircuitPageComponent } from './components/multicircuit/multicircuit.page.component';
import { ExpandableComponent } from './components/expandable/expandable.component';

export const homeRoutes: Routes = [
  {
    path: '',
    component: ExpandableComponent,
  },
];
