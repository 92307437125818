import { Sort } from '@angular/material/sort';
import { get, isNumber } from 'lodash';

import { Identifiable } from './../models/identifiable';
import { FilterBase } from './../models/filters';

export abstract class ModelAbstract<T extends Identifiable, K extends FilterBase> {
  items: T[];

  constructor(items: T[]) {
    this.items = [...items];
  }

  abstract filter(filters: K): ModelAbstract<T, K>;

  sort(sort: Sort): ModelAbstract<T, K> {
    if (sort && sort.active) {
      const desc = sort.direction === 'desc' ? -1 : 1;
      this.items = this.items.sort((a, b) => {
        const aVal = (get(a, sort.active) || 0) + '';
        const bVal = (get(b, sort.active) || 0) + '';
        return isNumber(aVal) && isNumber(bVal)
          ? aVal > bVal
            ? desc
            : desc * -1
          : aVal.toLocaleLowerCase().localeCompare(bVal.toLocaleLowerCase()) * desc;
      });
    }
    return this;
  }

  get(): T[] {
    return this.items;
  }
}
