import { createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Intercom } from './intercom.model';
import * as IntercomActions from './intercom.actions';
import * as CommunityActions from './../community/community.actions';
import { Handset } from '../handset/handset.model';

export const intercomsFeatureKey = 'intercoms';

export interface State extends EntityState<Intercom> {
  // additional entities state properties
  loaded: boolean;
  loading: boolean;
}

export const adapter: EntityAdapter<Intercom> = createEntityAdapter<Intercom>({
  selectId: selectIntercomId,
  sortComparer: sortByCreatedAt,
});

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
  loaded: false,
  loading: false,
});

export function selectIntercomId(a: Intercom): string {
  return a.id;
}

export function sortByCreatedAt(a: Intercom, b: Intercom): number {
  return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
}

const findConnectedHandset = (intercom: Intercom, handsets: Handset[], dynamicBridges) => {
  const dynamicBridge = dynamicBridges.find(
    (b) => intercom.ownerBridge.bridge.confNum === b.dynamicBridge.called
  );
  if (!dynamicBridge) return;
  const handset = handsets.find(
    (h) => h.bridge.bridge['confNum'] === dynamicBridge.dynamicBridge.destnum
  );
  return handset;
};

export const reducer = createReducer(
  initialState,
  on(IntercomActions.loadIntercoms, (state) => ({
    ...state,
    loading: true,
  })),
  on(IntercomActions.loadIntercomsError, (state) => ({
    ...state,
    loading: false,
    loaded: false,
  })),
  on(IntercomActions.loadIntercomsSuccess, (state, action) => {
    const intercoms = adapter.setAll(
      action.intercoms.map((intercom) => ({
        ...intercom,
      })),
      state
    );
    return {
      ...intercoms,
      loading: false,
      loaded: true,
    };
  }),
  on(CommunityActions.loadCommunitySuccess, (state, action) => ({
    ...adapter.map(
      (intercom) => ({
        ...intercom,
        onHandset: findConnectedHandset(intercom, action.handsets, action.calls),
      }),
      state
    ),
  })),
  on(IntercomActions.addIntercom, (state, action) => adapter.addOne(action.intercom, state)),
  on(IntercomActions.upsertIntercom, (state, action) => adapter.upsertOne(action.intercom, state)),
  on(IntercomActions.addIntercoms, (state, action) => adapter.addMany(action.intercoms, state)),
  on(IntercomActions.upsertIntercoms, (state, action) =>
    adapter.upsertMany(action.intercoms, state)
  ),
  on(IntercomActions.updateIntercom, (state, action) => adapter.updateOne(action.intercom, state)),
  on(IntercomActions.updateIntercoms, (state, action) =>
    adapter.updateMany(action.intercoms, state)
  ),
  on(IntercomActions.deleteIntercom, (state, action) => adapter.removeOne(action.id, state)),
  on(IntercomActions.deleteIntercoms, (state, action) => adapter.removeMany(action.ids, state)),
  on(IntercomActions.clearIntercoms, (state) => adapter.removeAll(state))
);

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
