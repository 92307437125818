import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { dashboardApiUrl } from '../../const/api.const';
import { ServiceRequestOptions } from '@ethervox/ui';

@Injectable({
  providedIn: 'root',
})
export class DashboardApiService {
  constructor(private httpClient: HttpClient) {}

  // eslint-disable-next-line
  searchUsers(searchString: string): Observable<any> {
    return this.httpClient.get(`${dashboardApiUrl}/user-search?searchString=` + searchString);
  }

  createRoom(contactId: string): Observable<unknown> {
    return this.httpClient.post(`${dashboardApiUrl}/room`, { contactId }, this._requestOptions);
  }

  private get _requestOptions(): ServiceRequestOptions {
    const headers = {
      Authorization: `Bearer ${window['token']}`,
    };
    return { headers, observe: 'body' as const };
  }
}
