import { Component, OnInit } from '@angular/core';
import { SymphonyService } from '../../../common/resources/symphony/symphony.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'hoot-page',
  templateUrl: './hoot-page.component.html',
  styleUrls: ['./hoot-page.component.scss'],
})
export class HootPageComponent implements OnInit {
  streams$: Observable<unknown>;

  constructor(private symphonyService: SymphonyService) {}

  ngOnInit(): void {
    this.streams$ = this.symphonyService.getStreams();
  }
}
