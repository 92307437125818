import { MatDialog } from '@angular/material/dialog';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, switchMap, catchError, tap, mergeMap, repeat } from 'rxjs/operators';
import { of, from } from 'rxjs';

import * as fromTwosome from './twosome.actions';
import { dialogSize } from '@const/ui.const';
import { BridgeSettingsDialogComponent } from '@shared/dialogs/bridge-settings-dialog/bridge-settings-dialog.component';
import { Twosome } from './twosome.model';
import { SnackbarService } from '@services/snackbar/snackbar.service';
import { CommunityLineService } from '@resources/community-line/community-line.service';
import { SocketCarrierService } from '@services/socket-carrier/socket-carrier.service';
import { DialogService } from '@services/dialog/dialog.service';

@Injectable()
export class TwosomeEffects {
  updateSettings$ = createEffect(() =>
    this.actions$
      .pipe(ofType(fromTwosome.updateSettings))
      .pipe(
        map((twosome) =>
          this.dialog.open<BridgeSettingsDialogComponent, unknown, Twosome>(
            BridgeSettingsDialogComponent,
            {
              width: dialogSize.MEDIUM,
              data: { twosome },
            }
          )
        )
      )
      .pipe(switchMap((d) => d.afterClosed()))
      .pipe(map((twosome) => fromTwosome.updateSettingsSuccess({ twosome })))
      .pipe(tap(() => this.snackbar.openSimple('Settings have been updated')))
      .pipe(catchError((error) => of(fromTwosome.updateSettingsError({ error }))))
      .pipe(repeat())
  );

  resendRequest$ = createEffect(() =>
    this.actions$.pipe(ofType(fromTwosome.resendRequest)).pipe(
      mergeMap(({ twosome }) =>
        this.communityLineService
          .resendLine(twosome.requestid, '5e1cd8f0b452ca743b86482e')
          .pipe(map(() => fromTwosome.resendRequestSuccess({ twosome })))
          .pipe(tap(() => this.snackbar.openSimple('Bridge request has been sent')))
          .pipe(catchError((error) => of(fromTwosome.resendRequestError({ error }))))
          .pipe(repeat())
      )
    )
  );

  acceptContact$ = createEffect(() =>
    this.actions$.pipe(ofType(fromTwosome.acceptContact)).pipe(
      switchMap(({ twosome }) =>
        this.dialogService
          .openConfirmationDialog<Twosome>('Are you sure?', twosome)
          .pipe(switchMap(({ requestid }) => from(this.socketCarrier.acceptBridge(requestid))))
          .pipe(map(() => fromTwosome.acceptContactSuccess({ twosome })))
          .pipe(tap(() => this.snackbar.openSimple('Bridge has been accepted')))
          .pipe(catchError((error) => of(fromTwosome.acceptContactError({ error }))))
          .pipe(repeat())
      )
    )
  );

  rejectContact$ = createEffect(() =>
    this.actions$.pipe(ofType(fromTwosome.rejectContact)).pipe(
      switchMap(({ twosome }) =>
        this.dialogService
          .openConfirmationDialog<Twosome>('Are you sure?', twosome)
          .pipe(switchMap(({ requestid }) => from(this.socketCarrier.rejectBridge(requestid))))
          .pipe(map(() => fromTwosome.rejectContactSuccess({ twosome })))
          .pipe(tap(() => this.snackbar.openSimple('Bridge has been rejected')))
          .pipe(catchError((error) => of(fromTwosome.rejectContactError({ error }))))
          .pipe(repeat())
      )
    )
  );

  ringdown$ = createEffect(() =>
    this.actions$.pipe(ofType(fromTwosome.ringdown)).pipe(
      switchMap(({ twosome }) =>
        from(this.socketCarrier.ringDown(twosome))
          .pipe(map(() => fromTwosome.ringdownSuccess({ twosome })))
          .pipe(tap(() => this.snackbar.openSimple('Ringdown sent')))
          .pipe(catchError((error) => of(fromTwosome.ringdownError({ error }))))
          .pipe(repeat())
      )
    )
  );

  constructor(
    private actions$: Actions,
    private communityLineService: CommunityLineService,
    private socketCarrier: SocketCarrierService,
    private snackbar: SnackbarService,
    private dialogService: DialogService,
    private dialog: MatDialog
  ) {}
}
