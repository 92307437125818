import { Component, Input } from '@angular/core';

@Component({
  selector: 'direction-indicator',
  templateUrl: './direction-indicator.component.html',
  styleUrls: ['./direction-indicator.component.scss']
})
export class DirectionIndicatorComponent {

  @Input() direction: string;

}
