import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';

import { AdminService } from '@resources/admin/admin.service';
import * as fromAdmin from './admin.actions';
import { from, of } from 'rxjs';

@Injectable()
export class AdminEffects {

  loadApps$ = createEffect(() => this.actions$.pipe(
    ofType(fromAdmin.loadApps),
    switchMap(() => this.adminService.getApps()
      .pipe(
        map(apps => fromAdmin.loadAppsSuccess({ apps })),
        catchError((error) => of(fromAdmin.loadAppsError({ error })))
      ))
  ));

  loadAppNodes$ = createEffect(() => this.actions$.pipe(
    ofType(fromAdmin.loadAppNodes),
    switchMap(({ id }) => this.adminService.getAppNodes(id)
      .pipe(
        map(nodes => fromAdmin.loadAppNodesSuccess({ nodes })),
        catchError((error) => of(fromAdmin.loadAppNodesError({ error })))
      ))
  ));

  constructor(
    private actions$: Actions,
    // private snackbar: SnackbarService,
    // private dialogService: DialogService,
    private adminService: AdminService
  ) { }
}
