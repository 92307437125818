import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Meeting, SitesDetails } from '../../../common/models/site.model';
import { Store } from '@ngrx/store';
import { State, selectSelectedSite, selectIsLocal } from '../../../store/app.reducer';
import * as multicircuitActions from '../../../store/multi-circuit/multicircuit-actions';
import { takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'ethervox-channels',
  templateUrl: './channels.component.html',
  styleUrls: ['./channels.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChannelsComponent implements OnInit {
  selectedSite$: Observable<Meeting>;
  activeChanel$: Observable<any>;
  isLocal: boolean;
  activeCalls: Array<string> = [];
  componentDestroyed$: Subject<boolean> = new Subject();
  currentRow;
  collapsed = true;

  constructor(private store: Store<State>) {}

  public selectRow(payload: SitesDetails): void {
    this.currentRow = payload?.id;
    this.store.dispatch(multicircuitActions.setSelectedChannel({ payload }));
    if (!this.isLocal) {
      this.store.dispatch(multicircuitActions.loadMulticircuitSite({ siteId: this.currentRow }));
    }
  }

  ngOnInit(): void {
    this.selectedSite$ = this.store.select(selectSelectedSite);
    this.store
      .select(selectIsLocal)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((p) => {
        this.isLocal = p;
      });
  }

  ngOnDestroy() {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  public collapse(payload) {
    this.collapsed = !this.collapsed && this.currentRow + '' === payload?.id + '';
  }

  public toggle(item: any, type: string): void {
    let action;
    if (type === 'mic') {
      action = !(Boolean(item.mutedMic) === true);
    } else {
      action = !(Boolean(item.mutedSpeaker) === true);
    }
    const mutestatus = action ? 'mute' : 'unmute';
    //update values on server
    this.store.dispatch(
      multicircuitActions.volumeToggle({
        siteId: item.id,
        device: type,
        action: mutestatus,
        local: this.isLocal,
      })
    );
  }

  public ringDown(item: SitesDetails): void {
    this.store.dispatch(
      multicircuitActions.ringDown({
        lineId: item.id,
      })
    );
  }
}
