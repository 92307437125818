<h4 class="mat-title">
  <mat-icon>hub</mat-icon>
  Bridged Multi-Circuits
</h4>
<div class="create">
  <mat-form-field>
    <mat-label>Select to create</mat-label>
    <mat-select [(ngModel)]="bridgeToCreate" multiple>
      <mat-option *ngFor="let site of sites" [value]="site">{{ site.label }}</mat-option>
    </mat-select>
  </mat-form-field>
  <button mat-button color="primary" [disabled]="bridgeToCreate.length < 2" (click)="create()">
    Create
  </button>
</div>
<card-list *ngIf="bridges$ | async as bridges">
  <div
    *ngFor="let bridge of bridges; let index = index"
    [id]="'line__' + bridge.id"
    class="card-item {{ bridge.state }} {{ bridge.onHandset ? 'connected' : '' }}"
  >
    <user-avatar>
      {{ index + 1 }}
    </user-avatar>
    <div class="meta">
      <h3>
        <span>{{ bridge.bridgechannelnum }}</span>
      </h3>
      <span class="status">
        {{ bridge.formattedTime }}
      </span>
      <!-- <span class="status">
        {{ twosome.state }}<ng-container *ngIf="twosome.onHandset">, connected</ng-container>
      </span> -->
    </div>

    <div class="controls--right">
      <handset-selector
        (selected)="putOnHandset.emit({ callId: bridge.id, lineId: $event.sipLine })"
        (unselected)="dropHandset(bridge.id, bridge)"
        [connected]="bridge.onHandset"
        [handsets]="handsets"
      >
      </handset-selector>
      <ui-button class="icon" color="warn" tooltip="Drop Bridge" (click)="drop(bridge.id)">
        <mat-icon>clear</mat-icon>
      </ui-button>
    </div>
  </div>
</card-list>
