import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { BroadcastGroup } from './broadcast-group.model';

// loadBroadcastGroups
export const loadBroadcastGroups = createAction('[BroadcastGroup/API] Load BroadcastGroups');

export const loadBroadcastGroupsSuccess = createAction(
  '[BroadcastGroup/API] Load BroadcastGroups Success',
  props<{ broadcastGroups: BroadcastGroup[] }>()
);

export const loadBroadcastGroupsError = createAction(
  '[BroadcastGroup/API] Load BroadcastGroups Error',
  props<{ error: unknown }>()
);

// attachTwosome
export const attachTwosome = createAction(
  '[BroadcastGroup/API] Attach Twosome',
  props<{ broadcastGroupId: string; twosomeId: string }>()
);

export const attachTwosomeSuccess = createAction(
  '[BroadcastGroup/API] Attach Twosome Success',
  props<{ broadcastGroup: BroadcastGroup }>()
);

export const attachTwosomeError = createAction(
  '[BroadcastGroup/API] Attach Twosome Error',
  props<{ error: unknown }>()
);

// detachTwosome
export const detachTwosome = createAction(
  '[BroadcastGroup/API] Detach Twosome',
  props<{ broadcastGroupId: string; twosomeId: string }>()
);

export const detachTwosomeSuccess = createAction(
  '[BroadcastGroup/API] Detach Twosome Success',
  props<{ broadcastGroup: BroadcastGroup }>()
);

export const detachTwosomeError = createAction(
  '[BroadcastGroup/API] Detach Twosome Error',
  props<{ error: unknown }>()
);

// createBroadcastGroup
export const createBroadcastGroup = createAction(
  '[BroadcastGroup/API] Create BroadcastGroup',
  props<{ broadcastGroup?: Partial<BroadcastGroup> }>()
);

export const createBroadcastGroupSuccess = createAction(
  '[BroadcastGroup/API] Create BroadcastGroup Success',
  props<{ broadcastGroup: BroadcastGroup }>()
);

export const createBroadcastGroupError = createAction(
  '[BroadcastGroup/API] Create BroadcastGroup Error',
  props<{ error: unknown }>()
);

// updateBroadcastGroup
export const updateBroadcastGroup = createAction(
  '[BroadcastGroup/API] Update BroadcastGroup',
  props<{ broadcastGroup: Partial<BroadcastGroup> }>()
);

export const updateBroadcastGroupSuccess = createAction(
  '[BroadcastGroup/API] Update BroadcastGroup Success',
  props<{ broadcastGroup: BroadcastGroup }>()
);

export const updateBroadcastGroupError = createAction(
  '[BroadcastGroup/API] Update BroadcastGroup Error',
  props<{ error: unknown }>()
);

// deleteBroadcastGroup
export const deleteBroadcastGroup = createAction(
  '[BroadcastGroup/API] Delete BroadcastGroup',
  props<{ id: string }>()
);

export const deleteBroadcastGroupSuccess = createAction(
  '[BroadcastGroup/API] Delete BroadcastGroup Success',
  props<{ id: string }>()
);

export const deleteBroadcastGroupError = createAction(
  '[BroadcastGroup/API] Delete BroadcastGroup Error',
  props<{ error: unknown }>()
);

export const upsertBroadcastGroup = createAction(
  '[BroadcastGroup/API] Upsert BroadcastGroup',
  props<{ broadcastGroup: BroadcastGroup }>()
);

export const addBroadcastGroups = createAction(
  '[BroadcastGroup/API] Add BroadcastGroups',
  props<{ broadcastGroups: BroadcastGroup[] }>()
);

export const upsertBroadcastGroups = createAction(
  '[BroadcastGroup/API] Upsert BroadcastGroups',
  props<{ broadcastGroups: BroadcastGroup[] }>()
);

export const updateBroadcastGroups = createAction(
  '[BroadcastGroup/API] Update BroadcastGroups',
  props<{ broadcastGroups: Update<BroadcastGroup>[] }>()
);

export const deleteBroadcastGroups = createAction(
  '[BroadcastGroup/API] Delete BroadcastGroups',
  props<{ ids: string[] }>()
);

export const clearBroadcastGroups = createAction('[BroadcastGroup/API] Clear BroadcastGroups');
