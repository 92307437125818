import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { ResourceAbstract } from '@resources/resources.abstract';
import { BroadcastGroup } from '../../../home/bridges/store/broadcast-group/broadcast-group.model';

@Injectable({
  providedIn: 'root',
})
export class BroadcastGroupService extends ResourceAbstract {
  constructor(protected httpClient: HttpClient) {
    super(httpClient);
  }

  getAll(): Observable<BroadcastGroup[]> {
    return this.get<BroadcastGroup[]>('ethervox/community/broadcast-group');
  }

  create(group: Partial<BroadcastGroup>): Observable<BroadcastGroup> {
    return this.post<BroadcastGroup>('ethervox/community/broadcast-group', { ...group });
  }

  update(id: string, name: string): Observable<BroadcastGroup> {
    return this.put<BroadcastGroup>(`ethervox/community/broadcast-group/${id}`, { name });
  }

  //eslint-disable-next-line
  remove(id: string): Observable<any> {
    return this.delete(`ethervox/community/broadcast-group/${id}`);
  }

  addBridge(groupId: string, bridgeId: string): Observable<BroadcastGroup> {
    return this.post<BroadcastGroup>(
      `ethervox/community/broadcast-group/${groupId}/dynamicline/${bridgeId}`
    );
  }

  //eslint-disable-next-line
  removeBridge(groupId: string, bridgeId: string): Observable<any> {
    return this.delete(`ethervox/community/broadcast-group/${groupId}/dynamicline/${bridgeId}`);
  }
}
