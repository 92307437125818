import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { mergeMap, map, catchError, switchMap, tap, filter, repeat } from 'rxjs/operators';
import { of } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';

import { IntercomService } from '@resources/intercom/intercom.service';
import * as fromIntercom from './intercom.actions';
import { SnackbarService } from '@services/snackbar/snackbar.service';
import { IntercomRequestDialogComponent } from '@shared/dialogs/intercom-request-dialog/intercom-request-dialog.component';
import { Store } from '@ngrx/store';
import { State } from './intercom.reducer';
import { selectAllContacts } from '..';
import { dialogSize } from '@const/ui.const';
import { DialogService } from '@services/dialog/dialog.service';

@Injectable()
export class IntercomEffects {
  loadIntercoms$ = createEffect(() =>
    this.actions$.pipe(ofType(fromIntercom.loadIntercoms)).pipe(
      mergeMap(
        () =>
          this.intercomService
            .getAll()
            .pipe(map((intercoms) => fromIntercom.loadIntercomsSuccess({ intercoms })))
            .pipe(catchError((error) => of(fromIntercom.loadIntercomsError({ error }))))
        // .pipe(repeat())
      )
    )
  );

  toggleIntercomDevice$ = createEffect(() =>
    this.actions$.pipe(ofType(fromIntercom.toggleIntercomDevice)).pipe(
      mergeMap(({ intercom, line, device, isMuted }) =>
        this.intercomService
          .toggleIntercomDevice(intercom, line, device, isMuted)
          .pipe(
            map(() => fromIntercom.toggleIntercomDeviceSuccess({ intercom, line, device, isMuted }))
          )
          .pipe(catchError((error) => of(fromIntercom.toggleIntercomDeviceError({ error }))))
      )
    )
  );

  openConnectionDialog$ = createEffect(() =>
    this.actions$.pipe(ofType(fromIntercom.openConnectionDialog)).pipe(
      switchMap(({ intercomId }) =>
        this.store$.select(selectAllContacts).pipe(
          switchMap(
            (contacts) =>
              this.dialog
                .open(IntercomRequestDialogComponent, {
                  width: dialogSize.MEDIUM,
                  data: { contacts, intercomId },
                })
                .afterClosed()
                .pipe(filter((r) => r && r.selectedContact))
                .pipe(
                  map(({ data, selectedContact }) =>
                    fromIntercom.addConnection({ data, selectedContact })
                  )
                )
            // .pipe(repeat())
          )
        )
      )
    )
  );

  addConnection$ = createEffect(
    () =>
      this.actions$
        .pipe(ofType(fromIntercom.addConnection))
        .pipe(
          switchMap(({ data, selectedContact }) =>
            this.intercomService.addConnection(selectedContact.id)
          )
        )
        .pipe(
          switchMap((intercom) => [
            fromIntercom.addConnectionSuccess({ intercom }),
            fromIntercom.loadIntercoms(),
          ])
        )
        .pipe(tap(() => this.snackbar.openSimple('Connection has been added to Intercom')))
        .pipe(catchError((error) => of(fromIntercom.addConnectionError({ error }))))
    // .pipe(repeat())
  );

  removeConnection$ = createEffect(() =>
    this.actions$.pipe(ofType(fromIntercom.removeConnection)).pipe(
      switchMap(
        ({ intercomId, contactId }) =>
          this.dialogService
            .openConfirmationDialog('Are you sure?', { intercomId, contactId })
            .pipe(
              switchMap(({ intercomId, contactId }) =>
                this.intercomService.removeConnection(intercomId, contactId)
              )
            )
            .pipe(map((intercom) => fromIntercom.removeConnectionSuccess({ intercom })))
            .pipe(tap(() => this.snackbar.openSimple('Connection has been removed from Intercom')))
            .pipe(catchError((error) => of(fromIntercom.removeConnectionError({ error }))))
        // .pipe(repeat())
      )
    )
  );

  constructor(
    private store$: Store<State>,
    private actions$: Actions,
    private snackbar: SnackbarService,
    private dialog: MatDialog,
    private dialogService: DialogService,
    private intercomService: IntercomService
  ) {}
}
