import { Component, Input } from '@angular/core';

import { Handset } from '../../store/handset/handset.model';
import { BridgeHelper } from '../../../../common/helpers/bridge.helper';

@Component({
  selector: 'handsets-bar',
  templateUrl: './handsets-bar.component.html',
  styleUrls: ['./handsets-bar.component.scss'],
})
export class HandsetsBarComponent {
  @Input() handsets: Handset[];

  getInitials = BridgeHelper.getInitials;
}
