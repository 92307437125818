import { ChangeDetectorRef, Component, OnInit } from '@angular/core';

import { UiTabs } from '@ethervox/ui';
import { Store } from '@ngrx/store';
import { SocketCarrierService } from '@services/socket-carrier/socket-carrier.service';
import { Observable } from 'rxjs';
import { Handset } from './bridges/store/handset/handset.model';
import { State } from '../store/reducers';
import { selectAllHandsets, selectTranscriptions } from './bridges/store/index';
import { loadCommunity } from './bridges/store/community/community.actions';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { SimpleUser } from 'sip.js/lib/platform/web';
import { CommunityLineService } from '../common/resources/community-line/community-line.service';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { TranscriptionSheetComponent } from '@shared/transcription-sheet/transcription-sheet.component';
import {
  dropWebRTC,
  initWebRTC,
  selectOnCall,
  selectOnCallWith,
  transcript,
} from '../../../../mcs-ui/src';

@Component({
  selector: 'home-page',
  templateUrl: `./home-page.component.html`,
  styleUrls: ['./home-page.component.scss'],
})
export class HomePageComponent implements OnInit {
  handsets$: Observable<Handset[]>;
  logoUrl = environment.logoUrl;

  onCall$: Observable<boolean>;
  onCallWith$: Observable<string>;

  simpleUser: SimpleUser;
  onCall: boolean;

  opened = false;

  tabs: UiTabs = [
    {
      label: 'Connections',
      routerLink: '/dashboard-home/bridges',
    },
    {
      label: 'Contacts',
      routerLink: '/dashboard-home/contacts',
    },
    {
      label: 'Multi-Circuits',
      routerLink: '/dashboard-home/mcs',
    },
    {
      label: 'Settings',
      routerLink: '/dashboard-home/handsets',
    },
    {
      label: 'Admin',
      routerLink: '/dashboard-home/admin',
    },
  ];

  transcriptions$: Observable<any[]>;
  transcriptionsConfNum: string;
  // @ViewChild('sidenav') vc:Child;

  constructor(
    private socketCarrierService: SocketCarrierService,
    private _bottomSheet: MatBottomSheet,
    private store: Store<State>,
    private communityLineService: CommunityLineService,
    private router: Router,
    private cdRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.transcriptions$ = this.store.select(selectTranscriptions);
    this.onCall$ = this.store.select(selectOnCall);
    this.onCallWith$ = this.store.select(selectOnCallWith);
    if (this.router.routerState.snapshot.url === '/') {
      this.router.navigate(['dashboard-home/contacts']);
    }
    this.store.dispatch(loadCommunity());
    this.handsets$ = this.store.select(selectAllHandsets);
    this.socketCarrierService.initialize().then(() => this.socketCarrierService.dispatchActions());
    this.store.dispatch(initWebRTC());
    window.addEventListener('openTranscriptionsSheet', (e: CustomEvent) => {
      const { detail } = e;
      const { confNum } = detail;
      this.opened = !this.opened;
      this.transcriptionsConfNum = confNum;
      this.cdRef.markForCheck();
      this.cdRef.detectChanges();
    });
  }

  dropWebRTC(): void {
    this.store.dispatch(dropWebRTC());
  }

  transcript(): void {
    this.store.dispatch(transcript());
  }

  openTranscriptionSheet() {
    this._bottomSheet.open(TranscriptionSheetComponent, {
      // data: {
      //   bcn: '92006'
      // }
    });
  }
}
