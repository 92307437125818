import { createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { get } from 'lodash';

import { Handset } from './handset.model';
import * as HandsetActions from './handset.actions';
import * as CommunityActions from './../community/community.actions';
import { Twosome } from '../twosome/twosome.model';
import { DynamicBridge } from '../community/community.model';
import { BroadcastGroup } from '../broadcast-group/broadcast-group.model';
import { Contact } from '../contact/contact.model';

export const handsetsFeatureKey = 'handsets';

export interface State extends EntityState<Handset> {
  // additional entities state properties
  loaded: boolean;
  loading: boolean;
}

export const adapter: EntityAdapter<Handset> = createEntityAdapter<Handset>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
  loaded: false,
  loading: false,
});

export const findConnectedDynamicBridge = (
  handset: Handset,
  dynamicBridges: DynamicBridge[]
): DynamicBridge | null => {
  const handsetConfNum = get(handset, 'bridge.bridge.confNum');
  const dynamicBridge = dynamicBridges.find((twosome) => twosome.destnum === handsetConfNum);
  return dynamicBridge;
};

export const findConnectedCall = (handset: any, calls: any[]): Contact | null => {
  const call = calls.find((call) => call.resource === handset.id);
  return call;
};

export const findConnectedTwosome = (
  dynamicBridge: DynamicBridge,
  twosomes: Twosome[]
): Twosome | null => {
  // console.log({ dynamicBridge, twosomes });
  const dynamicBridgeDestNum = get(dynamicBridge, 'called');
  // console.log({ dynamicBridgeDestNum });
  return twosomes.find(
    (line) => get(line, 'circuit.bridge.bridge.confNum') === dynamicBridgeDestNum
  );
};

export const findConnectedBroadcastGroup = (
  dynamicBridge: DynamicBridge,
  broadcastGroups: BroadcastGroup[]
): BroadcastGroup | null => {
  // console.log(dynamicBridge, broadcastGroups);
  if (!dynamicBridge) {
    return null;
  }
  const dynamicBridgeDestNum = get(dynamicBridge, 'participants[0].destNum');
  // console.log({ dynamicBridgeDestNum, broadcastGroups });
  return broadcastGroups.find(
    (bg) => get(bg, 'ownerBridge.bridge.confNum') === dynamicBridgeDestNum
  );
};

export const reducer = createReducer(
  initialState,
  on(CommunityActions.loadCommunity, (state) => ({
    ...state,
    loading: true,
  })),
  on(CommunityActions.loadCommunityError, (state) => ({
    ...state,
    loading: false,
    loaded: false,
  })),
  on(CommunityActions.loadCommunitySuccess, (state, action) => {
    const handsets = action.handsets
      .map((handset) => {
        const dynamicBridge = findConnectedDynamicBridge(handset, action.dynamicBridges);
        const call = findConnectedCall(handset, action.calls);
        const broadcastGroup = findConnectedBroadcastGroup(
          dynamicBridge,
          action.broadcastGroups || []
        );
        const twosome = findConnectedTwosome(dynamicBridge, action.community.lines);
        // console.log({ dynamicBridge, call, broadcastGroup, twosome });
        return {
          ...handset,
          connected: {
            call,
            dynamicBridge,
            broadcastGroup,
            twosome,
          },
        };
      })
      .sort((a, b) => (a.id >= b.id ? -1 : 1));
    return {
      ...adapter.setAll(handsets, state),
      loading: false,
      loaded: true,
    };
  }),
  on(HandsetActions.addHandset, (state, action) => adapter.addOne(action.handset, state)),
  on(HandsetActions.upsertHandset, (state, action) => adapter.upsertOne(action.handset, state)),
  on(HandsetActions.addHandsets, (state, action) => adapter.addMany(action.handsets, state)),
  on(HandsetActions.upsertHandsets, (state, action) => adapter.upsertMany(action.handsets, state)),
  on(HandsetActions.updateHandset, (state, action) => adapter.updateOne(action.handset, state)),
  on(HandsetActions.updateHandsets, (state, action) => adapter.updateMany(action.handsets, state)),
  on(HandsetActions.deleteHandset, (state, action) => adapter.removeOne(action.id, state)),
  on(HandsetActions.deleteHandsets, (state, action) => adapter.removeMany(action.ids, state)),
  on(HandsetActions.loadHandsets, (state, action) => adapter.setAll(action.handsets, state)),
  on(HandsetActions.clearHandsets, (state) => adapter.removeAll(state))
);

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
