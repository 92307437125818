import { ChangeDetectionStrategy, Component, ElementRef, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { State, authenticate, AuthStrategy } from '@ethervox/auth-store';

@Component({
  selector: 'login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginComponent {
  @ViewChild('container') container: ElementRef<HTMLElement>;

  constructor(private store: Store<State>) {}

  ngAfterViewInit(): void {
    this.store.dispatch(
      authenticate({
        strategy: AuthStrategy.FRAME,
        container: document.body,
        redirectUrl: '/mcs-home',
      })
    );
  }
}
