import { createReducer, on, Action } from '@ngrx/store';
import { AUTH_ENTITY_KEY, TOKEN_KEY } from '../auth-store.const';

import * as AuthActions from './auth.actions';
import { AuthEntity } from './auth.models';

export const AUTH_FEATURE_KEY = 'auth';

export interface State {
  authenticated: boolean;
  authenticating: boolean;
  token: string;
  entity: {
    loaded: boolean;
    loading: boolean;
    entity: AuthEntity;
  };
  error?: unknown;
}

export interface AuthPartialState {
  [AUTH_FEATURE_KEY]: State;
}

const storedEntity = localStorage.getItem(AUTH_ENTITY_KEY);

export const initialState: State = {
  authenticated: !!storedEntity,
  authenticating: false,
  token: localStorage.getItem(TOKEN_KEY),
  entity: {
    loaded: false,
    loading: false,
    entity: (storedEntity || {}) as AuthEntity,
  },
};

const authReducer = createReducer(
  initialState,
  on(AuthActions.authenticate, (state) => ({
    ...state,
    authenticating: true,
    error: null,
  })),
  on(AuthActions.authenticateSuccess, (state, { token }) => ({
    ...state,
    token,
    authenticating: false,
    authenticated: true,
  })),
  on(AuthActions.authenticateError, (state, { error }) => ({
    ...state,
    error,
    authenticating: false,
    authenticated: false,
  })),
  on(AuthActions.getAuthEntity, (state) => ({
    ...state,
    entity: {
      ...state.entity,
      loaded: false,
      loading: true,
    },
  })),
  on(AuthActions.getAuthEntitySuccess, (state, { authEntity: entity }) => ({
    ...state,
    entity: {
      ...state.entity,
      loaded: true,
      loading: false,
      entity,
    },
  })),
  on(AuthActions.getAuthEntityError, (state) => ({
    ...state,
    entity: {
      ...state.entity,
      loaded: false,
      loading: false,
    },
  }))
);

export function reducer(state: State | undefined, action: Action): State {
  return authReducer(state, action);
}
