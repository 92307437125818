<h1 mat-dialog-title>Bridge Settings</h1>
<div mat-dialog-content>
  <mat-checkbox color="primary">
    <span class="mat-body-1">Open Channel</span>
    <!-- <br> -->
    <!-- <span class="mat-caption">Lorem ipsum dolor sit amet</span> -->
  </mat-checkbox>
  <br>
  <br>
</div>
<div mat-dialog-actions>
  <button
    [mat-dialog-close]="true"
    mat-button
    cdkFocusInitial>
    Close
  </button>
</div>
