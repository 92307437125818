import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { ResourceAbstract } from '@resources/resources.abstract';
import { DynamicBridge } from '../../../home/bridges/store/community/community.model';

@Injectable({
  providedIn: 'root',
})
export class BridgeService extends ResourceAbstract {
  constructor(protected httpClient: HttpClient) {
    super(httpClient);
  }

  getDynamicBridge(): Observable<DynamicBridge[]> {
    return this.get<DynamicBridge[]>('audiobridge/dynamic/bridge', {}, true);
  }
}
