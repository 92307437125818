import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { Intercom } from './intercom.model';

// loadIntercoms
export const loadIntercoms = createAction('[Intercom/API] Load Intercoms');

export const loadIntercomsSuccess = createAction(
  '[Intercom/API] Load Intercoms Success',
  props<{ intercoms: Intercom[] }>()
);

export const loadIntercomsError = createAction(
  '[Intercom/API] Load Intercoms Error',
  props<{ error: unknown }>()
);

// openConnectionDialog
export const openConnectionDialog = createAction(
  '[Intercom/API] Open Connection Dialog',
  props<{ intercomId: string }>()
);

// addConnection
export const addConnection = createAction(
  '[Intercom/API] Add Connection',
  props<{ data: any; selectedContact: any }>()
);

export const addConnectionSuccess = createAction(
  '[Intercom/API] Add Connection Success',
  props<{ intercom: Intercom }>()
);

export const addConnectionError = createAction(
  '[Intercom/API] Add Connection Error',
  props<{ error: unknown }>()
);

// addConnectionBulk
export const addConnectionBulk = createAction(
  '[Intercom/API] Add Connection Bulk',
  props<{ intercomId: string; contactIds: string[] }>()
);

export const addConnectionBulkSuccess = createAction(
  '[Intercom/API] Add Connection Bulk Success',
  props<{ intercom: Intercom }>()
);

export const addConnectionBulkError = createAction(
  '[Intercom/API] Add Connection Bulk Error',
  props<{ error: unknown }>()
);

// removeConnection
export const removeConnection = createAction(
  '[Intercom/API] Remove Connection',
  props<{ intercomId: string; contactId: string }>()
);

export const removeConnectionSuccess = createAction(
  '[Intercom/API] Remove Connection Success',
  props<{ intercom: Intercom }>()
);

export const removeConnectionError = createAction(
  '[Intercom/API] Remove Connection Error',
  props<{ error: unknown }>()
);

export const addIntercom = createAction(
  '[Intercom/API] Add Intercom',
  props<{ intercom: Intercom }>()
);

export const upsertIntercom = createAction(
  '[Intercom/API] Upsert Intercom',
  props<{ intercom: Intercom }>()
);

export const addIntercoms = createAction(
  '[Intercom/API] Add Intercoms',
  props<{ intercoms: Intercom[] }>()
);

export const upsertIntercoms = createAction(
  '[Intercom/API] Upsert Intercoms',
  props<{ intercoms: Intercom[] }>()
);

export const updateIntercom = createAction(
  '[Intercom/API] Update Intercom',
  props<{ intercom: Update<Intercom> }>()
);

export const updateIntercoms = createAction(
  '[Intercom/API] Update Intercoms',
  props<{ intercoms: Update<Intercom>[] }>()
);

export const deleteIntercom = createAction(
  '[Intercom/API] Delete Intercom',
  props<{ id: string }>()
);

export const deleteIntercoms = createAction(
  '[Intercom/API] Delete Intercoms',
  props<{ ids: string[] }>()
);

export const clearIntercoms = createAction('[Intercom/API] Clear Intercoms');

// toggleIntercomDevice
export const toggleIntercomDevice = createAction(
  '[Intercom/API] Toggle Intercom Device',
  props<{ intercom: Intercom; line: any; device: 'mic' | 'speaker'; isMuted: boolean }>()
);

export const toggleIntercomDeviceSuccess = createAction(
  '[Intercom/API] Toggle Intercom Device Success',
  props<{ intercom: Intercom; line: any; device: 'mic' | 'speaker'; isMuted: boolean }>()
);

export const toggleIntercomDeviceError = createAction(
  '[Intercom/API] Toggle Intercom Device Error',
  props<{ error: unknown }>()
);
