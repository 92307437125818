/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterBy',
})
export class FilterByPipe implements PipeTransform {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  transform(collection: any, filterPhrase: string, ...filterBy): any {
    if (!filterPhrase || !filterBy || !filterBy.length) {
      return collection;
    }
    return collection.filter((item) => {
      let found = false;
      for (let i = 0; i < filterBy.length; i++) {
        if (
          (item[filterBy[i]] || '').toLocaleLowerCase().indexOf(filterPhrase.toLocaleLowerCase()) >
          -1
        ) {
          found = true;
        }
      }
      return found;
    });
  }
}
