import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../store';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { selectAppNodes } from '../store/admin.selectors';
import { loadAppNodes } from '../store/admin.actions';

@Component({
  selector: 'app-app-abs-page',
  templateUrl: './app-abs-page.component.html',
  styleUrls: ['./app-abs-page.component.scss']
})
export class AppAbsPageComponent implements OnInit {
  nodes$: Observable<any[]>;

  constructor(private store: Store<State>, private route: ActivatedRoute) { }

  ngOnInit() {
    this.store.dispatch(loadAppNodes({ id: this.route.snapshot.params.appId }));
    this.nodes$ = this.store.select(selectAppNodes);

  }

}
