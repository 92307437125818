import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { ResourceAbstract } from '@resources/resources.abstract';

@Injectable({
  providedIn: 'root',
})
export class SymphonyService extends ResourceAbstract {
  constructor(protected httpClient: HttpClient) {
    super(httpClient);
  }

  checkEtherVoxStatus(identifier: string | string[]): Observable<unknown> {
    return this.httpClient.get(`https://symphony.ethervox.io/auth/check`, {
      params: { identifier },
      ...this.getRequestOptions(),
    });
  }

  checkContactStatus(farparty: string | string[]): Observable<unknown> {
    return this.httpClient.get(`https://symphony.ethervox.io/contact/contact-status`, {
      params: { farparty, community: 'ethervox' },
      ...this.getRequestOptions(),
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getStreams(): Observable<any> {
    return this.httpClient.get(
      `https://symphony.ethervox.io/symphony/streams`,
      this.getRequestOptions()
    );
  }
}
