import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { UiTab, UiTabs } from './ui-tabs';


@Component({
  selector: 'ui-tabs',
  templateUrl: './ui-tabs.component.html',
  styleUrls: ['./ui-tabs.component.scss']
})
export class UiTabsComponent implements OnInit, OnDestroy {

  @Input() tabs: UiTabs;

  activeTab: UiTab;

  private componentDestroyed$ = new Subject();

  constructor(private router: Router) { }

  ngOnInit() {
    this.setActiveTab(this.router.routerState.snapshot.url);
    this.router.events
      .pipe(
        takeUntil(this.componentDestroyed$),
        filter(event => event instanceof NavigationEnd)
      )
      .subscribe(this.onNavigationEnd.bind(this));
  }

  ngOnDestroy() {
    this.componentDestroyed$.next();
    this.componentDestroyed$.complete();
  }

  onTabClick(tab: UiTab) {
    this.router.navigate([tab.routerLink]);
  }

  private setActiveTab(routerStateUrl: string) {
    this.activeTab = this.tabs.find(tab => routerStateUrl.indexOf(tab.routerLink) > -1);
  }

  private onNavigationEnd() {
    this.setActiveTab(this.router.routerState.snapshot.url);
  }

}
