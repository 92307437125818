import { Injectable, NgZone } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { fromEvent, Observable, of } from 'rxjs';
import { filter, mergeMap, map, catchError, take, tap } from 'rxjs/operators';

import * as AuthActions from './auth.actions';
import { AuthCredentialsStrategy, AuthFrameStrategy, AuthStrategy, AuthToken } from './auth.models';
import { AuthResource } from '../services/auth.resource';
import { AUTH_ENTITY_KEY, AUTH_HOST, TOKEN_KEY, REDIRECT_URL } from '../auth-store.const';
import { Router } from '@angular/router';

@Injectable()
export class AuthEffects {
  authenticateCredentials$ = createEffect(() =>
    this.actions$
      .pipe(ofType(AuthActions.authenticate))
      .pipe(filter(({ strategy }) => strategy === AuthStrategy.CREDENTIALS))
      .pipe(map((props) => props as AuthCredentialsStrategy))
      .pipe(
        mergeMap(({ credentials, redirectUrl }) =>
          this.authResource
            .authenticate(credentials)
            .pipe(map((token) => AuthActions.authenticateSuccess({ ...token, redirectUrl })))
            .pipe(catchError((error) => of(AuthActions.authenticateError({ error }))))
        )
      )
  );

  authenticateFrame$ = createEffect(() =>
    this.actions$
      .pipe(ofType(AuthActions.authenticate))
      .pipe(filter(({ strategy }) => strategy === AuthStrategy.FRAME))
      .pipe(map((props) => props as AuthFrameStrategy))
      .pipe(tap(({ container }) => this.authFrame(container)))
      .pipe(
        mergeMap(({ redirectUrl }) =>
          this.authEventHandler()
            .pipe(map((token) => AuthActions.authenticateSuccess({ ...token, redirectUrl })))
            .pipe(catchError((error) => of(AuthActions.authenticateError({ error }))))
        )
      )
  );

  authenticatePopup$ = createEffect(() =>
    this.actions$
      .pipe(ofType(AuthActions.authenticate))
      .pipe(filter(({ strategy }) => strategy === AuthStrategy.POPUP))
      .pipe(tap(() => this.authPopup()))
      .pipe(
        mergeMap(({ redirectUrl }) =>
          this.authEventHandler()
            .pipe(map((token) => AuthActions.authenticateSuccess({ ...token, redirectUrl })))
            .pipe(catchError((error) => of(AuthActions.authenticateError({ error }))))
        )
      )
  );

  authenticateSuccess$ = createEffect(
    () =>
      this.actions$
        .pipe(ofType(AuthActions.authenticateSuccess))
        .pipe(tap(({ token }) => localStorage.setItem(TOKEN_KEY, token)))
        .pipe(tap(() => document.querySelector('.auth-frame')?.remove()))
        .pipe(tap(({ redirectUrl }) => this.router.navigate([redirectUrl || REDIRECT_URL]))),
    { dispatch: false }
  );

  getAuthEntity$ = createEffect(() =>
    this.actions$
      .pipe(ofType(AuthActions.authenticateSuccess, AuthActions.getAuthEntity))
      .pipe(mergeMap(() => this.authResource.getAuthEntity()))
      .pipe(map((authEntity) => AuthActions.getAuthEntitySuccess({ authEntity })))
      .pipe(catchError((error) => of(AuthActions.getAuthEntityError({ error }))))
  );

  getAuthEntitySuccess$ = createEffect(
    () =>
      this.actions$
        .pipe(ofType(AuthActions.getAuthEntitySuccess))
        .pipe(map(({ authEntity }) => JSON.stringify(authEntity)))
        .pipe(tap((authEntity) => localStorage.setItem(AUTH_ENTITY_KEY, authEntity))),
    { dispatch: false }
  );

  disprove$ = createEffect(() =>
    this.actions$
      .pipe(ofType(AuthActions.disprove))
      .pipe(tap(() => localStorage.removeItem(TOKEN_KEY)))
      .pipe(tap(() => localStorage.removeItem(AUTH_ENTITY_KEY)))
      .pipe(map(() => AuthActions.disproveSuccess()))
      .pipe(catchError((error) => of(AuthActions.disproveError({ error }))))
  );

  disproveSuccess$ = createEffect(
    () =>
      this.actions$
        .pipe(ofType(AuthActions.disproveSuccess))
        .pipe(tap(() => this.router.navigate(['/hall']))),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private ngZone: NgZone,
    private router: Router,
    private authResource: AuthResource
  ) {}

  private authFrame(container: HTMLElement) {
    this.ngZone.runOutsideAngular(() => {
      const iframe = document.createElement('iframe');
      iframe.src = AUTH_HOST;
      iframe.classList.add('auth-frame');
      container.appendChild(iframe);
    });
  }

  private authPopup() {
    this.ngZone.runOutsideAngular(() => {
      window.open(AUTH_HOST);
    });
  }

  private authEventHandler(): Observable<AuthToken> {
    return fromEvent<MessageEvent>(window, 'message')
      .pipe(filter(({ origin }) => origin === AUTH_HOST))
      .pipe(take(1))
      .pipe(map(({ data }) => data));
  }
}
