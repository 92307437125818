/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'contact-invitation-dialog',
  templateUrl: './contact-invitation-dialog.component.html',
  styleUrls: ['./contact-invitation-dialog.component.scss'],
})
export class ContactInvitationDialogComponent {
  selectedUserEmail: string;

  constructor(
    public dialogRef: MatDialogRef<ContactInvitationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.selectedUserEmail = data.results && data.results[0] && data.results[0].emailAddress;
  }

  getUserAvatar(user): string {
    return user && user.avatars && user.avatars.find((avatar) => avatar.size === 'original');
  }

  invite(): void {
    this.dialogRef.close(this.selectedUserEmail || this.data.email);
  }
}
