<!-- <pre>{{ subtable$ | async | json }}</pre> -->
<ng-container *ngIf="subtable$ | async as subtable">
  <table *ngIf="subtable.sites" mat-table [dataSource]="subtable.sites" class="mat-elevation-z3">
    <ng-container matColumnDef="icon">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element">
        <mat-icon class="connected" *ngIf="element?.casType + '' === 'dlic'"> group_work </mat-icon>
        <mat-icon class="connected" *ngIf="element?.casType + '' === 'transfer'">
          account_circle
        </mat-icon>
      </td>
    </ng-container>

    <ng-container matColumnDef="label">
      <th mat-header-cell *matHeaderCellDef>Label</th>
      <td mat-cell *matCellDef="let element">{{ element.label }}</td>
    </ng-container>

    <ng-container matColumnDef="number">
      <th mat-header-cell *matHeaderCellDef>Number</th>
      <td mat-cell *matCellDef="let element">{{ element.ucn }}</td>
    </ng-container>

    <ng-container matColumnDef="lineId">
      <th mat-header-cell *matHeaderCellDef>Line ID</th>
      <td mat-cell *matCellDef="let element">{{ element.id }}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columns"></tr>
    <tr mat-row *matRowDef="let row; columns: columns"></tr>
  </table>
</ng-container>
