import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { pickBy, isNull } from 'lodash';
import { etherVoxApiContext, etherVoxApiUrl } from '../const/service.const';

/**
 * @todo
 * merge v2 and asFormData with options object
 */
interface ServiceHeaders {
  [header: string]: string | string[];
}

export interface ServiceRequestOptions {
  v2?: boolean;
  asFormData?: boolean;
  observe?: 'body';
  headers?: HttpHeaders | ServiceHeaders;
}

export abstract class ServiceAbstract {
  constructor(protected httpClient: HttpClient) {}

  protected get<T>(path: string | string[], options?: ServiceRequestOptions, v2 = false): Observable<T> {
    return this.httpClient
      .get<T>(this._getRequestPath(path, v2), this.getRequestOptions(options))
      .pipe(map(this._getResponseFromModel.bind(this)));
  }

  protected delete<T>(path: string | string[], options?: ServiceRequestOptions, v2 = false): Observable<T> {
    return this.httpClient
      .delete<T>(this._getRequestPath(path, v2), this.getRequestOptions(options))
      .pipe(map(this._getResponseFromModel.bind(this)));
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  protected post<T>(path: string, body?: Record<string, any>, asFormData = false, options = {}, v2 = false): Observable<T> {
    const normalizedBody = body && !Array.isArray(body) ? pickBy(body, (property) => !isNull(property)) : body;
    const requestBody = asFormData ? this.buildURLEncodedBody(normalizedBody).toString() : normalizedBody;
    return this.httpClient
      .post<T>(this._getRequestPath(path, v2), requestBody, this.getRequestOptions(options))
      .pipe(map(this._getResponseFromModel.bind(this)));
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  protected put<T>(path: string, body?: Record<string, any>, asFormData = false, options = {}, v2 = false): Observable<T> {
    const normalizedBody = body && !Array.isArray(body) ? pickBy(body, (property) => !isNull(property)) : body;
    const requestBody = asFormData ? this.buildURLEncodedBody(normalizedBody).toString() : normalizedBody;
    return this.httpClient
      .put<T>(this._getRequestPath(path, v2), requestBody, this.getRequestOptions(options))
      .pipe(map(this._getResponseFromModel.bind(this)));
  }

  getRequestOptions(options = {}): ServiceRequestOptions {
    return { ...this._requestOptions, ...options };
  }

  private _getRequestPath(fragments: string | string[], v2 = false): string {
    const fragmentsArray = Array.isArray(fragments) ? [...fragments] : [fragments];
    const pathArray = [etherVoxApiUrl, v2 ? etherVoxApiContext.replace('v3', 'v2') : etherVoxApiContext, ...fragmentsArray];
    return pathArray.join('/');
  }

  private get _requestOptions(): ServiceRequestOptions {
    const headers = {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    };
    return { headers, observe: 'body' as const };
  }

  private _getResponseFromModel<T>(responseModel: T): T {
    return responseModel;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private buildURLEncodedBody(rawBody: Record<string, any>): URLSearchParams {
    const URLEncodedBody = new URLSearchParams();
    Object.keys(rawBody).forEach((key) => {
      const value = rawBody[key];
      URLEncodedBody.set(key, <string>value);
    });
    return URLEncodedBody;
  }
}
