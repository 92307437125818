import * as contextActions from './context.actions';
import { Meeting } from '../../common/models/site.model';
import { createReducer, on } from '@ngrx/store';

export const contextKey = 'context';

export interface State {
  selectedSite: Meeting;
  loading: boolean;
  volumeToggle: any;
  activeChannelId: any;
  activeChannelStatus: any;
}

const initialState = {
  selectedSite: {},
  selectedChannel: null,
  loading: false,
  volumeToggle: null,
  activeChannelId: null,
  activeChannelStatus: null,
};

export const contextReducer = createReducer(
  initialState,
  on(contextActions.setSelectedSite, (state, action) => ({
    ...state,
    selectedSite: { ...action.payload },
  })),
  on(contextActions.unsetSelectedSite, (state) => ({
    ...state,
    selectedSite: null,
  })),

  on(contextActions.loadSuccess, (state, action) => ({
    ...state,
    loading: action.payload,
  }))
);
