import { Routes } from '@angular/router';

import { HootPageComponent } from './+hoot-page/hoot-page.component';

export const hootRoutes: Routes = [
  {
    path: '',
    component: HootPageComponent
  }
];
