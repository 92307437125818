import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { mergeMap, map, catchError, tap, repeat } from 'rxjs/operators';
import { of } from 'rxjs';

import * as fromHandset from './handset.actions';
import { CommunityLineService } from '@resources/community-line/community-line.service';
import { SnackbarService } from '@services/snackbar/snackbar.service';
import { loadCommunity } from '../community/community.actions';

@Injectable()
export class HandsetEffects {
  constructor(
    private actions$: Actions,
    private snackbar: SnackbarService,
    private communityLineService: CommunityLineService
  ) {}

  putOnHandset$ = createEffect(() =>
    this.actions$.pipe(ofType(fromHandset.putOnHandset)).pipe(
      mergeMap(
        ({ handsetId, bridgeId }) =>
          this.communityLineService
            .putOnHandset(handsetId, bridgeId)
            .pipe(map(() => fromHandset.putOnHandsetSuccess({ handsetId, bridgeId })))
            .pipe(tap(() => this.snackbar.openSimple('Handset has been connected')))
            .pipe(catchError((error) => of(fromHandset.putOnHandsetError({ error }))))
        // .pipe(repeat())
      )
    )
  );

  putMCOnHandset$ = createEffect(() =>
    this.actions$.pipe(ofType(fromHandset.putMCOnHandset)).pipe(
      mergeMap(({ callId, lineId }) =>
        this.communityLineService
          .putMCOnHandset(callId, lineId)
          .pipe(map((res) => fromHandset.putMCOnHandsetSuccess(res)))
          .pipe(tap(() => this.snackbar.openSimple('Handset has been connected')))
          .pipe(catchError((error) => of(fromHandset.putMCOnHandsetError({ error }))))
      )
    )
  );

  dropHandset$ = createEffect(() =>
    this.actions$.pipe(ofType(fromHandset.dropHandset)).pipe(
      mergeMap(
        ({ handsetId, bridgeId }) =>
          this.communityLineService
            .dropHandset(handsetId, bridgeId)
            .pipe(map(() => fromHandset.dropHandsetSuccess({ handsetId, bridgeId })))
            .pipe(tap(() => this.snackbar.openSimple('Handset has been disconnected')))
            .pipe(catchError((error) => of(fromHandset.dropHandsetError({ error }))))
        // .pipe(repeat())
      )
    )
  );

  // dropHandsetSuccess$ = createEffect(() =>
  //   this.actions$.pipe(ofType(fromHandset.dropHandsetSuccess)).pipe(map(() => loadCommunity()))
  // );
}
