<nav mat-tab-nav-bar>
  <a
    mat-tab-link
    *ngFor="let tab of tabs"
    (click)="onTabClick(tab)"
    [active]="activeTab == tab">
    <mat-icon *ngIf="tab.icon">{{tab.icon}}</mat-icon>
    <span [matBadge]="tab.badge" class="label">
      {{tab.label}}
    </span>
  </a>
</nav>

<button mat-icon-button [matMenuTriggerFor]="menu" class="burger-menu">
  <mat-icon>more_vert</mat-icon>
</button>
<mat-menu #menu="matMenu">
  <button mat-menu-item *ngFor="let tab of tabs" (click)="onTabClick(tab)">
    <span>{{tab.label}}</span>
  </button>
</mat-menu>
