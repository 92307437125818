<div class="container mat-elevation-z1">
  <div class="grid-container">
    <div *ngIf="this.selectedSite$ | async as site">
      <card-list>
        <ng-container *ngFor="let item of site.sites">
          <div
            [class.highlighted]="item?.id + '' === currentRow + ''"
            (click)="selectRow(item)"
            class="card-item"
          >
            <button mat-icon-button (click)="collapse(item)">
              <ng-container *ngIf="!this.isLocal">
                <mat-icon class="connected">groups</mat-icon>
              </ng-container>
              <ng-container *ngIf="this.isLocal">
                <mat-icon class="connected" *ngIf="item.connectionStatus || item.connected"
                  >person</mat-icon
                >
                <mat-icon *ngIf="!(item.connectionStatus || item.connected)" class="toggle-off"
                  >person</mat-icon
                >
              </ng-container>
            </button>
            <button
              mat-icon-button
              class="icon"
              color="default"
              tooltip="Ringdown"
              (click)="ringDown(item)"
            >
              <mat-icon class="talking" *ngIf="item.talkingStatus">phone_in_talk</mat-icon>
              <mat-icon *ngIf="!item.talkingStatus" class="toggle-off">phone_in_talk</mat-icon>
            </button>
            <div class="meta">
              <h3>
                <span> {{ item?.label }} / {{ item?.ucn }} </span>
              </h3>
              <span class="status"> {{ item?.id }} </span>
            </div>
            <div class="controls--right">
              <ui-button
                class="icon"
                color="default"
                tooltip="Toggle mic"
                (click)="toggle(item, 'mic')"
              >
                <mat-icon class="muted-button" *ngIf="item.mutedMic">mic_off</mat-icon>
                <mat-icon *ngIf="!item.mutedMic" class="unmuted-button">mic</mat-icon>
              </ui-button>
              <ui-button
                class="icon"
                color="default"
                tooltip="Toggle mic"
                (click)="toggle(item, 'speaker')"
              >
                <mat-icon class="muted-button" *ngIf="item.mutedSpeaker">volume_up</mat-icon>
                <mat-icon *ngIf="!item.mutedSpeaker" class="unmuted-button">volume_down</mat-icon>
              </ui-button>
            </div>
          </div>
          <div
            *ngIf="item?.id + '' === currentRow + '' && !isLocal && !collapsed"
            class="card-item"
          >
            <ethervox-multicircuit-expansion isLocal="true"> </ethervox-multicircuit-expansion>
          </div>
        </ng-container>
      </card-list>

      <!-- <table id="channels-table" class="fl-table" cellspacing="0" cellpadding="0">
        <thead>
          <th></th>
          <th></th>
          <th *ngIf="this.isLocal">Label</th>
          <th *ngIf="!this.isLocal">Location</th>
          <th>Number</th>
          <th *ngIf="this.isLocal">Line Id</th>
          <th *ngIf="!this.isLocal">Bridge</th>
          <th></th>
        </thead>
        <tbody *ngFor="let item of site.sites" (click)="selectRow(item)">
          <tr [class.highlighted]="item?.id + '' === currentRow + ''">
            <td width="5%">
              <button mat-icon-button color="secondary" (click)="collapse(item)">
                <ng-container *ngIf="!this.isLocal">
                  <mat-icon class="connected">groups</mat-icon>
                </ng-container>
                <ng-container *ngIf="this.isLocal">
                  <mat-icon class="connected" *ngIf="item.connectionStatus || item.connected"
                    >person</mat-icon
                  >
                  <mat-icon *ngIf="!(item.connectionStatus || item.connected)" class="toggle-off"
                    >person</mat-icon
                  >
                </ng-container>
              </button>
            </td>
            <td width="5%">
              <button mat-icon-button color="secondary" class="ringdown" (click)="ringDown(item)">
                <mat-icon class="talking" *ngIf="item.talkingStatus">phone_in_talk</mat-icon>
                <mat-icon *ngIf="!item.talkingStatus" class="toggle-off">phone_in_talk</mat-icon>
              </button>
            </td>
            <td width="20%">{{ item?.label }}</td>
            <td width="25%">{{ item?.ucn }}</td>
            <td width="25%">{{ item?.id }}</td>
            <td width="20%">
              <p data-placement="top" title="Delete">
                <button mat-icon-button color="secondary" (click)="toggle(item, 'mic')">
                  <mat-icon class="muted-button" *ngIf="item.mutedMic">mic_off</mat-icon>
                  <mat-icon *ngIf="!item.mutedMic" class="unmuted-button">mic</mat-icon>
                </button>
                <button
                  class="stop-button"
                  mat-icon-button
                  color="secondary"
                  (click)="toggle(item, 'speaker')"
                >
                  <mat-icon class="muted-button" *ngIf="item.mutedSpeaker">volume_up</mat-icon>
                  <mat-icon *ngIf="!item.mutedSpeaker" class="unmuted-button">volume_down</mat-icon>
                </button>
              </p>
            </td>
          </tr>

          <ng-container *ngIf="item?.id + '' === currentRow + '' && !isLocal && !collapsed">
            <tr>
              <td colspan="6">
                <ethervox-multicircuit-expansion isLocal="true"> </ethervox-multicircuit-expansion>
              </td>
            </tr>
          </ng-container>
        </tbody>
      </table> -->
    </div>
  </div>
</div>
