import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  ViewChild,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { State, authenticate, AuthStrategy } from '@ethervox/auth-store';

@Component({
  selector: 'login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginPageComponent implements AfterViewInit {
  @ViewChild('container') container: ElementRef<HTMLElement>;

  constructor(private store: Store<State>) {}

  ngAfterViewInit(): void {
    this.store.dispatch(
      authenticate({
        strategy: AuthStrategy.FRAME,
        container: document.body,
        redirectUrl: '/dashboard-home',
      })
    );
  }
}
