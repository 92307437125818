import { createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { get } from 'lodash';

import * as CommunityActions from './../community/community.actions';
import { Twosome } from './twosome.model';
import { Handset } from '../handset/handset.model';
import { DynamicBridge } from '../community/community.model';

export const twosomesFeatureKey = 'twosomes';

export interface State extends EntityState<Twosome> {
  // additional entities state properties
  loaded: boolean;
  loading: boolean;
}

export const adapter: EntityAdapter<Twosome> = createEntityAdapter<Twosome>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
  loaded: false,
  loading: false,
});

export const findConnectedHandset = (
  twosome: Twosome,
  dynamicBridges: DynamicBridge[],
  handsets: Handset[]
): Handset | null => {
  // console.log({ twosome, dynamicBridges, handsets });
  // find dynamicbridges connected to handset
  const twosomeConfNum = get(twosome, 'circuit.bridge.bridge.confNum');
  // console.log({ twosomeConfNum });
  const connectedDynamicBridges = dynamicBridges
    .map((dynamicBridge) => {
      const dynamicBridgeDestNum = get(dynamicBridge, 'destnum');
      const connectedHandset = handsets.find(
        (handset) => get(handset, 'bridge.bridge.confNum') === dynamicBridgeDestNum
      );
      // console.log({ dynamicBridge, dynamicBridgeDestNum, connectedHandset });
      return {
        dynamicBridge,
        connectedHandset,
      };
    })
    .filter((connectedDynamicBridge) => !!connectedDynamicBridge.connectedHandset);
  // check whether twosome is connected to dynamicbridge
  // console.log({ connectedDynamicBridges });
  const twosomeHandset = connectedDynamicBridges.find((connectedDynamicBridge) => {
    const dynamicBridgeCalled = get(connectedDynamicBridge, 'dynamicBridge.called');
    // console.log({ dynamicBridgeCalled});
    return dynamicBridgeCalled === twosomeConfNum;
  });
  // console.log({ twosomeHandset });
  return twosomeHandset && twosomeHandset.connectedHandset;
};

export const reducer = createReducer(
  initialState,
  on(CommunityActions.loadCommunity, (state) => ({
    ...state,
    loading: true,
  })),
  on(CommunityActions.loadCommunityError, (state) => ({
    ...state,
    loading: false,
    loaded: false,
  })),
  on(CommunityActions.loadCommunitySuccess, (state, action) => {
    const { handsets, dynamicBridges, community } = action;
    const twosomes = community.lines
      .filter((l) => !l.isIntercom)
      .map((twosome) => ({
        ...twosome,
        onHandset: findConnectedHandset(twosome, dynamicBridges, handsets),
      }));
    return {
      ...adapter.setAll(twosomes, state),
      loading: false,
      loaded: true,
    };
  })
);

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
