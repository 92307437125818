import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Handset } from 'src/app/home/bridges/store/handset/handset.model';

@Component({
  selector: 'handset-selector',
  templateUrl: './handset-selector.component.html',
  styleUrls: ['./handset-selector.component.scss'],
})
export class HandsetSelectorComponent {
  @Input() handsets: Handset[];
  @Input() connected: Handset;

  @Output() selected = new EventEmitter<Handset>();
  @Output() unselected = new EventEmitter<Handset>();

  getConnectedIndex(connected: Handset, handsets: Handset[]): number {
    return handsets.findIndex((h) => connected && h.id === connected.id) + 1;
  }
}
