import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable, forkJoin } from 'rxjs';
import { filter, switchMap, take, map, tap, withLatestFrom } from 'rxjs/operators';

import { ContactsService } from '@resources/contacts/contacts.service';
import { Contacts } from '@resources/contacts/contacts';
import { ConfirmDialogComponent } from '@shared/dialogs/confirm-dialog/confirm-dialog.component';
import { ConfirmDialogData } from '@shared/dialogs/confirm-dialog/confirm-dialog';
import { CommunityLineService } from '@resources/community-line/community-line.service';
import { SocketCarrierService } from '@services/socket-carrier/socket-carrier.service';
import { SnackbarService } from '@services/snackbar/snackbar.service';
import { Member } from '@resources/member/member';
import { MemberService } from '@resources/member/member.service';
import { DashboardApiService } from '../../../common/services/dashboard-api/dashboard-api.service';
import { SymphonyService } from '../../../common/resources/symphony/symphony.service';
import { HootRequestDialogComponent } from '../../../shared/dialogs/hoot-request-dialog/hoot-request-dialog.component';
import { PhoneHelpers } from '../../../common/helpers/phone.helpers';
import { Router } from '@angular/router';
import { Handset } from '../../bridges/store/handset/handset.model';
import { Store } from '@ngrx/store';
import { State, selectAllHandsets } from '../../bridges/store';
import { Contact } from '../../bridges/store/contact/contact.model';
import { requestMemberBridge, dropMemberBridge } from '../../bridges/store/contact/contact.actions';
import { loadCommunity } from '../../bridges/store/community/community.actions';
// import { }, dropWebRTC } from '../../../../../../mcs-ui/src/app/store/multi-circuit/multicircuit-actions';
import { callWebRTC, dropWebRTC } from '@ethervox/mcs';

@Component({
  selector: 'contacts-page',
  templateUrl: './contacts-page.component.html',
  styleUrls: ['./contacts-page.component.scss'],
})
export class ContactsPageComponent implements OnInit {
  contacts$: Observable<unknown>;
  handsets$: Observable<Handset[]>;

  // eslint-disable-next-line
  streams$: Observable<any>;
  searchPhrase = '';

  constructor(
    private contactsService: ContactsService,
    private memberService: MemberService,
    private communityLineService: CommunityLineService,
    private socketCarrierService: SocketCarrierService,
    private snackbarService: SnackbarService,
    private dashboardApiService: DashboardApiService,
    private symphonyService: SymphonyService,
    private store: Store<State>,
    private router: Router,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    if (this.router.routerState.snapshot.url === '/') {
      this.router.navigate(['dashboard-home/contacts']);
    }
    this.handsets$ = this.store.select(selectAllHandsets);

    this.store
      .select(selectAllHandsets)
      .pipe(filter((handsets) => !!handsets.length))
      .pipe(map((handsets) => handsets.find((h) => h.webRTC)))
      .pipe(filter((handset) => !!handset.connected?.call))
      .subscribe((handset) => {
        setTimeout(() => {
          this.store.dispatch(callWebRTC({ confNum: handset.bridge.bridge.confNum }));
        }, 2000);
      });

    // this.store.dispatch(loadCommunity());
    this.contacts$ = this.contactsService.getContacts().pipe(
      map((contacts) => {
        const byCommunityMap = {};
        contacts.forEach((contact) => {
          contact['community'].forEach((community) => {
            byCommunityMap[community.name] = byCommunityMap[community.name] || [];
            byCommunityMap[community.name].push(contact);
          });
        });
        // const byCommunity = [];
        return byCommunityMap;
      })
    );
  }

  onCtiCall(phoneNumber: string): void {
    this.contactsService.ctiCall(phoneNumber).subscribe(() => {
      this.snackbarService.openSimple('Call requested');
    });
  }

  acceptContact(contact: Contacts.Entity): void {
    this.contactsService
      .acceptContact(contact.requestid)
      .pipe(take(1))
      .subscribe(() => {
        this.snackbarService.openSimple('Contact accepted');
      });
  }

  rejectContact(contact: Contacts.Entity): void {
    this.showConfirmationDialog('Are you sure you want to reject this contact?')
      .pipe(take(1))
      .pipe(switchMap(() => this.contactsService.rejectContact(contact.requestid)))
      .subscribe(() => {
        this.snackbarService.openSimple('Contact rejected');
      });
  }

  requestBridge(contact: Contacts.Entity): void {
    this.showConfirmationDialog(
      'Are you sure you want to request the bridge for that contact?'
    ).subscribe(() => {
      this.communityLineService.getCommunityLines().subscribe((communityLines) => {
        const idleLine = PhoneHelpers.getIdleLine(communityLines.lines);
        if (!idleLine) {
          this.snackbarService.openSimple('Not enough Idle Lines');
          return;
        }
        this.socketCarrierService
          .requestBridge(communityLines.community.id, idleLine.id, contact.member.id)
          .then(() => {
            this.snackbarService.openSimple('Bridge requested');
          });
      });
    });
  }

  requestMemberBridge(handset: Handset, contact: Contact): void {
    this.store.dispatch(
      requestMemberBridge({ memberId: contact.id, handsetId: handset.id, webrtc: handset.webRTC })
    );
    if (handset.webRTC) {
      setTimeout(() => {
        this.store.dispatch(callWebRTC({ confNum: handset.bridge.bridge.confNum }));
      }, 2000);
    }
  }

  // eslint-disable-next-line
  requestHoot(stream: any) {
    const userIds = (stream.users || []).map((u) => u.id);
    forkJoin(
      this.symphonyService.checkEtherVoxStatus(userIds),
      this.symphonyService.checkContactStatus(userIds),
      this.memberService.whoami()
    )
      .pipe(
        map(([evStatuses, contactStatuses, whoami]) =>
          stream.users.map((user) => ({
            ...user,
            myself: user.id + '' === whoami.externalMember.identifier,
            etherVoxAuthStatus: evStatuses[user.id],
            etherVoxContactStatus: contactStatuses[user.id],
          }))
        )
      )
      .pipe(take(1))
      .subscribe((hootUsers) => {
        this.dialog.open(HootRequestDialogComponent, {
          data: hootUsers,
          width: '80vw',
        });
      });
  }

  onInviteEmail(email: string): void {
    this.memberService.invite(email).subscribe(() => {
      this.snackbarService.openSimple('Member invited');
      this.dashboardApiService.createRoom(email).subscribe((result) => console.log(result));
    });
  }

  onRequestContact(member: Member.Entity): void {
    this.socketCarrierService
      .requestContact(member.address)
      .then(() => this.snackbarService.openSimple('Contact requested'));
  }

  disconnectHandset(contact: Contact, handset: Handset): void {
    this.store.dispatch(dropMemberBridge({ callId: handset.connected.call.id }));
    if (handset.webRTC) {
      setTimeout(() => {
        this.store.dispatch(dropWebRTC());
      }, 2000);
    }
  }

  getContactHandset(contact: Contact, handsets: Handset[]): Handset {
    return handsets.find(
      (h) =>
        h.connected &&
        h.connected.call &&
        h.connected.call.communityMember &&
        h.connected.call.communityMember.id === contact.id &&
        h.connected.call.dynamicBridge
    );
  }

  private showConfirmationDialog(caption): Observable<boolean> {
    return this.dialog
      .open(ConfirmDialogComponent, {
        width: '480px',
        data: <ConfirmDialogData>{
          title: 'Confirm action',
          caption,
        },
      })
      .afterClosed()
      .pipe(filter((result) => !!result));
  }
}
