import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromContext from './context.reducers';

const stateKey = 'stateKey';
const selectState = createFeatureSelector<fromContext.State>(stateKey);

export const getContextState = createSelector(
  selectState,
  (state) => state[fromContext.contextKey]
);

export const selectSelectedSite = createSelector(getContextState, (state) => {
  return state.selectedSite;
});

export const selectSelectedChannel = createSelector(getContextState, (state) => {
  return state.selectedSite?.sites?.find((p1) => p1.selectedChannel == true);
});

export const selectLoadingStatus = createSelector(getContextState, (state) => {
  return state.loading;
});
