import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { CanActivateHall } from './guards/can-activate-hall.guard';
import { CanActivateHome } from './guards/can-activate-home.guard';
import { ResponseErrorInterceptor } from './interceptors/response-error.interceptor';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromAuth from './+state/auth.reducer';
import { AuthEffects } from './+state/auth.effects';

const providers = [CanActivateHall, CanActivateHome];

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(fromAuth.AUTH_FEATURE_KEY, fromAuth.reducer),
    EffectsModule.forFeature([AuthEffects]),
  ],
  providers: [
    ...providers,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ResponseErrorInterceptor,
      multi: true,
    },
  ],
  exports: [],
})
export class AuthStoreModule {}
