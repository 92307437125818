<mat-sidenav-container class="example-container">
  <mat-sidenav #sidenav mode="side" [(opened)]="opened">
    <!-- {{ transcriptionsConfNum }} -->
    <transcription-sheet [confNum]="transcriptionsConfNum"></transcription-sheet>
  </mat-sidenav>
  <mat-sidenav-content>
    <div class="top-bar">
      <div class="logo-container">
        <img *ngIf="logoUrl" [src]="logoUrl" class="lse-logo" />
        <p>Secure Dialer</p>
      </div>
      <ui-tabs [tabs]="tabs"></ui-tabs>
    </div>

    <div class="tab-content animated fadeIn">
      <router-outlet></router-outlet>
    </div>

    <mat-toolbar class="webrtc-status mat-elevation-z4" *ngIf="onCall$ | async">
      On Call {{ onCallWith$ | async }}
      <button mat-raised-button color="primary" (click)="dropWebRTC()">Drop</button>
      <button mat-raised-button color="primary" (click)="transcript()">Transcript</button>
    </mat-toolbar>

    <button
      mat-button
      color="primary"
      class="transcriptions-btn"
      (click)="transcriptionsConfNum = ''; sidenav.toggle()"
    >
      All transcriptions
    </button>
  </mat-sidenav-content>
</mat-sidenav-container>
<ng-container *ngIf="handsets$ | async as handsets">
  <handsets-bar [handsets]="handsets"> </handsets-bar>
</ng-container>

<!-- <div class="transcriptions" *ngIf="transcriptions$ | async as transcriptions">
    <ul>
      <li *ngFor="let transcription of transcriptions">
        [{{transcription.timestamp}}] {{transcription.bridgechannelnum}}: {{transcription.text}}
      </li>
    </ul>
  </div> -->
