import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

import { MatDialog } from '@angular/material/dialog';
import { HandsetDetailsDialogComponent } from '../../../shared/dialogs/handset-details-dialog/handset-details-dialog.component';
import { dialogSize } from '@const/ui.const';
import { CommunityLineService } from '@resources/community-line/community-line.service';

@Component({
  selector: 'handsets-page',
  templateUrl: './handsets-page.component.html',
  styleUrls: ['./handsets-page.component.scss'],
})
export class HandsetsPageComponent implements OnInit {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handsets$: Observable<any>;

  constructor(private communityLineService: CommunityLineService, public dialog: MatDialog) {}

  ngOnInit(): void {
    this.handsets$ = this.communityLineService.getHandsets();
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  showHandsetDetails(handset): void {
    this.communityLineService.getHansetInfo(handset.id).subscribe((handsetDetails) => {
      this.dialog.open(HandsetDetailsDialogComponent, {
        width: dialogSize.MEDIUM,
        data: handsetDetails,
      });
    });
  }
}
