<ng-container *ngIf="localSitesGrouped$ | async as localSites">
  <br />
  <ethervox-expandable-tree
    (webRTCcall)="callWebRTC($event)"
    (expandNode)="onNodeExpanded($event)"
    [onCall]="onCall$ | async"
    [sites]="localSites"
  >
  </ethervox-expandable-tree>
</ng-container>
<ng-container *ngIf="remoteSitesGrouped$ | async as remoteSites">
  <ethervox-expandable-tree
    chipLabel="Cross-Bridge"
    (webRTCcall)="callWebRTC($event)"
    (expandNode)="onNodeExpanded($event)"
    [onCall]="onCall$ | async"
    [subtable]="true"
    [sites]="remoteSites"
  ></ethervox-expandable-tree>
</ng-container>
