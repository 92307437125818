import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from '@shared/shared.module';
import { McsAppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { McsService } from './common/services/mcs.service';
import { HomeModule } from './home/home.module';
import { HallModule } from './hall/hall.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { environment } from '../environments/environment';
import { EffectsModule } from '@ngrx/effects';
import { ActionReducer, StoreModule } from '@ngrx/store';
import { reducers, metaReducers } from './store/app.reducer';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import { MulticircuitEffects } from './store/multi-circuit/multicircuit.effects';

@NgModule({
  declarations: [McsAppComponent],
  imports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    SharedModule,
    HomeModule,
    HallModule,
    HttpClientModule,
    FlexLayoutModule,
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
      },
    }),
    EffectsModule.forRoot([MulticircuitEffects]),
    StoreModule.forFeature('stateKey', reducers, { metaReducers: metaReducers }),

    StoreDevtoolsModule.instrument(),
  ],
  providers: [McsService],
  bootstrap: [McsAppComponent],
})
export class McsAppModule {}
