import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatRadioModule } from '@angular/material/radio';
import { MatCardModule } from '@angular/material/card';
import { MatSliderModule } from '@angular/material/slider';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NgxPaginationModule } from 'ngx-pagination';
import { MatSelectModule } from '@angular/material/select';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatOptionModule } from '@angular/material/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatSidenavModule } from '@angular/material/sidenav';
const modules = [
  CommonModule,
  MatBadgeModule,
  MatButtonModule,
  MatDialogModule,
  MatCheckboxModule,
  MatSidenavModule,
  MatChipsModule,
  MatFormFieldModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatCardModule,
  MatMenuModule,
  MatSnackBarModule,
  MatTabsModule,
  MatToolbarModule,
  MatTooltipModule,
  MatRadioModule,
  MatAutocompleteModule,
  FlexLayoutModule,
  NgxPaginationModule,
  MatSliderModule,
  MatSelectModule,
  MatProgressSpinnerModule,
  MatOptionModule,
  MatExpansionModule,
  MatBottomSheetModule,
];

import { UiButtonComponent } from './components/ui-button/ui-button.component';
import { UiTabsComponent } from './components/ui-tabs/ui-tabs.component';
import { CardListComponent } from './components/card-list/card-list.component';
import { UserAvatarComponent } from './components/user-avatar/user-avatar.component';

const components = [UiButtonComponent, UiTabsComponent, CardListComponent, UserAvatarComponent];

@NgModule({
  providers: [],
  imports: [...modules],
  exports: [...components, ...modules],
  declarations: [...components],
})
export class UiModule {}
