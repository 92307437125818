/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAction, props } from '@ngrx/store';

export const confJoin = createAction('[Socket] Conf Join', props<{ payload: any }>());

export const connected = createAction('[Socket] Connected', props<{ payload: any }>());

export const confEnd = createAction('[Socket] Conf End', props<{ payload: any }>());

export const confLeave = createAction('[Socket] Conf Leave', props<{ payload: any }>());

export const hangup = createAction('[Socket] Hangup', props<{ payload: any }>());

export const confTalk = createAction('[Socket] Conf Talk', props<{ payload: any }>());

export const confTranscript = createAction('[Socket] Conf Transcript', props<{ payload: any }>());

export const connectionState = createAction('[Socket] Connection State', props<{ payload: any }>());

export const handsetStatus = createAction('[Socket] Handset Status', props<{ payload: any }>());
