import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Contact } from 'src/app/home/bridges/store/contact/contact.model';

@Component({
  selector: 'intercom-request-dialog',
  templateUrl: './intercom-request-dialog.component.html',
  styleUrls: ['./intercom-request-dialog.component.scss'],
})
export class IntercomRequestDialogComponent {
  selectedContact: Contact;

  constructor(
    public dialogRef: MatDialogRef<IntercomRequestDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { intercomId: string; contacts: Contact[] }
  ) {}

  cancel(): void {
    this.dialogRef.close(false);
  }

  confirm(data, selectedContact): void {
    this.dialogRef.close({ data, selectedContact });
  }
}
