import { Component, OnInit } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import * as multicircuitActions from '../../../store/multi-circuit/multicircuit-actions';
import { selectIsLocal, State } from '../../../store/app.reducer';
import { Meeting } from './../../../common/models/site.model';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'ethervox-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss'],
})
export class IndexComponent implements OnInit {
  site$: Observable<Meeting[]> = of(null);
  selectedSite$: Observable<Meeting> = of(null);
  isLocal: boolean;
  componentDestroyed$: Subject<boolean> = new Subject();
  constructor(private store: Store<State>) {}

  ngOnInit(): void {
    this.store.dispatch(multicircuitActions.loadlocalSites());
    this.store
      .select(selectIsLocal)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((p) => {
        this.isLocal = p;
      });
  }
  ngOnDestroy() {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }
}
