import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { tap, map } from 'rxjs/operators';

import { AuthPartialState } from '../+state/auth.reducer';
import { getAuthenticated } from '../+state/auth.selectors';

@Injectable()
export class CanActivateHome implements CanActivate, CanActivateChild {
  constructor(private router: Router, private store: Store<AuthPartialState>) {}

  canActivate(): Observable<boolean> {
    return this.permitOrRedirect();
  }

  canActivateChild(): Observable<boolean> {
    return this.permitOrRedirect();
  }

  permitOrRedirect(): Observable<boolean> {
    return this.store
      .select<boolean>(getAuthenticated)
      .pipe(tap((authenticated) => !authenticated && this.router.navigate(['/hall'])))
      .pipe(map((authenticated) => !!authenticated));
  }
}
