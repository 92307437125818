import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse, HttpResponse } from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { catchError, filter } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AuthPartialState } from '../+state/auth.reducer';
import { disprove } from '../+state/auth.actions';

export enum HttpStatusCodes {
  Forbidden = 403,
  Unauthorized = 403,
}

@Injectable()
export class ResponseErrorInterceptor implements HttpInterceptor {
  constructor(private router: Router, private store: Store<AuthPartialState>) {}

  intercept<T>(request: HttpRequest<T>, next: HttpHandler): Observable<HttpEvent<T>> {
    return next.handle(request).pipe(
      filter((event: HttpEvent<T>) => event instanceof HttpResponse),
      catchError(this.onCaughtError.bind(this))
    );
  }

  private onCaughtError(error: HttpErrorResponse): Observable<HttpErrorResponse> {
    if ([HttpStatusCodes.Forbidden, HttpStatusCodes.Unauthorized].indexOf(error.status) > -1) {
      this.store.dispatch(disprove());
    }
    return throwError(error);
  }
}
