import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
// import { NgProgressModule } from 'ngx-progressbar';
import { UiModule } from '@ethervox/ui';
import { AuthStoreModule } from '@ethervox/auth-store';
import { CdkTreeModule } from '@angular/cdk/tree';
import { MatTableModule } from '@angular/material/table';
import { MatSidenavModule } from '@angular/material/sidenav';

const modules = [
  UiModule,
  RouterModule,
  CommonModule,
  FormsModule,
  ReactiveFormsModule,
  AuthStoreModule,
  // NgProgressModule,
  CdkTreeModule,
  MatTableModule,
  MatSidenavModule,
];

// COMPONENTS
// import { CardListComponent } from './card-list/card-list.component';
import { VersionBarComponent } from './version-bar/version-bar.component';
import { HeaderBarComponent } from './header-bar/header-bar.component';

const components = [HeaderBarComponent, VersionBarComponent];

@NgModule({
  imports: [...modules],
  exports: [...modules, components],
  declarations: [...components],
  entryComponents: [],
})
export class SharedModule {}
