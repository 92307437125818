<div class="container">
  <div *ngIf="site$ | async as sites" class="table-wrapper">
    <mat-form-field appearance="fill">
      <mat-label>Choose an option</mat-label>
      <mat-select value="Local">
        <mat-option value="Local" (click)="loadLocalSites()">Multi Circuits</mat-option>
        <mat-option value="Remote" (click)="loadRemoteSites()">Cross Bridge</mat-option>
      </mat-select>
    </mat-form-field>

    <card-list>
      <div
        *ngFor="let item of sites | paginate: config"
        (click)="selectRow(item)"
        [class.highlighted]="item?.label == currentRow"
        class="card-item"
      >
        <user-avatar> {{ item?.label?.charAt(0) }} </user-avatar>
        <div class="meta">
          <h3>
            <span> {{ item?.label }} </span>
          </h3>
          <span class="status"> {{ item?.signalling }} </span>
        </div>
        <div class="controls--right">
          <ui-button class="icon" color="default" tooltip="Settings" (click)="togglePlay(item)">
            <mat-icon class="active-button" *ngIf="getPlayStatus(item.id)"
              >play_circle_outline</mat-icon
            >
            <mat-icon *ngIf="!getPlayStatus(item.id)" class="passive-button"
              >play_circle_outline</mat-icon
            >
          </ui-button>
          <ui-button class="icon" color="default" tooltip="Settings" (click)="toggleStop(item)">
            <mat-icon class="active-button" *ngIf="getStopStatus(item.id)"
              >pause_circle_outline</mat-icon
            >
            <mat-icon *ngIf="!getStopStatus(item.id)" class="passive-button"
              >pause_circle_outline</mat-icon
            >
          </ui-button>

          <!-- <button mat-icon-button color="secondary"></button> -->
        </div>
      </div>
    </card-list>

    <!-- <table
      id="multicircuit-table"
      class="fl-table table mat-elevation-z2"
      cellspacing="0"
      cellpadding="0"
    >
      <thead>
        <th scope="col">Name</th>
        <th scope="col">Type</th>
        <th></th>
      </thead>
      <tbody>
        <tr
          *ngFor="let item of sites | paginate: config"
          (click)="selectRow(item)"
          [class.highlighted]="item?.label == currentRow"
        >
          <td width="20%">{{ item?.label }}</td>
          <td width="60%">{{ item?.signalling }}</td>
          <td width="20%">
            <p data-placement="top" title="Delete"></p>
          </td>
        </tr>
      </tbody>
    </table> -->
    <div class="pagination">
      <pagination-controls
        class="custom-pagination"
        (pageChange)="pageChanged($event)"
        responsive="false"
        previousLabel="Previous"
        nextLabel="Next"
        screenReaderPaginationLabel="Pagination"
        screenReaderPageLabel="page"
        maxSize="5"
      ></pagination-controls>
    </div>
  </div>

  <div *ngIf="this.site$ === null">
    <div class="alert alert-warning alert-dismissible fade show">
      List is empty.
      <button type="button" class="close" data-dismiss="alert">&times;</button>
    </div>
  </div>
</div>
