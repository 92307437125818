<div class="container">
  <div class="grid-container">
    <div>
      <div *ngIf="this.selectedChannel$ | async as site" class="table-wrapper">
        <table id="channels-table" class="fl-table" cellspacing="0" cellpadding="0">
          <thead>
            <th></th>
            <th>Label</th>
            <th>Number</th>
            <th>Line Id</th>
          </thead>
          <tbody>
            <tr *ngFor="let item of site.sites">
              <td width="5%">
                <button mat-icon-button color="secondary">
                  <mat-icon class="connected" *ngIf="item?.casType + '' === casType.dlic + ''"
                    >group_work
                  </mat-icon>
                  <mat-icon class="connected" *ngIf="item?.casType + '' === casType.transfer + ''">
                    account_circle
                  </mat-icon>
                </button>
              </td>
              <td width="20%">{{ item?.label }}</td>
              <td width="25%">{{ item?.ucn }}</td>
              <td width="25%">{{ item?.id }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
