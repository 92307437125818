import { ActionReducerMap, createFeatureSelector, createSelector, MetaReducer } from '@ngrx/store';
import * as fromAdmin from './admin.reducer'

export interface State {
  [fromAdmin.adminFeatureKey]: fromAdmin.State
}

export const reducers: ActionReducerMap<State> = {
  [fromAdmin.adminFeatureKey]: fromAdmin.reducer
}

export * from './admin.reducer';
