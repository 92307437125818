<ng-container *ngIf="handsets$ | async as handsets">
  <h4 class="mat-title">
    <mat-icon>settings_ethernet</mat-icon>
    Dynamic Lines
  </h4>

  <twosome-bridges
    [twosomes]="twosomes$ | async"
    [handsets]="handsets"
    (ringdown)="onRingdownTwosome($event.circuit.id)"
    (reject)="onRejectTwosome($event)"
    (accept)="onAcceptTwosome($event)"
    (resend)="onResendTwosome($event)"
    (requestContact)="onRequestContactTwosome($event)"
    (updateSettings)="onUpdateTwosomeSettings($event)"
    (putOnHandset)="putTwosomeOnHandset($event.twosome, $event.handset)"
    (dropHandset)="dropTwosomeFromHandset($event.twosome, $event.handset)"
  >
  </twosome-bridges>

  <ng-container *ngIf="sites$ | async as sites">
    <multicircuit-bridges
      [sites]="sites"
      [handsets]="handsets"
      (putOnHandset)="putMCOnHandset($event.callId, $event.lineId)"
    >
    </multicircuit-bridges>
  </ng-container>

  <ng-container *ngIf="intercoms$ | async as intercoms">
    <intercom-bridge [handsets]="handsets" [intercom]="intercoms[0]"> </intercom-bridge>
  </ng-container>

  <broadcast-groups
    [twosomes]="twosomes$ | async"
    [handsets]="handsets"
    [broadcastGroups]="broadcastGroup$ | async"
  >
  </broadcast-groups>
</ng-container>
