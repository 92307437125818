<h1 mat-dialog-title>Member lookup result</h1>
<div mat-dialog-content *ngIf="!data.results; else results">
  <p>There is no result for that search.</p>
  <p>You can invite the party to register via e-mail address</p>
  <mat-form-field>
    <input matInput [(ngModel)]="data.email">
  </mat-form-field>
</div>
<ng-template #results>
  <div mat-dialog-content>
      <mat-radio-group [(ngModel)]="selectedUserEmail" color="primary">
        <mat-list>
          <mat-list-item *ngFor="let user of data.results" (click)="selectedUserEmail = user.emailAddress">
            <mat-icon mat-list-icon>person</mat-icon>
            <h4 mat-line>{{user.firstName}} {{user.lastName}}</h4>
            <p mat-line>{{user.emailAddress}}</p>
            <mat-radio-button color="primary" [value]="user.emailAddress"></mat-radio-button>
          </mat-list-item>
        </mat-list>
      </mat-radio-group>
  </div>
</ng-template>
<div mat-dialog-actions>
  <button mat-flat-button [mat-dialog-close]="false">
    Cancel
  </button>
  <button mat-flat-button (click)="invite()" cdkFocusInitial color="primary" class="btn-right">
    Send Invitation
  </button>
</div>

