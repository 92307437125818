import { Component, ChangeDetectionStrategy } from '@angular/core';
import { State } from 'src/app/store/reducers';
import { Store } from '@ngrx/store';
import { disprove } from '@ethervox/auth-store';

@Component({
  selector: 'version-bar',
  templateUrl: './version-bar.component.html',
  styleUrls: ['./version-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VersionBarComponent {
  constructor(private store: Store<State>) {}

  logout(): void {
    this.store.dispatch(disprove());
  }
}
