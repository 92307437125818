import { Component, OnInit, Inject, ViewChild, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelectionList, MatSelectionListChange } from '@angular/material/list';
import { FormBuilder, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ContactRequestDialogData } from './contact-request-dialog';
import { Contact } from '../../../home/bridges/store/contact/contact.model';

@Component({
  selector: 'contact-request-dialog',
  templateUrl: './contact-request-dialog.component.html',
  styleUrls: ['./contact-request-dialog.component.scss'],
})
export class ContactRequestDialogComponent implements OnInit, OnDestroy {
  @ViewChild(MatSelectionList, { static: true }) contactsRef: MatSelectionList;

  formGroup = this.formBuilder.group({
    contact: ['', Validators.required],
  });

  searchPhrase: string;

  private componentDestroyed$ = new Subject();

  constructor(
    public dialogRef: MatDialogRef<ContactRequestDialogComponent, Contact[]>,
    @Inject(MAT_DIALOG_DATA) public data: ContactRequestDialogData,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit(): void {
    this.contactsRef.selectionChange
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(this.onSelectionChange.bind(this));
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next();
    this.componentDestroyed$.complete();
  }

  confirm(): void {
    if (this.formGroup.valid) {
      this.dialogRef.close([this.formGroup.get('contact').value]);
    }
  }

  cancel(): void {
    this.dialogRef.close([]);
  }

  private onSelectionChange(event: MatSelectionListChange) {
    const areOtherOptionsSelected = event.source.selectedOptions.selected.length > 1;
    if (areOtherOptionsSelected) {
      this.contactsRef.deselectAll();
      this.contactsRef.selectedOptions.toggle(event.option);
    }
    this.formGroup.get('contact').setValue(event.option.selected ? event.option.value : null);
  }
}
