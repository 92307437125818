import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { SharedModule } from '@shared/shared.module';
import { bridgesRoutes } from './bridges.routes';
import { BridgesPageComponent } from './+bridges-page/bridges-page.component';
import { BroadcastGroupsComponent } from './+bridges-page/broadcast-groups/broadcast-groups.component';
import { IntercomBridgeComponent } from './+bridges-page/intercom-bridge/intercom-bridge.component';
import { StoreModule } from '@ngrx/store';
import * as fromBridges from './store';
import { EffectsModule } from '@ngrx/effects';
import { IntercomEffects } from './store/intercom/intercom.effects';
import { BroadcastGroupEffects } from './store/broadcast-group/broadcast-group.effects';
import { HandsetEffects } from './store/handset/handset.effects';
import { CommunityEffects } from './store/community/community.effects';
// import { HandsetsBarComponent } from './+bridges-page/handsets-bar/handsets-bar.component';
import { TwosomeBridgesComponent } from './+bridges-page/twosome-bridges/twosome-bridges.component';
import { MulticircuitBridgesComponent } from './+bridges-page/multicircuit-bridges/multicircuit-bridges.component';

import { ContactEffects } from './store/contact/contact.effects';
import { TwosomeEffects } from './store/twosome/twosome.effects';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    RouterModule.forChild(bridgesRoutes),
    StoreModule.forFeature(fromBridges.bridgesFeatureKey, fromBridges.reducers, {
      metaReducers: fromBridges.metaReducers,
    }),
    EffectsModule.forFeature([
      IntercomEffects,
      BroadcastGroupEffects,
      HandsetEffects,
      CommunityEffects,
      ContactEffects,
      TwosomeEffects,
    ]),
  ],
  declarations: [
    BridgesPageComponent,
    BroadcastGroupsComponent,
    IntercomBridgeComponent,
    TwosomeBridgesComponent,
    MulticircuitBridgesComponent,
  ],
})
export class BridgesModule {}
