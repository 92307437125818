import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CanActivateHall } from './common/guards/can-active-hall';
import { hallRoutes } from './hall/hall.routes';
import { NotFoundComponent } from './hall/components/not-found/not-found.component';
import { homeRoutes } from './home/home.routes';
import { environment } from '../environments/environment';
import { CanActivateHome } from '@ethervox/auth-store';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'mcs-hall',
  },
  {
    path: 'mcs-hall',
    children: hallRoutes,
  },
  {
    path: 'mcs-home',
    children: homeRoutes,
    canActivate: [CanActivateHome],
  },
  {
    path: environment.production ? '**' : 'not-found',
    redirectTo: '/404',
  },
  {
    path: '404',
    component: NotFoundComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [CanActivateHall, CanActivateHome],
})
export class AppRoutingModule {}
