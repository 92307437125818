import { createReducer, on } from '@ngrx/store';
import { Community, DynamicBridge } from './community.model';
import * as CommunityActions from './community.actions';
import { confTranscript } from 'src/app/store/actions/socket.actions';

export const communityFeatureKey = 'community';

export interface TranscriptionItem {
  bridgechannelnum?: string;
  channelId?: string;
  timestamp: any;
  text: string;
}

export interface TranscriptionAggregate {
  bridgechannelnum?: string;
  channelId?: string;
  startTimestamp: any;
  username?: string;
  conferenceName?: string;
  transcriptions: TranscriptionItem[];
}

export interface State {
  community: Community;
  transcriptions: { [key: string]: TranscriptionItem[] };
  transcriptionArray: TranscriptionItem[];
  transcriptionsAggregatesByBCN: TranscriptionAggregate[];
  transcriptionsAggregatesByChannelId: TranscriptionAggregate[];
  lastAggregatedBCN: string;
  lastAggregatedChannelId: string;
  dynamicBridges: DynamicBridge[];
  loaded: boolean;
  loading: boolean;
  channelIdToUsername: { [key: string]: string };
}

export const initialState: State = {
  community: <Community>{},
  transcriptions: {},
  transcriptionArray: [],
  dynamicBridges: [],
  transcriptionsAggregatesByBCN: [],
  transcriptionsAggregatesByChannelId: [],
  lastAggregatedBCN: null,
  lastAggregatedChannelId: null,
  loaded: false,
  loading: false,
  channelIdToUsername: {},
};

export const reducer = createReducer(
  initialState,
  on(CommunityActions.loadCommunity, (state) => ({
    ...state,
    loading: true,
  })),
  on(confTranscript, (state, action) => {
    const { bridgechannelnum, channel_id, exten, final, callerID } = action.payload;

    if (final !== 'yes') return { ...state };

    const transcription = {
      bridgechannelnum: exten,
      channelId: channel_id,
      timestamp: new Date().toISOString(),
      text: action.payload['transcript-text'] || 'test',
    };

    const transcriptions = {
      [action.payload.exten]: [
        ...(state.transcriptions[action.payload.exten] || []),
        transcription,
      ],
    };

    const transcriptionsAggregatesByBCN = [...state.transcriptionsAggregatesByBCN];
    if (!state.transcriptionsAggregatesByBCN.length || exten !== state.lastAggregatedBCN) {
      transcriptionsAggregatesByBCN.push({
        bridgechannelnum: exten,
        channelId: channel_id,
        username: state.channelIdToUsername[channel_id] || action.payload.callerID,
        startTimestamp: new Date().toISOString(),
        transcriptions: [transcription],
      });
    } else {
      const lastAggregateIndex = transcriptionsAggregatesByBCN.length - 1;
      transcriptionsAggregatesByBCN[lastAggregateIndex].transcriptions.push(transcription);
    }

    const transcriptionsAggregatesByChannelId = [...state.transcriptionsAggregatesByChannelId];
    if (
      !state.transcriptionsAggregatesByChannelId.length ||
      callerID !== state.lastAggregatedChannelId
    ) {
      transcriptionsAggregatesByChannelId.push({
        bridgechannelnum: exten,
        channelId: callerID,
        username: state.channelIdToUsername[channel_id] || action.payload.callerID,
        startTimestamp: new Date().toISOString(),
        transcriptions: [transcription],
      });
    } else {
      const lastAggregateIndex = transcriptionsAggregatesByChannelId.length - 1;
      transcriptionsAggregatesByChannelId[lastAggregateIndex].transcriptions.push(transcription);
    }

    return {
      ...state,
      lastAggregatedBCN: exten,
      lastAggregatedChannelId: callerID,
      transcriptionsAggregatesByBCN,
      transcriptionsAggregatesByChannelId,
      transcriptionArray: [...state.transcriptionArray, transcription],
      transcriptions: { ...state.transcriptions, ...transcriptions },
    };
  }),
  on(CommunityActions.loadCommunityError, (state) => ({
    ...state,
    loading: false,
    loaded: false,
  })),
  on(CommunityActions.loadCommunitySuccess, (state, action) => {
    const { localSites, remoteSites } = action;
    const localSitesMapping = localSites.map((site) =>
      site.sites.reduce((acc, curr) => ((acc[curr.id] = curr.label), acc), {})
    );
    const remoteSitesMapping = remoteSites.map((site) =>
      site.sites.reduce((acc, curr) => ((acc[curr.id] = curr.label), acc), {})
    );
    const allSitesMapping = [...localSitesMapping, ...remoteSitesMapping];
    const channelIdToUsername = {
      ...allSitesMapping.reduce((acc, curr) => Object.assign(acc, curr), {}),
    };
    return {
      ...state,
      community: action.community,
      dynamicBridges: action.dynamicBridges,
      loaded: true,
      loading: false,
      channelIdToUsername,
    };
  })
);
