<h3 class="dialog-title">
  Request Contact
</h3>
<mat-form-field class="search">
  <input
    matInput
    placeholder="Search Contacts..."
    [(ngModel)]="searchPhrase">
</mat-form-field>
<mat-selection-list #contacts>
  <mat-list-option
    *ngFor="let contact of data.contacts | filterBy : searchPhrase : 'displayName' : 'address' : 'email'"
    [value]="contact">

    {{contact.displayName}}
  </mat-list-option>
</mat-selection-list>
<div class="dialog-controls">
  <button
    color="default"
    (click)="cancel()"
    mat-button>
    Cancel
  </button>
  <button
    class="control--right"
    color="primary"
    (click)="confirm()"
    mat-raised-button>
    Confirm
  </button>
</div>
