
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { SharedModule } from '@shared/shared.module';
import { contactsRoutes } from './contacts.routes';
import { ContactsPageComponent } from './+contacts-page/contacts-page.component';
import { ContactSearchComponent } from './contact-search/contact-search.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    RouterModule.forChild(contactsRoutes)
  ],
  declarations: [ContactsPageComponent, ContactSearchComponent]
})
export class ContactsModule { }
