<div class="container">
  <contact-search
    (searchPhrase)="searchPhrase = $event"
    (inviteEmail)="onInviteEmail($event)"
    (requestContact)="onRequestContact($event)"
  >
  </contact-search>
  <h4 class="mat-title">Contacts</h4>
  <card-list *ngIf="contacts$ | async | keyvalue as communities" class="animated fadeIn">
    <!-- <pre>{{ communities | json }}</pre> -->
    <!-- <div
      class="card-item no-contacts"
      *ngIf="
        !(contacts | filterBy: searchPhrase:'displayName':'phone':'email':'address':'requestid')
          .length
      "
    >
      <span class="mat-body">Nothing to show</span>
    </div> -->
    <div *ngFor="let contacts of communities">
      <h2 class="community-name">{{ contacts.key }}</h2>
      <div
        *ngFor="
          let contact of contacts.value
            | filterBy: searchPhrase:'displayName':'phone':'email':'address':'requestid'
        "
        class="card-item {{ contact.state }}"
      >
        <mat-checkbox color="primary"></mat-checkbox>
        <user-avatar [uri]="contact.avatar?.uri">{{ contact.displayName.charAt(0) }}</user-avatar>
        <div class="meta">
          <h3>
            <direction-indicator [direction]="contact.direction"></direction-indicator>
            {{ contact.displayName }}
            <span class="mat-caption" *ngIf="contact.phone">
              &nbsp;&nbsp; m: {{ contact.phone }}
              <button mat-icon-button color="primary" (click)="onCtiCall(contact.phone)">
                <mat-icon [style.fontSize]="'13px'">phone</mat-icon>
              </button>
            </span>
          </h3>
          <span class="status">{{ contact.state }}</span>
        </div>
        <div class="controls--right">
          <a
            mat-icon-button
            *ngIf="contact.phone as phone"
            [attr.href]="'callto:' + phone | safeUrl"
          >
            <mat-icon>phone</mat-icon>
          </a>
          <handset-selector
            *ngIf="handsets$ | async as handsets"
            (selected)="requestMemberBridge($event, contact)"
            (unselected)="disconnectHandset(contact, $event)"
            [connected]="getContactHandset(contact, handsets)"
            [handsets]="handsets"
          ></handset-selector>
          <ui-button
            class="icon"
            color="primary"
            *ngIf="contact.state === 'pending' && contact.direction === 'inbound'"
            (click)="acceptContact(contact)"
          >
            <mat-icon>check</mat-icon>
          </ui-button>
          <ui-button
            class="icon"
            color="default"
            *ngIf="contact.state === 'accepted'"
            (click)="requestBridge(contact)"
          >
            <mat-icon>contact_phone</mat-icon>
          </ui-button>
          <ui-button
            class="icon"
            color="warn"
            *ngIf="contact.state !== 'rejected'"
            (click)="rejectContact(contact)"
          >
            <mat-icon>clear</mat-icon>
          </ui-button>
        </div>
      </div>
    </div>
  </card-list>

  <ng-container *ngIf="streams$ | async as streams">
    <br /><br />
    <h4 class="mat-title">Teams</h4>
    <card-list class="streams">
      <ng-container *ngFor="let stream of streams">
        <div class="animated fadeIn card-item">
          <user-avatar>
            {{ stream.id.charAt(0).toUpperCase() }}
          </user-avatar>
          <span class="meta mat-body-2">
            <h3>
              <ng-container *ngFor="let user of stream.users; let last = last">
                {{ user.displayName }}<ng-container *ngIf="!last">, </ng-container>
              </ng-container>
            </h3>
            <span class="id mat-caption">
              {{ stream.id }}
            </span>
          </span>
          <div class="controls--right">
            <ui-button class="icon" (click)="requestHoot(stream)" color="default">
              <mat-icon>group</mat-icon>
              <mat-icon class="phone-icon">phone</mat-icon>
            </ui-button>
          </div>
        </div>
      </ng-container>
    </card-list>
  </ng-container>
</div>
