export interface AuthEntity {
  id: string;
}

export interface AuthCredentials {
  identifier: string;
  password: string;
}

export enum AuthStrategy {
  POPUP,
  FRAME,
  CREDENTIALS,
}

export interface AuthStrategyBase {
  redirectUrl?: string;
}

export interface AuthPopupStrategy extends AuthStrategyBase {
  strategy: AuthStrategy.POPUP;
}

export interface AuthFrameStrategy extends AuthStrategyBase {
  strategy: AuthStrategy.FRAME;
  container: HTMLElement;
}

export interface AuthCredentialsStrategy extends AuthStrategyBase {
  strategy: AuthStrategy.CREDENTIALS;
  credentials: AuthCredentials;
}

export type AuthProps = AuthPopupStrategy | AuthFrameStrategy | AuthCredentialsStrategy;

export interface AuthToken {
  token: string;
  redirectUrl?: string;
}
