import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { State } from 'src/app/store/reducers';
import { selectTranscriptionsAggregatesByChannelId } from '../../home/bridges/store';

@Component({
  selector: 'transcription-sheet',
  templateUrl: './transcription-sheet.component.html',
  styleUrls: ['./transcription-sheet.component.scss'],
})
export class TranscriptionSheetComponent implements OnInit {
  @Input() bcn?: string;
  @Input() confNum?: string;

  transcriptions$: Observable<any>;
  bcns: string[] = [];
  activeBcn: string;

  constructor(private store: Store<State>) {}

  ngOnInit() {
    this.transcriptions$ = this.store
      .select(selectTranscriptionsAggregatesByChannelId)
      .pipe(
        tap((transcriptions) => {
          this.bcns = transcriptions
            .map((t) => t.bridgechannelnum)
            .filter((v, i, a) => a.indexOf(v) === i);
        })
      )
      .pipe(
        map((transcriptions) =>
          this.confNum
            ? transcriptions.filter((t) => t.bridgechannelnum === this.confNum)
            : transcriptions
        )
      )
      .pipe(
        map((transcriptions) =>
          this.bcn ? transcriptions.filter((t) => t.bridgechannelnum === this.bcn) : transcriptions
        )
      );
  }
}
