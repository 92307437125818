import { Component, OnInit } from '@angular/core';
import { Observable, combineLatest, of } from 'rxjs';
import { Store } from '@ngrx/store';
import {
  State,
  selectLoadingStatus,
  selectLoadingStatusMulticircuit,
} from '../../../store/app.reducer';
@Component({
  selector: 'ethervox-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent implements OnInit {
  color = 'primary';
  mode = 'indeterminate';
  value = 50;
  isLoading: Observable<boolean>;

  constructor(private store: Store<State>) {}

  ngOnInit(): void {
    combineLatest(
      this.store.select(selectLoadingStatus),
      this.store.select(selectLoadingStatusMulticircuit)
    ).subscribe((data) => {
      this.isLoading = of(data.some((item) => item === true));
    });
  }
}
