import { Routes } from '@angular/router';

import { BridgesPageComponent } from './+bridges-page/bridges-page.component';

export const bridgesRoutes: Routes = [
  {
    path: '',
    component: BridgesPageComponent,
  },
];
