import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AuthCredentials, AuthEntity, AuthToken } from '../+state/auth.models';
import { TOKEN_KEY } from '../auth-store.const';

@Injectable({
  providedIn: 'root',
})
export class AuthResource {
  constructor(private httpClient: HttpClient) {}

  authenticate(credentials: AuthCredentials): Observable<AuthToken> {
    return this.httpClient.post<AuthToken>(`https://api.ethervox.io/api/v2/auth/local`, credentials);
  }

  getAuthEntity(): Observable<AuthEntity> {
    const headers = {
      Authorization: `Bearer ${localStorage.getItem(TOKEN_KEY)}`,
    };
    const options = { headers, observe: 'body' as const };
    return this.httpClient.get<AuthEntity>(`https://api.ethervox.io/api/v2/user/profile`, options);
  }
}
