/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { noop } from 'lodash';
import * as io from 'socket.io-client';

import { Observable, BehaviorSubject } from 'rxjs';
import { filter, switchMap, take } from 'rxjs/operators';
import { etherVoxApiUrl, protectedSocketPath } from '../const/service.const';

export interface CarrierListener<T = any> {
  $: Observable<T>;
}

export interface CarrierObject {
  id: string;
}

@Injectable({
  providedIn: 'root',
})
export class SocketConnectorService {
  public socket: SocketIOClient.Socket;
  connected$ = new BehaviorSubject<boolean>(false);

  private socketListeners = new Map();

  on<T>(eventName: string): Observable<T> {
    const $ = this.connected$
      .pipe(
        filter((c) => !!c),
        take(1)
      )
      .pipe(
        switchMap(
          () =>
            new Observable<T>((subscriber) => {
              this.socket.on(
                'event',
                (e) => e && e.event === eventName && subscriber.next(e['event-object'] as T)
              );
            })
        )
      );

    return $;
  }

  initialize(token: string): Promise<SocketIOClient.Socket> {
    const query = { token };
    this.socket =
      this.socket && this.socket.connected
        ? this.socket
        : io(etherVoxApiUrl, { path: protectedSocketPath, query });
    this.socket.on('event', (e) => (this.socketListeners[e.event] || noop)(e['event-object']));
    return new Promise((resolve, reject) => {
      this.socket.on('connect', () => (resolve(this.socket), this.connected$.next(true)));
      this.socket.on('disconnect', reject);
    });
  }
}
