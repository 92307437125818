import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { ResourceAbstract } from '@resources/resources.abstract';
import { map } from 'rxjs/operators';
import { Contact } from 'src/app/home/bridges/store/contact/contact.model';

@Injectable({
  providedIn: 'root',
})
export class ContactsService extends ResourceAbstract {
  constructor(protected httpClient: HttpClient) {
    super(httpClient);
  }

  getContacts(): Observable<Contact[]> {
    return this.get<Contact[]>('ethervox/community/members').pipe(
      map((contacts) => contacts.sort((a, b) => a.displayName.localeCompare(b.displayName)))
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getMemberInfo(address: string): Observable<any> {
    return this.get(`ethervox/member/lookup?address=${address}`);
  }

  acceptContact(requestid: string): Observable<unknown> {
    return this.put<unknown>(`ethervox/contacts/${requestid}/accept`);
  }

  rejectContact(requestid: string): Observable<unknown> {
    return this.put<unknown>(`ethervox/contacts/${requestid}/reject`);
  }

  ctiCall(destination: string): Observable<unknown> {
    return this.post<unknown>(`ethervox/cti/call`, { destination });
  }
}
