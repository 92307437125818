import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { mergeMap, map, catchError, switchMap, tap, repeat, take } from 'rxjs/operators';
import { of } from 'rxjs';

import * as fromBG from './broadcast-group.actions';
import { BroadcastGroupService } from '@resources/broadcast-group/broadcast-group.service';
import { SnackbarService } from '@services/snackbar/snackbar.service';
import { DialogService } from '@services/dialog/dialog.service';

@Injectable()
export class BroadcastGroupEffects {
  loadBroadcastGroups$ = createEffect(() =>
    this.actions$.pipe(ofType(fromBG.loadBroadcastGroups)).pipe(
      switchMap(() =>
        this.broadcastGroupService
          .getAll()
          .pipe(map((broadcastGroups) => fromBG.loadBroadcastGroupsSuccess({ broadcastGroups })))
          .pipe(catchError((error) => of(fromBG.loadBroadcastGroupsError({ error }))))
      )
    )
  );

  createBroadcastGroup$ = createEffect(() =>
    this.actions$.pipe(ofType(fromBG.createBroadcastGroup)).pipe(
      switchMap(({ broadcastGroup: partial }) =>
        this.broadcastGroupService
          .create(partial || {})
          .pipe(
            map(
              // (broadcastGroup) => fromBG.createBroadcastGroupSuccess({ broadcastGroup })
              () => fromBG.loadBroadcastGroups()
            )
          )
          .pipe(tap(() => this.snackbar.openSimple('Broadcast Group has been created')))
          .pipe(catchError((error) => of(fromBG.createBroadcastGroupError({ error }))))
      )
    )
  );

  updateBroadcastGroup$ = createEffect(() =>
    this.actions$.pipe(ofType(fromBG.updateBroadcastGroup)).pipe(
      mergeMap(({ broadcastGroup: partial }) =>
        this.broadcastGroupService
          .update(partial.id, partial.name)
          .pipe(
            switchMap((broadcastGroup) => [
              fromBG.updateBroadcastGroupSuccess({ broadcastGroup }),
              fromBG.loadBroadcastGroups(),
            ])
          )
          .pipe(tap(() => this.snackbar.openSimple('Broadcast Group has been updated')))
          .pipe(catchError((error) => of(fromBG.updateBroadcastGroupError({ error }))))
      )
    )
  );

  deleteBroadcastGroup$ = createEffect(() =>
    this.actions$
      .pipe(ofType(fromBG.deleteBroadcastGroup))
      .pipe(
        switchMap(({ id }) =>
          this.dialogService.openConfirmationDialog(
            'Are you sure you want to remove this Broadcast Group?',
            id
          )
        )
      )
      .pipe(
        mergeMap((id) =>
          this.broadcastGroupService
            .remove(id)
            .pipe(
              switchMap(() => [
                fromBG.deleteBroadcastGroupSuccess({ id }),
                // fromBG.loadBroadcastGroups()
              ])
            )
            .pipe(tap(() => this.snackbar.openSimple('Broadcast Group has been removed')))
            .pipe(catchError((error) => of(fromBG.deleteBroadcastGroupError({ error }))))
        )
      )
  );

  attachTwosome$ = createEffect(() =>
    this.actions$.pipe(ofType(fromBG.attachTwosome)).pipe(
      mergeMap(({ broadcastGroupId, twosomeId }) =>
        this.broadcastGroupService
          .addBridge(broadcastGroupId, twosomeId)
          .pipe(
            switchMap((broadcastGroup) => [
              fromBG.attachTwosomeSuccess({ broadcastGroup }),
              fromBG.loadBroadcastGroups(),
            ])
          )
          .pipe(
            tap(() => this.snackbar.openSimple('Bridge has been attached to the Broadcast Group'))
          )
          .pipe(catchError((error) => of(fromBG.attachTwosomeError({ error }))))
      )
    )
  );

  detachTwosome$ = createEffect(() =>
    this.actions$.pipe(ofType(fromBG.detachTwosome)).pipe(
      mergeMap(({ broadcastGroupId, twosomeId }) =>
        this.broadcastGroupService
          .removeBridge(broadcastGroupId, twosomeId)
          .pipe(
            switchMap((broadcastGroup) => [
              fromBG.detachTwosomeSuccess({ broadcastGroup }),
              fromBG.loadBroadcastGroups(),
            ])
          )
          .pipe(
            tap(() => this.snackbar.openSimple('Bridge has been detached from the Broadcast Group'))
          )
          .pipe(catchError((error) => of(fromBG.detachTwosomeError({ error }))))
      )
    )
  );

  constructor(
    private actions$: Actions,
    private snackbar: SnackbarService,
    private dialogService: DialogService,
    private broadcastGroupService: BroadcastGroupService
  ) {}
}
