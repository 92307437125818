/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { pickBy, isNull } from 'lodash';

import { etherVoxApiUrl, etherVoxApiContext } from '@const/api.const';
import { SessionService } from '@services/session/session.service';
import { environment } from 'src/environments/environment';

export abstract class ResourceAbstract {
  constructor(protected httpClient: HttpClient) {}

  protected get<T>(path: string | string[], options?, v2 = false): Observable<T> {
    return this.httpClient
      .get<T>(this._getRequestPath(path, v2), this.getRequestOptions(options))
      .pipe(map(this._getResponseFromModel.bind(this)));
  }

  protected delete<T>(path: string | string[], options?, v2 = false): Observable<T> {
    return this.httpClient
      .delete<T>(this._getRequestPath(path, v2), this.getRequestOptions(options))
      .pipe(map(this._getResponseFromModel.bind(this)));
  }

  protected post<T>(
    path: string,
    body?: any,
    asFormData = false,
    options = {},
    v2 = false
  ): Observable<T> {
    const normalizedBody =
      body && !Array.isArray(body) ? pickBy(body, (property) => !isNull(property)) : body;
    const requestBody = asFormData
      ? this.buildURLEncodedBody(normalizedBody).toString()
      : normalizedBody;
    return this.httpClient
      .post<T>(this._getRequestPath(path, v2), requestBody, this.getRequestOptions(options))
      .pipe(map(this._getResponseFromModel.bind(this)));
  }

  protected put<T>(
    path: string,
    body?: any,
    asFormData = false,
    options = {},
    v2 = false
  ): Observable<T> {
    const normalizedBody =
      body && !Array.isArray(body) ? pickBy(body, (property) => !isNull(property)) : body;
    const requestBody = asFormData
      ? this.buildURLEncodedBody(normalizedBody).toString()
      : normalizedBody;
    return this.httpClient
      .put<T>(this._getRequestPath(path, v2), requestBody, this.getRequestOptions(options))
      .pipe(map(this._getResponseFromModel.bind(this)));
  }

  /** @todo create type */
  getRequestOptions(options = {}): any {
    return { ...this._requestOptions, ...options };
  }

  private _getRequestPath(fragments: string | string[], v2 = false): string {
    const fragmentsArray = Array.isArray(fragments) ? [...fragments] : [fragments];
    const pathArray = [
      etherVoxApiUrl,
      v2 ? etherVoxApiContext.replace('v3', 'v2') : etherVoxApiContext,
      ...fragmentsArray,
    ];
    return pathArray.join('/');
  }

  /** @todo create type */
  private get _requestOptions() {
    const headers = {
      Authorization: `Bearer ${SessionService.token}`,
    };
    const params = {
      community: environment.community,
    };
    return { headers, observe: 'body' as const, params };
  }

  private _getResponseFromModel<T>(responseModel: T): T {
    return responseModel;
  }

  private buildURLEncodedBody(rawBody: any): URLSearchParams {
    const URLEncodedBody = new URLSearchParams();
    Object.keys(rawBody).forEach((key) => {
      const value = rawBody[key];
      URLEncodedBody.set(key, <string>value);
    });
    return URLEncodedBody;
  }
}
