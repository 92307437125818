import { createAction, props } from '@ngrx/store';
import { Meeting } from '../../../../src/app/common/models/site.model';

// set Selected Site
export const setSelectedSite = createAction(
  '[context] Set Selected Site',
  props<{ payload: Meeting }>()
);

export const unsetSelectedSite = createAction('[context] Unset Selected Site');

export const loadSuccess = createAction('[context] Load Success', props<{ payload: boolean }>());
