import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { ConfirmDialogComponent } from '@shared/dialogs/confirm-dialog/confirm-dialog.component';
import { dialogSize } from '@const/ui.const';
import { ConfirmDialogData } from '@shared/dialogs/confirm-dialog/confirm-dialog';
import { MatDialog } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  constructor(public matDialog: MatDialog) {}

  // eslint-disable-next-line
  openConfirmationDialog<T = any>(caption: string, resolve?: T): Observable<T> {
    return this.matDialog
      .open(ConfirmDialogComponent, {
        width: dialogSize.MEDIUM,
        data: <ConfirmDialogData>{ caption },
      })
      .afterClosed()
      .pipe(filter((result) => !!result))
      .pipe(map(() => resolve));
  }
}
