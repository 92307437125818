import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { filter } from 'rxjs/operators';

import { Meeting } from '../../../common/models/site.model';
import { State, selectSites } from '../../../store/app.reducer';
import * as multicircuitActions from '../../../store/multi-circuit/multicircuit-actions';
import { SocketCarrierService } from '../../../common/services/socket-carrier.service';

@Component({
  selector: 'ethervox-multicircuit',
  templateUrl: './multicircuit.page.component.html',
  styleUrls: ['./multicircuit.page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MulticircuitPageComponent implements OnInit {
  site$: Observable<Meeting[]>;
  selectedSite$: Observable<Meeting>;
  config = {
    itemsPerPage: 12,
    currentPage: 1,
  };
  currentRow;
  selectedType = null;
  playStatus: Array<string> = [];
  stopStatus: Array<string> = [];

  constructor(private store: Store<State>, private socketCarrierService: SocketCarrierService) {}

  ngOnInit(): void {
    this.site$ = this.store.select(selectSites);
    this.site$.pipe(filter((c) => !!c)).subscribe((p) => {
      if (p && !p.some((item) => item.selected == true)) this.selectRow(p[0]);
    });
    this.socketCarrierService.dispatchActions();
  }

  selectRow(row: Meeting): void {
    this.currentRow = row?.label;
    this.store.dispatch(multicircuitActions.setSelectedMeeting({ payload: { ...row } }));
    const payload = row?.sites[0] ? row?.sites[0] : null;
    this.store.dispatch(multicircuitActions.setSelectedChannel({ payload }));
  }

  public togglePlay(item: any): void {
    let active: string;
    active = 'active';

    if (!this.playStatus?.includes(item.id)) {
      this.playStatus.push(item.id);
    } else {
      this.playStatus = this.playStatus.filter((e) => e != item.id);
      active = 'passive';
    }
  }

  public toggleStop(item: any): void {
    let active: string;
    active = 'active';

    if (!this.stopStatus?.includes(item.id)) {
      this.stopStatus.push(item.id);
    } else {
      this.stopStatus = this.stopStatus.filter((e) => e != item.id);
      active = 'passive';
    }
  }

  public getPlayStatus(idx: string): boolean {
    if (this.playStatus.includes(idx)) {
      return true;
    } else {
      return false;
    }
  }

  public getStopStatus(idx: string): boolean {
    if (this.stopStatus.includes(idx)) {
      return true;
    } else {
      return false;
    }
  }

  pageChanged(page: number): void {
    this.config.currentPage = page;
  }

  loadLocalSites(): void {
    this.store.dispatch(multicircuitActions.loadlocalSites());
    this.config.currentPage = 0;
  }

  loadRemoteSites(): void {
    this.store.dispatch(multicircuitActions.loadSites());
    this.config.currentPage = 0;
  }
}
