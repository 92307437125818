<ng-container>
  <cdk-tree [dataSource]="_sites" [treeControl]="sitesTree" #tree>
    <cdk-nested-tree-node *cdkTreeNodeDef="let node" class="example-tree-node">
      <mat-accordion>
        <mat-expansion-panel
          [expanded]="subtables[node.id]"
          (opened)="subtables[node.id] = true"
          (closed)="subtables[node.id] = false"
        >
          <mat-expansion-panel-header>
            <mat-panel-title>
              <mat-chip-list>
                <mat-chip
                  class="chip--channel mat-caption"
                  [class.chip--channel--active]="node.connectionStatus || node.connected"
                  ><span class="mat-caption">Channel</span></mat-chip
                >
              </mat-chip-list>
              <span class="mat-caption channel-label">
                <mat-icon [class.active]="node.talkingStatus" class="talking-status-icon"
                  >record_voice_over</mat-icon
                >
                &nbsp;&nbsp; {{ node.label }} / {{ node.ucn }}
              </span>
              <div class="pull-right media-ctrls">
                <button
                  [class.active]="!node.mutedMic"
                  (click)="onToggleMic(node, $event)"
                  mat-icon-button
                >
                  <mat-icon>mic</mat-icon>
                </button>
                <button
                  [class.active]="!node.mutedSpeaker"
                  (click)="onToggleSpeaker(node, $event)"
                  mat-icon-button
                >
                  <mat-icon>volume_up</mat-icon>
                </button>
              </div>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <button
            class="ringdown-btn"
            [class.active]="node.talkingStatus"
            mat-mini-fab
            color="primary"
            (click)="clickPhone(node, $event)"
          >
            <mat-icon>phone_in_talk</mat-icon>
          </button>
          <ethervox-multicircuit-context-page [channel]="node"></ethervox-multicircuit-context-page>

          <ethervox-expandable-subtable
            #subtable
            *ngIf="subtable && subtables[node.bridge]"
            [id]="node.bridge"
          ></ethervox-expandable-subtable>
        </mat-expansion-panel>
      </mat-accordion>
    </cdk-nested-tree-node>

    <cdk-nested-tree-node *cdkTreeNodeDef="let node; when: hasChild" class="example-tree-node">
      <ng-container *ngIf="node.isGrouped">
        <mat-card class="site-header grouped">
          <button
            mat-icon-button
            cdkTreeNodeToggle
            (click)="
              expansionModel.toggle(node.id); sitesTree.isExpanded(node) && onNodeExpanded(node)
            "
          >
            <mat-icon class="mat-icon-rtl-mirror">
              {{ sitesTree.isExpanded(node) ? 'expand_more' : 'chevron_right' }}
            </mat-icon>
          </button>

          <mat-chip-list>
            <mat-chip
              class="chip--channel mat-caption"
              [class.chip--channel--active]="node.connectionStatus || node.connected"
              ><span class="mat-caption">Participant</span></mat-chip
            >
          </mat-chip-list>
          <!-- <mat-icon *ngIf="node.name === 'Shared'">group</mat-icon>&nbsp;&nbsp; {{ node.name }} -->
          <span class="mat-caption channel-label">
            <mat-icon [class.active]="node.talkingStatus" class="talking-status-icon"
              >record_voice_over</mat-icon
            >
            &nbsp;&nbsp; {{ node.name }}
          </span>
        </mat-card>
        <div [class.example-tree-invisible]="!sitesTree.isExpanded(node)" class="site-context">
          <ng-container cdkTreeNodeOutlet></ng-container>
        </div>
      </ng-container>
      <ng-container *ngIf="!node.isGrouped">
        <div class="site-header">
          <button
            mat-icon-button
            cdkTreeNodeToggle
            (click)="
              expansionModel.toggle(node.id); sitesTree.isExpanded(node) && onNodeExpanded(node)
            "
          >
            <mat-icon class="mat-icon-rtl-mirror">
              {{ sitesTree.isExpanded(node) ? 'expand_more' : 'chevron_right' }}
            </mat-icon>
          </button>
          <mat-chip-list>
            <mat-chip class="chip--mc">{{
              chipLabel || node.chipLabel || 'Multi-Circuit'
            }}</mat-chip>
          </mat-chip-list>
          &nbsp;&nbsp; {{ node.label }} / {{ node.signalling }}
        </div>
        <div [class.example-tree-invisible]="!sitesTree.isExpanded(node)" class="site-context">
          <button
            [disabled]="onCall"
            mat-raised-button
            color="primary"
            class="webrtc-btn"
            (click)="webRTCcall.emit(node)"
          >
            <mat-icon> call </mat-icon>
            WebRTC
          </button>
          <button
            mat-raised-button
            color="primary"
            class="transcription-btn"
            (click)="openTranscriptionsSheet(node)"
          >
            Transcriptions
          </button>
          <ethervox-multicircuit-context-page
            *ngIf="sitesTree.isExpanded(node)"
            [flexAccess]="flexAccess$ | async"
            [flexAccessLoaded]="flexAccessLoaded"
            [site]="node"
            (refreshFlexAccess)="refreshFlexAccess()"
          ></ethervox-multicircuit-context-page>
          <ng-container cdkTreeNodeOutlet></ng-container>
        </div>
      </ng-container>
    </cdk-nested-tree-node>
  </cdk-tree>
</ng-container>
