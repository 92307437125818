import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { ResourceAbstract } from '@resources/resources.abstract';
import { Intercom } from '../../../home/bridges/store/intercom/intercom.model';

@Injectable({
  providedIn: 'root',
})
export class IntercomService extends ResourceAbstract {
  constructor(protected httpClient: HttpClient) {
    super(httpClient);
  }

  getAll(): Observable<Intercom[]> {
    return this.get<Intercom[]>('ethervox/community/intercom');
  }

  addConnection(contactId: string): Observable<Intercom> {
    return this.post<Intercom>(`ethervox/community/intercom`, { contacts: [contactId] });
  }

  removeConnection(intercomId: string, contactId: string): Observable<Intercom> {
    return this.delete<Intercom>(`ethervox/community/intercom/${intercomId}/contact/${contactId}`);
  }

  toggleIntercomDevice(
    intercom: Intercom,
    line: any,
    device: 'mic' | 'speaker',
    isMuted: boolean
  ): Observable<Intercom> {
    return this.post<Intercom>(
      `ethervox/community/intercom/${intercom.id}/dynamicline/${line.id}/${device}/${
        isMuted ? 'unmute' : 'mute'
      }`
    );
  }
}
