import { createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { get } from 'lodash';

import { BroadcastGroup } from './broadcast-group.model';
import * as BroadcastGroupActions from './broadcast-group.actions';
import * as CommunityActions from './../community/community.actions';
import { DynamicBridge } from '../community/community.model';
import { Handset } from '../handset/handset.model';

export const broadcastGroupsFeatureKey = 'broadcastGroups';

export const selectBroadcastGroupId = ({ id }: BroadcastGroup): string => id;

export const sortByCreatedAt = (a: BroadcastGroup, b: BroadcastGroup): number => {
  return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
};

export interface State extends EntityState<BroadcastGroup> {
  loaded: boolean;
  loading: boolean;
}

export const adapter: EntityAdapter<BroadcastGroup> = createEntityAdapter<BroadcastGroup>();

export const initialState: State = adapter.getInitialState({
  loaded: false,
  loading: false,
});

const findConnectedHandset = (
  broadcastGroup: BroadcastGroup,
  dynamicBridges: DynamicBridge[],
  handsets: Handset[]
): Handset | null => {
  const broadcastGroupConfNum = broadcastGroup.ownerBridge?.bridge?.confNum;
  const connectedDynamicBridge = dynamicBridges.find(
    (db) => get(db, 'called') === broadcastGroupConfNum
  );
  return connectedDynamicBridge
    ? handsets.find((h) => connectedDynamicBridge.destnum === h?.bridge?.bridge.confNum)
    : null;
};

export const reducer = createReducer(
  initialState,
  on(
    BroadcastGroupActions.loadBroadcastGroups,
    BroadcastGroupActions.createBroadcastGroup,
    (state) => ({
      ...state,
      loading: true,
    })
  ),
  on(
    BroadcastGroupActions.loadBroadcastGroupsError,
    BroadcastGroupActions.createBroadcastGroupError,
    (state) => ({
      ...state,
      loading: false,
      loaded: false,
    })
  ),
  on(BroadcastGroupActions.loadBroadcastGroupsSuccess, (state, action) => ({
    ...adapter.setAll(action.broadcastGroups, state),
    loading: false,
    loaded: true,
  })),
  on(BroadcastGroupActions.createBroadcastGroupSuccess, (state, action) => ({
    ...adapter.addOne(action.broadcastGroup, state),
    loading: false,
  })),
  on(BroadcastGroupActions.updateBroadcastGroupSuccess, (state, action) => ({
    ...adapter.upsertOne(action.broadcastGroup, state),
    loading: false,
  })),
  on(BroadcastGroupActions.deleteBroadcastGroupSuccess, (state, action) => ({
    ...adapter.removeOne(action.id, state),
    loading: false,
  })),
  on(CommunityActions.loadCommunitySuccess, (state, action) => ({
    ...adapter.map(
      (bg) => ({
        ...bg,
        onHandset: findConnectedHandset(bg, action.dynamicBridges, action.handsets),
      }),
      state
    ),
  }))
);

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
