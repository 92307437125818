export class PhoneHelpers {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  static getEtherVoxCommunity(communityLines) {
    return communityLines.find((communityLine) => communityLine.community.name === 'EtherVox');
  }
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  static getIdleLine(lines) {
    return lines.find((line) => line.state === 'idle');
  }
}
