import { Component, Input, ViewChild, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import {
  MatAutocompleteSelectedEvent,
  MatAutocompleteTrigger,
} from '@angular/material/autocomplete';
import { startWith, map } from 'rxjs/operators';
import { of, Observable } from 'rxjs';
import { Store } from '@ngrx/store';

import { BroadcastGroup } from '../../store/broadcast-group/broadcast-group.model';
import { State, selectBroadcastGroupsLoading } from '../../store';
import * as fromBroadcastGroup from '../../store/broadcast-group/broadcast-group.actions';
import { putOnHandset, dropHandset } from '../../store/handset/handset.actions';
import { Twosome } from '../../store/twosome/twosome.model';
import { Handset } from '../../store/handset/handset.model';
import { BridgeHelper } from '../../../../common/helpers/bridge.helper';

interface EditableBroadcastGroup extends BroadcastGroup {
  editable: boolean;
  formGroup: FormGroup;
  selectedBridges: Twosome[];
  filteredBridges: Observable<Twosome[]>;
}
@Component({
  selector: 'broadcast-groups',
  templateUrl: './broadcast-groups.component.html',
  styleUrls: ['./broadcast-groups.component.scss'],
})
export class BroadcastGroupsComponent implements OnInit {
  @ViewChild(MatAutocompleteTrigger) autoTrigger: MatAutocompleteTrigger;
  getInitials = BridgeHelper.getInitials;

  broadcastGroups_: EditableBroadcastGroup[] = [];

  @Input() set broadcastGroups(broadcastGroups: BroadcastGroup[]) {
    this.render(broadcastGroups);
  }

  @Input() handsets: Handset[];

  @Input() set twosomes(twosomes: Twosome[]) {
    this.availableBridges = twosomes
      .filter((l) => l.farparty)
      .map((b) => ({ ...b, name: b.farparty.name }));
    this.render(this.broadcastGroups_);
  }

  availableBridges: Twosome[];
  groups: unknown[] = [];
  loading$: Observable<boolean>;
  creating: boolean;

  constructor(private store: Store<State>) {}

  ngOnInit(): void {
    this.loading$ = this.store.select(selectBroadcastGroupsLoading);
  }

  detachTwosome(broadcastGroup: BroadcastGroup, twosome: Twosome): void {
    this.store.dispatch(
      fromBroadcastGroup.detachTwosome({
        broadcastGroupId: broadcastGroup.id,
        twosomeId: twosome.id,
      })
    );
  }

  attachTwosome(event: MatAutocompleteSelectedEvent, broadcastGroup: BroadcastGroup): void {
    const bridgeId = event.option.value.id;
    this.store.dispatch(
      fromBroadcastGroup.attachTwosome({
        broadcastGroupId: broadcastGroup.id,
        twosomeId: bridgeId,
      })
    );
  }

  createBroadcastGroup(): void {
    console.log('cl');
    this.store.dispatch(
      fromBroadcastGroup.createBroadcastGroup({
        broadcastGroup: { name: 'Broadcast Group' },
      })
    );
  }

  saveGroup(broadcastGroup: BroadcastGroup, name: string): void {
    const toUpdate = { id: broadcastGroup.id, name };
    this.store.dispatch(fromBroadcastGroup.updateBroadcastGroup({ broadcastGroup: toUpdate }));
    broadcastGroup.editable = false;
  }

  editGroup(group: BroadcastGroup): void {
    group.editable = true;
  }

  removeGroup(group: BroadcastGroup): void {
    this.store.dispatch(fromBroadcastGroup.deleteBroadcastGroup({ id: group.id }));
  }

  onMouseHoverGroup(group: BroadcastGroup, enter: boolean): void {
    if (!group.editable && group.dynamicline && group.dynamicline.length) {
      group.dynamicline
        .map((b) => document.getElementById(`line__${b.id}`))
        .filter((el) => !!el)
        .forEach((el) =>
          enter ? el.classList.add('line--highlighted') : el.classList.remove('line--highlighted')
        );
    }
  }

  onHandsetClick(group: BroadcastGroup, { id }: Handset, disconnect: boolean): void {
    const dispatchable = { handsetId: id, bridgeId: group.ownerBridge.id };
    if (disconnect) {
      this.store.dispatch(dropHandset(dispatchable));
    } else {
      this.store.dispatch(putOnHandset(dispatchable));
    }
  }

  private render(broadcastGroups: BroadcastGroup[]) {
    this.broadcastGroups_ = (broadcastGroups || []).map((bg, index) => {
      const rendered = this.broadcastGroups_[index];
      const selectedBridges = this.availableBridges.filter(
        (b) => !!bg.dynamicline.find((dl) => dl.handshake === b.requestid)
      );
      const newGroup = {
        ...bg,
        index,
        editable: rendered && rendered.editable,
        filteredBridges: of([]),
        selectedBridges,
        availableBridges: [
          ...this.availableBridges.filter(
            (b) => !bg.dynamicline.find((dl) => dl.handshake === b.requestid)
          ),
        ],
        formGroup: new FormGroup({
          name: new FormControl((rendered && rendered.name) || bg.name),
          bridges: new FormControl(selectedBridges),
        }),
      };
      newGroup.filteredBridges = newGroup.formGroup.get('bridges').valueChanges.pipe(
        startWith(null),
        map((bridge: unknown | null) =>
          bridge
            ? this.filter(newGroup.availableBridges, bridge)
            : newGroup.availableBridges.slice()
        )
      );
      return newGroup;
    });
  }

  // eslint-disable-next-line
  private filter(bridges: any[], value: string | any): string[] {
    if (!Array.isArray(value)) {
      const filterValue = value && value.name ? value.name.toLowerCase() : value.toLowerCase();
      return bridges.filter((bridge) => bridge.name.toLowerCase().indexOf(filterValue) === 0);
    }
    return bridges;
  }
}
