import { createAction, props } from '@ngrx/store';

import { Community, DynamicBridge } from './community.model';
import { BroadcastGroup } from '../broadcast-group/broadcast-group.model';
import { Handset } from '../handset/handset.model';

// loadCommunity
export const loadCommunity = createAction('[Community/API] Load Community');

export const loadCommunitySuccess = createAction(
  '[Community/API] Load Community Success',
  props<{
    community: Community;
    handsets: Handset[];
    calls: unknown[];
    dynamicBridges: DynamicBridge[];
    broadcastGroups: BroadcastGroup[];
    localSites: any[];
    remoteSites: any[];
  }>()
);

export const loadCommunityError = createAction(
  '[Community/API] Load Community Error',
  props<{ error: unknown }>()
);
