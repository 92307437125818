/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { flatten } from 'lodash';

export class BridgeHelper {
  static getDynamicLines(communityLines: any): any {
    return flatten(
      [...communityLines].map((communityLine) => {
        communityLine.lines.forEach((line) => (line.community = communityLine.community));
        return communityLine.lines;
      })
    );
  }

  static getInitials(input = ''): string {
    return (input || '')
      .split(' ')
      .slice(0, 2)
      .map((partial) => partial.charAt(0).toUpperCase())
      .join('');
  }
}
