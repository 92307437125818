import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { Twosome } from './twosome.model';

// updateSettings
export const updateSettings = createAction(
  '[Twosome/API] Update Settings',
  props<{ twosome: Twosome }>()
);

export const updateSettingsSuccess = createAction(
  '[Twosome/API] Update Settings Success',
  props<{ twosome: Twosome }>()
);

export const updateSettingsError = createAction(
  '[Twosome/API] Update Settings Error',
  props<{ error: unknown }>()
);

// resendRequest
export const resendRequest = createAction(
  '[Twosome/API] Resend Request',
  props<{ twosome: Twosome }>()
);

export const resendRequestSuccess = createAction(
  '[Twosome/API] Resend Request Success',
  props<{ twosome: Twosome }>()
);

export const resendRequestError = createAction(
  '[Twosome/API] Resend Request Error',
  props<{ error: unknown }>()
);

// acceptContact
export const acceptContact = createAction(
  '[Twosome/API] Accept Contact',
  props<{ twosome: Twosome }>()
);

export const acceptContactSuccess = createAction(
  '[Twosome/API] Accept Contact Success',
  props<{ twosome: Twosome }>()
);

export const acceptContactError = createAction(
  '[Twosome/API] Accept Contact Error',
  props<{ error: unknown }>()
);

// rejectContact
export const rejectContact = createAction(
  '[Twosome/API] Reject Contact',
  props<{ twosome: Twosome }>()
);

export const rejectContactSuccess = createAction(
  '[Twosome/API] Reject Contact Success',
  props<{ twosome: Twosome }>()
);

export const rejectContactError = createAction(
  '[Twosome/API] Reject Contact Error',
  props<{ error: unknown }>()
);

// ringdown
export const ringdown = createAction('[Twosome/API] Ringdown', props<{ twosome: Twosome }>());

export const ringdownSuccess = createAction(
  '[Twosome/API] Ringdown Success',
  props<{ twosome: Twosome }>()
);

export const ringdownError = createAction(
  '[Twosome/API] Ringdown Error',
  props<{ error: unknown }>()
);

export const loadTwosomes = createAction(
  '[Twosome/API] Load Twosomes',
  props<{ twosomes: Twosome[] }>()
);

export const addTwosome = createAction('[Twosome/API] Add Twosome', props<{ twosome: Twosome }>());

export const upsertTwosome = createAction(
  '[Twosome/API] Upsert Twosome',
  props<{ twosome: Twosome }>()
);

export const addTwosomes = createAction(
  '[Twosome/API] Add Twosomes',
  props<{ twosomes: Twosome[] }>()
);

export const upsertTwosomes = createAction(
  '[Twosome/API] Upsert Twosomes',
  props<{ twosomes: Twosome[] }>()
);

export const updateTwosome = createAction(
  '[Twosome/API] Update Twosome',
  props<{ twosome: Update<Twosome> }>()
);

export const updateTwosomes = createAction(
  '[Twosome/API] Update Twosomes',
  props<{ twosomes: Update<Twosome>[] }>()
);

export const deleteTwosome = createAction('[Twosome/API] Delete Twosome', props<{ id: string }>());

export const deleteTwosomes = createAction(
  '[Twosome/API] Delete Twosomes',
  props<{ ids: string[] }>()
);

export const clearTwosomes = createAction('[Twosome/API] Clear Twosomes');
