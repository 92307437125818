import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  ViewChild,
  Output,
  EventEmitter,
} from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { tap } from 'rxjs/operators';
import { McsService } from './../../../common/services/mcs.service';

import { Meeting, SitesDetails } from './../../../common/models/site.model';
import { State, selectSelectedSite, selectSelectedChannel } from '../../../store/app.reducer';
import * as multicircuitActions from '../../../store/multi-circuit/multicircuit-actions';
import { NgModel } from '@angular/forms';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import { loadlocalSites, loadSites } from '../../../store/multi-circuit/multicircuit-actions';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { TranscriptionSheetComponent } from '@shared/transcription-sheet/transcription-sheet.component';

export interface Fruit {
  name: string;
}

@Component({
  selector: 'ethervox-multicircuit-context-page',
  templateUrl: './multicircuit-context-page.component.html',
  styleUrls: ['./multicircuit-context-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MulticircuitContextPageComponent implements OnInit {
  selectedSite$: Observable<Meeting>;
  selectedChannel$: Observable<SitesDetails>;

  selectedSite: Meeting;
  selectedChannel: SitesDetails;
  receiveValue = 0;
  transmitValue = 0;
  channelId: string;
  saving = false;
  constructor(
    private store: Store<State>,
    private mcsService: McsService,
    private _bottomSheet: MatBottomSheet
  ) {}

  @Input() flexAccessLoaded: boolean;

  flexAccessAs: any = {};
  @Input() set flexAccess(flexAccess: any) {
    if (flexAccess && !this.flexAccessAs.inboundNumber) {
      this.flexAccessAs = flexAccess;
      this.callerIds = [...(flexAccess.callerIDs || [])];
    }
  }
  @Input() set site(site: Meeting) {
    this.selectedSite = site;
  }
  @Input() set channel(channel: SitesDetails) {
    this.selectedChannel = channel;
    this.transmitValue = channel.txGain;
    this.receiveValue = channel.rxGain;
    this.channelId = channel.id;
  }

  @Output() refreshFlexAccess = new EventEmitter();

  @ViewChild('inboundNumberCtrl') inboundNumberCtrl: any;

  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  callerIds: string[] = [];

  ngOnInit(): void {
    this.selectedSite$ = this.store.select(selectSelectedSite);
    this.selectedChannel$ = this.store.select(selectSelectedChannel).pipe(
      tap((selectedChannel) => {
        this.transmitValue = selectedChannel?.txGain;
        this.receiveValue = selectedChannel?.rxGain;
        this.channelId = selectedChannel?.id;
      })
    );
  }

  onSliderChangeReceive(): void {
    this.store.dispatch(
      multicircuitActions.setVolume({
        channelId: this.channelId,
        txGain: this.transmitValue,
        rxGain: this.receiveValue,
      })
    );
  }

  onSliderChangeTransmit(): void {
    this.store.dispatch(
      multicircuitActions.setVolume({
        channelId: this.channelId,
        txGain: this.transmitValue,
        rxGain: this.receiveValue,
      })
    );
  }

  saveFlexAccess(inboundNumber: string, pin: string, callerIds): void {
    this.saving = true;
    this.mcsService
      .updateFlexAccess(this.selectedSite.id, inboundNumber, pin, callerIds)
      .subscribe((res) => {
        this.saving = false;
        this.store.dispatch(loadSites());
        this.store.dispatch(loadlocalSites());
        this.refreshFlexAccess.next();
      });
  }

  // openTranscriptionSheet(confNum) {
  //   this._bottomSheet.open(TranscriptionSheetComponent, {
  //     data: {
  //       bcn: confNum
  //     }
  //   });
  // }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    if ((value || '').trim()) {
      this.callerIds.push(value.trim());
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

  remove(callerId: string): void {
    const index = this.callerIds.indexOf(callerId);

    if (index >= 0) {
      this.callerIds.splice(index, 1);
    }
  }
}
