import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, Router } from '@angular/router';

@Injectable()
export class CanActivateHall implements CanActivate, CanActivateChild {
  constructor(private router: Router) { }

  canActivate(): boolean {
    return this.permitOrRedirect();
  }

  canActivateChild(): boolean {
    return this.permitOrRedirect();
  }

  permitOrRedirect(): boolean {
    return true;
  }
}
