import { createAction, props } from '@ngrx/store';

// loadApps
export const loadApps = createAction('[Admin/API] Load Apps');

export const loadAppsSuccess = createAction(
  '[Admin/API] Load Apps Success',
  props<{ apps: any[] }>()
);

export const loadAppsError = createAction(
  '[Admin/API] Load Apps Error',
  props<{ error: unknown }>()
);

// loadAppNodes
export const loadAppNodes = createAction('[Admin/API] Load App Nodes', props<{ id: string }>());

export const loadAppNodesSuccess = createAction(
  '[Admin/API] Load App Nodes Success',
  props<{ nodes: any[] }>()
);

export const loadAppNodesError = createAction(
  '[Admin/API] Load App Nodes Error',
  props<{ error: unknown }>()
);
