<h3 class="dialog-title">Request Intercom Connection</h3>
<br />
<ul>
  <li *ngFor="let contact of data.contacts; let i = index">
    <mat-radio-group [(ngModel)]="selectedContact">
      <mat-radio-button color="primary" [value]="contact">
        <h4>{{ contact.displayName }}</h4>
      </mat-radio-button>
    </mat-radio-group>
  </li>
</ul>
<br />
<div mat-dialog-actions>
  <button mat-flat-button [mat-dialog-close]="null">Cancel</button>
  <button
    mat-flat-button
    (click)="confirm(data, selectedContact)"
    [disabled]="!selectedContact"
    color="primary"
    class="btn-right"
  >
    Confirm
  </button>
</div>
