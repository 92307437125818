import { Component, OnInit } from '@angular/core';
import { Observable, combineLatest } from 'rxjs';
import { filter, switchMap, take, tap } from 'rxjs/operators';

import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import {
  State,
  selectIntercom,
  selectAllBroadcastGroups,
  selectAllHandsets,
  selectBroadcastGroupsLoaded,
  selectIntercomLoaded,
  selectAllTwosomes,
  selectContactsLoaded,
} from '../store';
import { Intercom } from '../store/intercom/intercom.model';
import { loadIntercoms } from '../store/intercom/intercom.actions';
import { BroadcastGroup } from '../store/broadcast-group/broadcast-group.model';
import { loadBroadcastGroups } from '../store/broadcast-group/broadcast-group.actions';
import { loadCommunity } from '../store/community/community.actions';
import { Handset } from '../store/handset/handset.model';
import { Twosome } from '../store/twosome/twosome.model';
import { loadContacts, requestTwosome } from '../store/contact/contact.actions';
import {
  updateSettings,
  resendRequest,
  ringdown,
  acceptContact,
  rejectContact,
} from '../store/twosome/twosome.actions';
import { putOnHandset, dropHandset, putMCOnHandset } from '../store/handset/handset.actions';
import { BridgeHelper } from '../../../common/helpers/bridge.helper';
import { DynamicBridge } from '../store/community/community.model';
import { Meeting, selectLocalSites, loadSites, loadlocalSites, selectLocalSitesLoaded } from '@ethervox/mcs';

@Component({
  selector: 'bridges-page',
  templateUrl: './bridges-page.component.html',
  styleUrls: ['./bridges-page.component.scss'],
})
export class BridgesPageComponent implements OnInit {
  handsets$: Observable<Handset[]>;
  twosomes$: Observable<Twosome[]>;
  sites$: Observable<Meeting[]>;

  intercom$: Observable<Intercom>;
  intercoms$: Observable<Intercom[]>;
  dynamicBridges$: Observable<DynamicBridge[]>;
  activeFarparties = {};
  broadcastGroup$: Observable<BroadcastGroup[]>;
  getInitials = BridgeHelper.getInitials;

  constructor(private store: Store<State>, private router: Router) { }

  ngOnInit(): void {
    this.sites$ = this.store.select(selectLocalSitesLoaded)
      .pipe(tap(loaded => !loaded && this.store.dispatch(loadlocalSites())))
      .pipe(filter(loaded => !!loaded))
      .pipe(switchMap(() => this.store.select(selectLocalSites)));

    if (this.router.routerState.snapshot.url === '/') {
      this.router.navigate(['dashboard-home/bridges']);
    }
    this.store
      .select(selectContactsLoaded)
      .pipe(filter((loaded) => !loaded))
      .pipe(take(1))
      .subscribe(() => this.store.dispatch(loadContacts()));

    this.intercoms$ = this.store.select(selectIntercom);
    this.broadcastGroup$ = this.store.select(selectAllBroadcastGroups);
    this.handsets$ = this.store.select(selectAllHandsets);
    this.twosomes$ = this.store.select(selectAllTwosomes);

    // @TODO to page guard
    // dispatch loadBroadcastGroups and loadIntercoms on page init
    this.store.dispatch(loadBroadcastGroups());
    this.store.dispatch(loadIntercoms());
    combineLatest([
      this.store.select(selectBroadcastGroupsLoaded),
      this.store.select(selectIntercomLoaded),
    ])
      .pipe(filter(([bgLoaded, intercomLoaded]) => bgLoaded && intercomLoaded))
      .subscribe(() => {
        // dispatch loadCommunity after bg and intercom become loaded
        this.store.dispatch(loadCommunity());
      });
  }

  onRequestContactTwosome(twosome: Twosome): void {
    this.store.dispatch(requestTwosome({ twosome }));
  }

  onResendTwosome(twosome: Twosome): void {
    this.store.dispatch(resendRequest({ twosome }));
  }

  onAcceptTwosome(twosome: Twosome): void {
    this.store.dispatch(acceptContact({ twosome }));
  }

  onRejectTwosome(twosome: Twosome): void {
    this.store.dispatch(rejectContact({ twosome }));
  }

  onUpdateTwosomeSettings(twosome: Twosome): void {
    this.store.dispatch(updateSettings({ twosome }));
  }

  putTwosomeOnHandset(twosome: Twosome, handset: Handset): void {
    this.store.dispatch(putOnHandset({ bridgeId: twosome.circuit.id, handsetId: handset.id }));
  }

  putMCOnHandset(callId: string, lineId: string): void {
    this.store.dispatch(putMCOnHandset({ callId, lineId }));
  }

  dropTwosomeFromHandset(twosome: Twosome, handset: Handset): void {
    this.store.dispatch(dropHandset({ bridgeId: twosome.circuit.id, handsetId: handset.id }));
  }

  onRingdownTwosome(twosome: Twosome): void {
    this.store.dispatch(ringdown({ twosome }));
  }
}
