import { createAction, props } from '@ngrx/store';
import { AuthEntity, AuthProps, AuthToken } from './auth.models';

// authenticate
export const authenticate = createAction('[Auth] Authenticate', props<AuthProps>());
export const authenticateSuccess = createAction('[Auth] Authenticate Success', props<AuthToken>());
export const authenticateError = createAction(
  '[Auth] Authenticate Error',
  props<{ error: unknown }>()
);

// disprove
export const disprove = createAction('[Auth] Disprove');
export const disproveSuccess = createAction('[Auth] Disprove Success');
export const disproveError = createAction('[Auth] Disprove Error', props<{ error: unknown }>());

// getAuthEntity
export const getAuthEntity = createAction('[Auth] Get Auth Entity');
export const getAuthEntitySuccess = createAction(
  '[Auth] Get Auth Entity Success',
  props<{ authEntity: AuthEntity }>()
);
export const getAuthEntityError = createAction(
  '[Auth] Get Auth Entity Error',
  props<{ error: unknown }>()
);
