import { Component, OnInit } from '@angular/core';
import { Web } from 'sip.js';

function getAudioElement(id: string): HTMLAudioElement {
  const el = document.getElementById(id);
  if (!(el instanceof HTMLAudioElement)) {
    throw new Error(`Element "${id}" not found or not an audio element.`);
  }
  return el;
}

@Component({
  selector: 'app-direct-webrtc-page',
  templateUrl: './direct-webrtc-page.component.html',
  styleUrls: ['./direct-webrtc-page.component.scss']
})
export class DirectWebrtcPageComponent implements OnInit {

  simpleUser: Web.SimpleUser;

  constructor() { }

  async ngOnInit() {
    const options: Web.SimpleUserOptions = {
      aor: `sip:1001@webrtc.ethervox.io`,
      media: {
        constraints: { audio: true, video: false },
        remote: { audio: getAudioElement('remoteAudio') },
      },
      userAgentOptions: {
        autoStart: true,
        autoStop: true,
        authorizationPassword: '1001!',
        authorizationUsername: '1001',
      },
    };
    const server = 'wss://webrtc.ethervox.io:8089/ws';
    this.simpleUser = new Web.SimpleUser(server, options);
    await this.simpleUser.connect();
    await this.simpleUser.register();
  }

  call() {
    this.simpleUser.call(`sip:55592006@webrtc.ethervox.io:8089`);
  }

}
