import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { hallRoutes } from './hall.routes';
import { LoginComponent } from './components/login/login.component';
import { SharedModule } from '../shared/shared.module';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import * as fromAuth from '@ethervox/auth-store';
@NgModule({
  declarations: [LoginComponent, NotFoundComponent],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule.forChild(hallRoutes),
    EffectsModule.forRoot([fromAuth.AuthEffects]),
  ],
})
export class HallModule {}
