import { Routes } from '@angular/router';
import { homeRoutes } from './home/home.routes';
import { hallRoutes } from './hall/hall.routes';
import { CanActivateHome } from '@ethervox/auth-store';

export const appRoutes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'dashboard-home',
  },
  {
    path: 'dashboard-home',
    children: homeRoutes,
    canActivate: [CanActivateHome],
  },
  {
    path: 'hall',
    children: hallRoutes,
  },
];
