import { Routes } from '@angular/router';

import { HomePageComponent } from './home-page.component';
import { bridgesRoutes } from './bridges/bridges.routes';
import { handsetsRoutes } from './handsets/handsets.routes';
import { contactsRoutes } from './contacts/contacts.routes';
import { mcsRoutes } from './mcs/mcs.routes';
import { hootRoutes } from './hoot/hoot.routes';
import { adminRoutes } from './admin/admin.routes';
import { AppAbsPageComponent } from './admin/+app-abs-page/app-abs-page.component';

export const homeRoutes: Routes = [
  {
    path: '',
    component: HomePageComponent,
    children: [
      {
        path: '',
        redirectTo: 'bridges',
        pathMatch: 'full',
      },
      {
        path: 'contacts',
        children: contactsRoutes,
      },
      {
        path: 'bridges',
        children: bridgesRoutes,
      },
      {
        path: 'handsets',
        children: handsetsRoutes,
      },
      {
        path: 'mcs',
        children: mcsRoutes,
      },
      {
        path: 'hoot',
        children: hootRoutes,
      },
      {
        path: 'admin',
        children: adminRoutes,
      },
      {
        path: 'admin/:appId',
        component: AppAbsPageComponent,
      }
    ],
  },
];
