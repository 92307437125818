/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { noop } from 'lodash';
import * as io from 'socket.io-client';

import { etherVoxApiUrl, protectedSocketPath as path } from '@const/api.const';
import { SessionService } from '../session/session.service';
import { Observable, BehaviorSubject } from 'rxjs';
import { filter, switchMap, take } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import {
  confEnd,
  confJoin,
  confTalk,
  confLeave,
  confTranscript,
  hangup,
  connected,
  connectionState,
  handsetStatus,
} from '../../../store/actions/socket.actions';
import { State } from 'src/app/store/reducers';

export interface CarrierListener<T = any> {
  $: Observable<T>;
}

export interface CarrierObject {
  id: string;
}

@Injectable({
  providedIn: 'root',
})
export class SocketCarrierService {
  socket: SocketIOClient.Socket;
  connected$ = new BehaviorSubject<boolean>(false);

  private socketListeners = new Map();

  constructor(private store: Store<State>) {}

  dispatchActions(): void {
    this.socket.on('event', (e) => {
      const payload = e['event-object'];
      // console.log('SOCKET', e)
      switch (e.event) {
        case 'conf-join':
          this.store.dispatch(confJoin({ payload: e }));
          break;
        case 'conf-leave':
          this.store.dispatch(confLeave({ payload: e }));
          break;
        case 'conf-talking':
          this.store.dispatch(confTalk({ payload: e }));
          break;
        case 'conf-transcript':
          this.store.dispatch(confTranscript({ payload: e }));
          break;
        case 'connected':
          this.store.dispatch(connected({ payload: e }));
          break;
        case 'conf-end':
          this.store.dispatch(confEnd({ payload }));
          break;
        case 'ConnectionState':
          this.store.dispatch(connectionState({ payload }));
          break;
        case 'Hangup':
          this.store.dispatch(hangup({ payload: e }));
          break;
        case 'HandsetStatus':
          this.store.dispatch(handsetStatus({ payload }));
          break;
      }
    });
  }

  on<T>(eventName: string): Observable<T> {
    const $ = this.connected$
      .pipe(
        filter((c) => !!c),
        take(1)
      )
      .pipe(
        switchMap(
          () =>
            new Observable<T>((subscriber) => {
              this.socket.on(
                'event',
                (e) => e && e.event === eventName && subscriber.next(e['event-object'] as T)
              );
            })
        )
      );
    return $;
  }

  initialize(): Promise<SocketIOClient.Socket> {
    const query = { token: SessionService.token };
    this.socket =
      this.socket && this.socket.connected ? this.socket : io(etherVoxApiUrl, { path, query });
    this.socket.on('event', (e) => (this.socketListeners[e.event] || noop)(e['event-object']));
    return new Promise((resolve, reject) => {
      this.socket.on('connect', () => (resolve(this.socket), this.connected$.next(true)));
      this.socket.on('disconnect', reject);
    });
  }

  resendBridge(): Promise<any> {
    const body = {
      request: 'DynamicLine',
      action: 'resend-connection',
    };
    return this.requestSocket(body);
  }

  requestBridge(community: string, line: string, member: string): Promise<any> {
    const body = {
      request: 'DynamicLine',
      action: 'request-connection',
      community,
      line,
      member,
    };
    return this.requestSocket(body);
  }

  acceptBridge(requestid: string): Promise<any> {
    const body = {
      request: 'DynamicLine',
      action: 'accept-connection',
      requestid,
    };
    return this.requestSocket(body);
  }

  rejectBridge(requestid: string): Promise<any> {
    const body = {
      request: 'DynamicLine',
      action: 'reject-connection',
      requestid,
    };
    return this.requestSocket(body);
  }

  requestContact(address: string): Promise<any> {
    const body = {
      request: 'Contact',
      action: 'request',
      address,
    };
    return this.requestSocket(body);
  }

  rejectContact(requestid: string): Promise<any> {
    const body = {
      request: 'Contact',
      action: 'reject',
      requestid,
    };
    return this.requestSocket(body);
  }

  acceptContact(requestid: string): Promise<any> {
    const body = {
      request: 'Contact',
      action: 'accept',
      requestid,
    };
    return this.requestSocket(body);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  ringDown(line): Promise<any> {
    const body = {
      request: 'DynamicLine',
      action: 'ring-down',
      line,
    };
    return this.requestSocket(body);
  }

  private requestSocket(request: any): Promise<any> {
    return new Promise((resolve) => {
      let ack = false;
      this.socket.emit('action', request, (response) => (resolve(response), (ack = true)));
      setTimeout(() => {
        if (!ack) {
          resolve({ ack: false });
        }
      }, 300);
    });
  }
}
