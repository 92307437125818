import { Injectable, TemplateRef } from '@angular/core';
import {
  MatSnackBar,
  MatSnackBarConfig,
  MatSnackBarRef,
  SimpleSnackBar,
} from '@angular/material/snack-bar';

import { duration } from '@services/snackbar/snackbar.const';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SnackbarService {
  constructor(public snackBar: MatSnackBar) {}

  open$(message: string): Observable<MatSnackBarRef<SimpleSnackBar>> {
    const snackbar$ = this.snackBar.open(message, null, { duration });
    return of(snackbar$);
  }

  openSimple(message: string, config?: MatSnackBarConfig): void {
    this.snackBar.open(message, null, {
      duration,
      ...config,
    });
  }

  openFromTemplate(template: TemplateRef<unknown>, config?: MatSnackBarConfig): void {
    this.snackBar.openFromTemplate(template, {
      duration,
      ...config,
    });
  }
}
