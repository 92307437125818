
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { SharedModule } from '@shared/shared.module';
import { hootRoutes } from './hoot.routes';
import { HootPageComponent } from './+hoot-page/hoot-page.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    RouterModule.forChild(hootRoutes)
  ],
  declarations: [HootPageComponent]
})
export class HootModule { }
