import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-direct-dial-page',
  templateUrl: './direct-dial-page.component.html',
  styleUrls: ['./direct-dial-page.component.scss']
})
export class DirectDialPageComponent implements OnInit {

  token = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJyZW1vdGVBZGRyZXNzIjoiOjpmZmZmOjE3Mi4yMC4wLjEiLCJ1c2VyLWFnZW50IjoiUG9zdG1hblJ1bnRpbWUvNy40MS4yIiwidXNlciI6IjVlMzA2NDg2NmVmNTA2MjVmYWQ0ZTg2OSIsInJlc291cmNlIjoiVXNlciIsImlhdCI6MTcyNTQ1NDg5MywiZXhwIjoxNzI4MTMzMjkzfQ.cCdbiwIARxA-nGa_Zz4z19LfhN-640tnWIlsatpKPPc';

  controls = [
    {
      name: 'MC5 BT to IPC / ard',
      url: 'https://api.ethervox.io/api/v3/audiobridge/selfmanage/661ea18fd8ce7e00367a3292/ringdown'
    },
    {
      name: 'MC7 BT to IPC / mrd',
      url: 'https://api.ethervox.io/api/v3/audiobridge/selfmanage/6616b8a053631b0502938469/ringdown'
    },
    {
      name: 'MC8 Audio Loop / hoot',
      url: 'https://api.ethervox.io/api/v3/audiobridge/selfmanage/66d7169b571600041e77a6ac/ringdown'
    }
  ]
  constructor() { }

  ngOnInit() {
  }

  dial(url) {
    return fetch(url, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${this.token}`
      }
    })
  }

}
