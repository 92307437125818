<h3 class="dialog-title">
  Confirm Multi-Party bridge
</h3>
<br>
<ul>
  <li *ngFor="let user of data; let i = index">
    <mat-checkbox
      color="primary"
      (change)="onMemberChange($event)"
      [disabled]="user.myself"
      [checked]="!!user.myself">
      <h4>{{user.displayName}}</h4>
      <ng-container *ngIf="!user.myself">
        <span>
          etherVox membership status:
          <strong>{{user.etherVoxAuthStatus ? 'active' : 'not registered'}}</strong>
        </span>
        <br>
        <span>
          etherVox contact status:
          <strong>{{user.etherVoxContactStatus?.exist ? 'active' : 'inactive'}}</strong>
        </span>
      </ng-container>
    </mat-checkbox>
  </li>
</ul>
<div mat-dialog-actions>
  <button mat-flat-button [mat-dialog-close]="false">
    Cancel
  </button>
  <button
    mat-flat-button
    [mat-dialog-close]="true"
    cdkFocusInitial
    color="primary"
    class="btn-right">
    Request Multi-Party
  </button>
</div>
