
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { SharedModule } from '@shared/shared.module';
import { handsetsRoutes } from './handsets.routes';
import { HandsetsPageComponent } from './+handsets-page/handsets-page.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    RouterModule.forChild(handsetsRoutes)
  ],
  declarations: [HandsetsPageComponent]
})
export class HandsetsModule { }
