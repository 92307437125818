<h1 mat-dialog-title>Member lookup result</h1>
<div mat-dialog-content>
  <p>
    User {{data.member.displayName || data.member.address}} has been found in etherVox community.
  </p>
</div>
<div mat-dialog-actions>
  <button mat-flat-button [mat-dialog-close]="false">
    Cancel
  </button>
  <button mat-flat-button [mat-dialog-close]="data.member" cdkFocusInitial color="primary" class="btn-right">
    Request Contact
  </button>
</div>
