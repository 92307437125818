import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  State,
  selectSelectedSite,
  selectIsLocal,
  selectSubMulticircuit,
} from '../../../store/app.reducer';
import { Observable, Subject } from 'rxjs';
import { Meeting } from './../../../common/models/site.model';
import { casType } from './../../../common/models/cas-type';
import { map, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'ethervox-multicircuit-expansion',
  templateUrl: './multicircuit-expansion.component.html',
  styleUrls: ['./multicircuit-expansion.component.scss'],
})
export class MulticircuitExpansionComponent implements OnInit {
  selectedSite$: Observable<Meeting>;
  selectedChannel$: Observable<any>;
  receiveValue = 0;
  transmitValue = 0;
  channelId: string;
  componentDestroyed$: Subject<boolean> = new Subject();
  panelOpenState = false;
  @Input() isLocal: boolean;
  casType = casType;
  constructor(private store: Store<State>) {}

  ngOnInit(): void {
    this.selectedSite$ = this.store.select(selectSelectedSite);
    this.selectedChannel$ = this.store
      .select(selectSubMulticircuit)
      .pipe(takeUntil(this.componentDestroyed$));
  }

  ngOnDestroy() {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }
}
