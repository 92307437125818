import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { authenticate, disprove } from '../+state/auth.actions';

import { AuthCredentials, AuthStrategy } from '../+state/auth.models';
import { State } from '../+state/auth.reducer';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  popupAuth(): void {
    this.store.dispatch(authenticate({ strategy: AuthStrategy.POPUP }));
  }

  frameAuth(container: HTMLElement): void {
    this.store.dispatch(authenticate({ strategy: AuthStrategy.FRAME, container }));
  }

  credentialsAuth(credentials: AuthCredentials): void {
    this.store.dispatch(authenticate({ strategy: AuthStrategy.CREDENTIALS, credentials }));
  }

  disprove(): void {
    this.store.dispatch(disprove());
  }

  constructor(private store: Store<State>) {}
}
