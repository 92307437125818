<div class="dialog__content">
  <span class="mat-title">
    {{data.title}}
  </span>
  <span
    class="mat-body-1"
    *ngIf="data.caption">
    {{data.caption}}
  </span>
</div>
<div class="dialog__controls">
  <button
    color="default"
    (click)="cancel()"
    mat-button>
    Cancel
  </button>
  <button
    class="control--right"
    color="primary"
    (click)="confirm()"
    mat-raised-button>
    Confirm
  </button>
</div>
