/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'contact-lookup-result-dialog',
  templateUrl: './contact-lookup-result-dialog.component.html',
  styleUrls: ['./contact-lookup-result-dialog.component.scss'],
})
export class ContactLookupResultDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<ContactLookupResultDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}
}
