import { Component, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil, filter } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';

import { ContactInvitationDialogComponent } from '@shared/dialogs/contact-invitation-dialog/contact-invitation-dialog.component';
import { MemberService } from '@resources/member/member.service';
import { Member } from '@resources/member/member';
import { ContactLookupResultDialogComponent } from '@shared/dialogs/contact-lookup-result-dialog/contact-lookup-result-dialog.component';
import { DashboardApiService } from '@services/dashboard-api/dashboard-api.service';

@Component({
  selector: 'contact-search',
  templateUrl: './contact-search.component.html',
  styleUrls: ['./contact-search.component.scss'],
})
export class ContactSearchComponent implements OnInit, OnDestroy {
  @Output() searchPhrase = new EventEmitter<string>();
  @Output() inviteEmail = new EventEmitter<string>();
  @Output() requestContact = new EventEmitter<Member.Entity>();

  formGroup = new FormGroup({
    search: new FormControl(),
  });

  get search(): string {
    return this.formGroup.get('search').value;
  }
  set search(search: string) {
    this.formGroup.get('search').setValue(search);
  }

  private componentDestroyed$ = new Subject();

  constructor(
    private dialog: MatDialog,
    private memberService: MemberService,
    private dashboardApiService: DashboardApiService
  ) {}

  ngOnInit(): void {
    this.formGroup
      .get('search')
      .valueChanges.pipe(takeUntil(this.componentDestroyed$))
      .subscribe(this.onSearchPhrase.bind(this));
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next();
    this.componentDestroyed$.complete();
  }

  lookupContacts(): void {
    if (!this.search || this.search.length < 3) return;
    if (this.isEthAdress(this.search)) {
      this.memberService.lookup(this.search).subscribe((result) => {
        if (!result) {
          this.openEmailInvitationDialog('');
        } else {
          this.openContactLookupResultDialog(result);
        }
      });
    } else {
      // search dashboard api
      this.dashboardApiService.searchUsers(this.search).subscribe((result) => {
        if (result && result.users && result.users.length) {
          this.openEmailInvitationDialog(this.search, result.users);
        } else if (this.isEmail(this.search)) {
          this.openEmailInvitationDialog(this.search);
        } else {
          this.formGroup.get('search').setErrors({ format: true });
        }
      });
    }
  }

  private openContactLookupResultDialog(member: Member.Entity[]) {
    const data = { member };
    this.dialog
      .open(ContactLookupResultDialogComponent, { data })
      .afterClosed()
      .pipe(takeUntil(this.componentDestroyed$))
      .pipe(filter((res) => !!res))
      .subscribe((member_: Member.Entity) => {
        this.requestContact.emit(member_);
        this.formGroup.reset();
      });
  }

  private openEmailInvitationDialog(email: string, results?: string[]) {
    const data = { email, results };
    this.dialog
      .open(ContactInvitationDialogComponent, { data, width: '480px' })
      .afterClosed()
      .pipe(takeUntil(this.componentDestroyed$))
      .pipe(filter((res) => !!res))
      .subscribe((result) => {
        this.inviteEmail.emit(result);
        this.formGroup.reset();
      });
  }

  private onSearchPhrase(phrase: string) {
    this.formGroup.get('search').setErrors(null);
    this.searchPhrase.emit(phrase);
  }

  private isEmail(phrase: string): boolean {
    return /\S+@\S+\.\S+/.test(phrase);
  }

  // eslint-disable-next-line
  private isEthAdress(phrase: string): boolean {
    return false;
    // return isAddress(phrase);
  }
}
