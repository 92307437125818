<div *ngIf="selectedSite as site" class="formelements">
  <mat-card class="result mat-elevation-z2">
    <mat-card-content>
      <!-- <span class="captionMulticircuit captionChannel">MultiCircuit</span> -->
      <div>
        <label class="left-label">Conference</label>
        <label>{{ site?.label }}</label>
      </div>
      <div>
        <label class="left-label">Number</label>
        <label>{{ site?.confNum }}</label>
      </div>
      <div>
        <label class="left-label">Signalling</label>
        <label>{{ site?.signalling }}</label>
      </div>
      <!-- <button mat-raised-button color="primary" class="transcriptions-btn" (click)="openTranscriptionSheet(site?.confNum)">
        Transcriptions
      </button> -->
      <!-- <pre>{{ flexAccessAs | json }}</pre> -->
      <div class="dial-opts">
        <div class="form-field">
          <mat-form-field appearance="fill">
            <mat-label>Dial-in number</mat-label>
            <input #inboundNumberCtrl="ngModel" matInput [(ngModel)]="flexAccessAs.inboundNumber" />
          </mat-form-field>
          <!-- <button
              (click)="saveFlexAccess(flexAccessAs.inboundNumber, flexAccessAs.pin, callerIds)"
              mat-icon-button
              color="primary"
              [disabled]="inboundNumberCtrl.pristine || saving"
            >
              <mat-icon>save</mat-icon>
            </button> -->
        </div>
        <div class="form-field">
          <mat-form-field appearance="fill">
            <mat-label>Dial-in PIN</mat-label>
            <input #pinCtrl="ngModel" matInput [(ngModel)]="flexAccessAs.pin" />
          </mat-form-field>
          <!-- <button
              (click)="saveFlexAccess(flexAccessAs.inboundNumber, flexAccessAs.pin, callerIds)"
              mat-icon-button
              color="primary"
              [disabled]="pinCtrl.pristine || saving"
            >
              <mat-icon>save</mat-icon>
            </button> -->
        </div>
        <div class="form-field">
          <mat-form-field class="example-chip-list" appearance="fill">
            <mat-chip-list #chipList aria-label="Fruit selection">
              <mat-chip
                *ngFor="let callerId of callerIds"
                [selectable]="selectable"
                [removable]="removable"
                (removed)="remove(callerId)"
              >
                {{ callerId }}
                <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
              </mat-chip>
              <input
                placeholder="Caller IDs"
                [matChipInputFor]="chipList"
                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                [matChipInputAddOnBlur]="addOnBlur"
                (matChipInputTokenEnd)="add($event)"
              />
            </mat-chip-list>
          </mat-form-field>
          <button
            (click)="saveFlexAccess(flexAccessAs.inboundNumber, flexAccessAs.pin, callerIds)"
            mat-icon-button
            color="primary"
            [disabled]="saving"
          >
            <mat-icon>save</mat-icon>
          </button>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>
<div>
  <mat-card class="result result--channel" *ngIf="selectedChannel as siteDetail">
    <mat-card-content>
      <!-- <span class="captionChannel">Channel</span> -->
      <div>
        <label class="left-label">Channel</label>
        <label>{{ siteDetail?.label }}</label>
      </div>

      <div>
        <label class="left-label">Number</label>
        <label>{{ siteDetail?.ucn }}</label>
      </div>

      <div>
        <label class="left-label">Receive Gain ({{ receiveValue }} dBm)</label>
        <mat-slider
          class="example-margin"
          [disabled]="false"
          [invert]="false"
          [max]="55"
          [min]="-25"
          [step]="1"
          [thumbLabel]="true"
          [tickInterval]="1"
          [(ngModel)]="receiveValue"
          [vertical]="false"
          [displayWith]="formatLabel"
          (change)="onSliderChangeReceive($event)"
        >
        </mat-slider>
      </div>

      <div>
        <label class="left-label">Transmit Gain ({{ transmitValue }} dBm)</label>
        <mat-slider
          class="example-margin"
          [disabled]="false"
          [invert]="false"
          [max]="55"
          [min]="-25"
          [step]="1"
          [thumbLabel]="true"
          [tickInterval]="1"
          [(ngModel)]="transmitValue"
          [vertical]="false"
          [displayWith]="formatLabel"
          (change)="onSliderChangeTransmit($event)"
        >
        </mat-slider>
      </div>
    </mat-card-content>
  </mat-card>
</div>
