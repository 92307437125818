import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { ResourceAbstract } from '@resources/resources.abstract';
import { Member } from './member';

@Injectable({
  providedIn: 'root',
})
export class MemberService extends ResourceAbstract {
  constructor(protected httpClient: HttpClient) {
    super(httpClient);
  }

  // eslint-disable-next-line
  whoami(): Observable<any> {
    return this.get(`user/profile`, {}, true);
  }

  lookup(address: string): Observable<Member.Entity[]> {
    return this.get<Member.Entity[]>(`ethervox/member/lookup?address=${address}`);
  }

  invite(email: string): Observable<unknown> {
    return this.post(`ethervox/member/invite`, { email });
  }
}
