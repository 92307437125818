import { Component, AfterViewInit, ElementRef, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'mcs-page',
  templateUrl: './mcs-page.component.html',
  styleUrls: ['./mcs-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class McsPageComponent implements AfterViewInit {
  constructor(private elementRef: ElementRef) {}

  ngAfterViewInit(): void {
    const iframe = document.createElement('iframe');
    iframe.src = 'https://mcs.ethervox.io';
    iframe.classList.add('animated');
    iframe.classList.add('fadeIn');
    this.elementRef.nativeElement.appendChild(iframe);
  }
}
