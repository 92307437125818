/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Inject, ViewChildren } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormControl } from '@angular/forms';
import { MatCheckboxChange, MatCheckbox } from '@angular/material/checkbox';

@Component({
  selector: 'hoot-request-dialog',
  templateUrl: './hoot-request-dialog.component.html',
  styleUrls: ['./hoot-request-dialog.component.scss'],
})
export class HootRequestDialogComponent {
  formGroup = new FormGroup({
    members: new FormControl(),
  });

  members: boolean[] = [];

  @ViewChildren('mat-checkbox') chosenMembers: MatCheckbox;

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  constructor(
    public dialogRef: MatDialogRef<HootRequestDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: unknown
  ) {}

  onMemberChange($event: MatCheckboxChange): void {}

  cancel(): void {
    this.dialogRef.close(false);
  }
}
